<template>
	<div class="one-content active" id="candidate_shortlist">

		<div class="card">
			<div class="card-header p-2">Shortlisted Candidates</div>
			<div class="card-body p-2">
				<div class="basic-block">
					<div class="row">
						<div class="col-sm-12">
							<div class="table-responsive">
								<table class="table table-borderless all-table">
									<thead>
										<tr>
											<th class="" scope="col"></th>
											<th scope="col">Name</th>
											<th scope="col">Position</th>
											<th scope="col">Exp</th>
											<!-- <th scope="col">Skills</th> -->
											<th scope="col">Place</th>
											<th v-if="false" scope="col">Phone</th>
											<th v-if="false" scope="col">Email</th>
											<th scope="col">Status</th>
											<th scope=""></th>
										</tr>
									</thead>
									<tbody>
										<tr v-if="shortList.shortListCand.length==0">
											<td colspan=9 align="center">No Candidates found</td>
										</tr>
										<template v-for="(ShortListCandidate,index) in shortList.shortListCand">
											<tr>
											<td>
												<a href="javascript:" class="expand" data-targetopen="panel1">
                                                    <span @click="getSalaryNegotiation(ShortListCandidate.candidateId,ShortListCandidate)"><i :class="(currentIndex==ShortListCandidate.candidateId) ? 'ti-arrow-circle-up' : 'ti-arrow-circle-down'"></i>
                                                    </span>
                                                </a>
											</td>	
											<td><router-link title="View Candidate Profile" :to="{ name: 'viewcandidate', params: { candidateid: ShortListCandidate.candidateId }}">{{ShortListCandidate.candidateName}}</router-link></td>
											<td>{{ShortListCandidate.position}}</td>
											<td>{{ShortListCandidate.experience}}</td>
											<!-- <td>{{ShortListCandidate.technology}}</td> -->
											<td>{{ShortListCandidate.cityName}} {{ShortListCandidate.stateName}}</td>
											<td v-if="false">{{ShortListCandidate.mobile}}</td>
											<td v-if="false">{{ShortListCandidate.email}}</td>
											<td>
												<a class="nav-link dropdown-toggle " href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
													<span :class="ShortListCandidate.statusName" class="label text-white p-1 rounded w-50-auto slstatus">{{ShortListCandidate.statusName}}</span>
													<div class="dropdown-menu p-0" aria-labelledby="navbarDropdown">
														<ul class="m-0">
															<li v-for="(CandSLStatus, idx) in shortList.candSLStatus" v-bind:key="idx">
																<a v-on:click="changeStatus(ShortListCandidate.rpCandShortlistId,CandSLStatus.id,ShortListCandidate.attachmentTypeId,ShortListCandidate.visaTypeText,ShortListCandidate)" class="dropdown-item" href="javascript:">{{CandSLStatus.name}}</a>
																<!-- <a v-on:click="$emit('changeSLcandStatus', {'rpCandSLid':ShortListCandidate.rpCandShortlistId, 'CandSLstatusId':CandSLStatus.id})" class="dropdown-item" href="javascript:">{{CandSLStatus.name}}</a> -->
															</li>
														</ul>
													</div>
												</a>
											</td>
											<td><i @click="getData(ShortListCandidate.rpCandShortlistId)" title="Refresh" class="fa fa-refresh refreshIcon"></i></td>
										</tr>
										<tr v-if="currentIndex == ShortListCandidate.candidateId">
                                                <td colspan="15">
                                                    <SalaryNegotiation :key="value" :jobDetails="jobDetails" :candidateInfo="ShortListCandidate" :transactionHistory="transactionHistory"/>         
                                                </td>
                                            </tr>

                                        </template>
									</tbody>
								</table>
							</div>	
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="modal" id="salaryNegConfirm1" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Information</h5>
                        <button type="button" @click="closeModal"  class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div>You are trying to skip the Salary Negotiation step. Do you wish to continue?</div>
                    </div>
                    <div class="modal-footer">                                            
                        <button type="submit" @click="changeStat()" class="btn btn-primary">Yes</button>
                        <button type="button" @click="openSalNeg()" class="btn btn-secondary" data-dismiss="modal">No</button>
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>
<script>
	import iziToast from 'izitoast';
	import SalaryNegotiation from '@/components/jobs/blocks/Integration/SalaryNegotiation';
	export default {
		props: {
			shortlistedCandidates: Array,
			jobDetails: Object,
			candContactAccess: [String, Number]
		},
		components: {
        	SalaryNegotiation
  		},
		computed:{
			jobid(){
				return this.$route.params.jobid;
			}
		},
		watch: {
			shortlistedCandidates(newValue,oldValue){
				this.shortList.shortListCand = this.filterByStatus("3");
			}
		},
		data(){
			return {
				salNeg: [],
				shortListdata: [],
				salaryNegotiationAccess:Boolean,
				value: 0,
				currentIndex: null,
				transactionHistory:[],
				recPlanId: localStorage.getItem('setRecPlanId'),//this.$store.getters.getRecPlanId,
				elements:{
					showSearch: false,
					showCanList: false
				},
				shortList:{
					candSLStatus: this.$store.getters.getShortListedCandidateStatus,
					selCandidatesId:[],
					selCanIdJson:[],
					shortListCand:[]
				}
			}
		},
		mounted(){
			this.shortList.shortListCand = this.filterByStatus("3");
			this.shortList.candSLStatus = this.filterByDropDownStatus(3,7);
			this.salaryAccess();
		},
		methods: {
			openSalNeg(){
				this.getSalaryNegotiation(this.salNeg.candidateId,this.salNeg);
				this.closeModal();
			},
			changeStat(){
				this.$emit('changeSLcandStatus', {'rpCandSLid':this.shortListdata[0].rpCandShortlistId, 'CandSLstatusId':this.shortListdata[0].candSLStatusId});
				this.closeModal();
			},
			closeModal() {
				document.getElementById('salaryNegConfirm1').classList.remove('show');
				document.getElementById('salaryNegConfirm1').style.display = 'none';
				document.getElementById('salaryNegConfirm1').setAttribute('aria-hidden', 'true');
			},
			salaryAccess(){
                if(localStorage.getItem('salaryNegotiationAccess') === "false"){
                        this.salaryNegotiationAccess  = false
                    }else if(localStorage.getItem('salaryNegotiationAccess') === "true"){
                        this.salaryNegotiationAccess  = true
                    }
            },
			reload() {
            	this.value++;
        	},
			getData(rpCandShortlistId){
				return axios.get(this.$store.getters.getAPIBasePath+'/setldresource/get/'+rpCandShortlistId)
                .then(response => {
					console.log(response);
					
						this.reload()
						this.transactionHistory = response.data;

                })
                .catch(error => {
                    console.log(error);
                });
			},
		async getSalaryNegotiation(id, shortListCandidate){
        
				await this.getData(shortListCandidate.rpCandShortlistId);

				if (this.currentIndex == id) {
					this.currentIndex = null;
				} else {
					this.currentIndex = id;                
				}
				
			},
			scheduleInterview(candId){
				localStorage.setItem('recPlanId',this.recPlanId);
				this.$router.push('/candidates/interview/'+candId);
				this.$emit('refreshIncidentHistory');
			},
			filterByStatus(statusId){
				return this.shortlistedCandidates.filter(function(data,key){
					if(data.status==statusId){
						return data;
					}
				});
			},
			filterByDropDownStatus(startId,endId){
				return this.shortList.candSLStatus.filter(function(data,key){
					if((data.id>=startId && data.id<=endId)&& (data.id != 5 && data.id != 6 )){
						return data;
					}
				});
			},
			jobDocumentData: function() {
				var result = '';
				if (this.jobDetails.jobDocumentData != null) {
					result = this.jobDetails.jobDocumentData.map(function(val) {              
						return val.id;
					}).join(',');
				}
				return result;
			},
			async validateCandidateAttachment(attachmentTypeId) {
				let isExistsLoc = true;
				let jobRequiredDocument = await this.jobDocumentData();
				console.log('candidate document: ', attachmentTypeId);
				console.log( jobRequiredDocument);

				for (var item in jobRequiredDocument) {
					console.log('jobDocumentData[item]',jobRequiredDocument[item]);
					if (attachmentTypeId==="" ||  attachmentTypeId == null || !attachmentTypeId.includes(jobRequiredDocument[item])) {
						isExistsLoc = false;
					}
				}
				if(!isExistsLoc) {
					iziToast.info({ message:this.$config.CandReqDocUpErr, position: 'topRight'});
					return isExistsLoc;
				}
			
				return isExistsLoc;
			},
			async changeStatus(rpCandShortlistId, candSLStatusId, attachmentTypeId, visaType,ShortListCandidate){
				const type = ["us citizen", "green card", "gcead","uscitizen"];
				let isVisaType = false;
				if(visaType != null && visaType.length > 0)
					isVisaType = type.includes(visaType.toLowerCase());
				console.log(isVisaType);
				let isValid;
				if(candSLStatusId == 6 || isVisaType == true){
					isValid = true;
				}
				else{
					isValid  = await this.validateCandidateAttachment(attachmentTypeId);
				}
				if(isValid){
					if(this.salaryNegotiationAccess  == true && ShortListCandidate.salaryNegotiationCount== 0 && candSLStatusId != 3 ){
						var shortListdata = [{
							"rpCandShortlistId": rpCandShortlistId,
							"candSLStatusId": candSLStatusId,
						}]
						this.shortListdata = shortListdata;
						this.salNeg = ShortListCandidate;
						document.getElementById('salaryNegConfirm1').classList.add('show');
						document.getElementById('salaryNegConfirm1').style.display = 'block';
						document.getElementById('salaryNegConfirm1').setAttribute('aria-hidden', 'false');
					}else{
					   this.$emit('changeSLcandStatus', {'rpCandSLid':rpCandShortlistId, 'CandSLstatusId':candSLStatusId});
					}
				}
			}
		}
	}
</script>