<template>
    <div id="main" class="mt-70 d-height" >
        <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.5" justify="center" class="h-100">
            <div v-if="elements.loadingCandidate">
                <div class="container-fluid" id="quick-search">
                    <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                        <div class="row">
                            <div class="col-md-6 col-sm-6 col-6">
                                <h4 class="page-title">Search</h4>
                            </div>
                            <div class="col-md-6 col-sm-6 col-6">
                                <ul class="ul-horizontal box-tools text-right mb-0">
                                    <li><a href="Javascript:void(0);" v-on:click="isExpand=!isExpand;" data-target="main-search" class="boxhide"><span :class="isExpand?'ti-angle-up':'ti-angle-down'"></span></a></li>
                                </ul>
                            </div>
                        </div>
                        <form id="main-search" :class="isExpand?'d-block':'d-none'" v-on:submit.prevent="">
                            <div class="row form-group ten-columns">
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Code/Name/Email/Phone</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group">
                                                <input class="form-control" v-on:keyup.enter="findCandidates" type="text" v-model="search.params.searchString"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Industry</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group">
                                                <select class="filter form-control" v-model="search.params.industryId">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in industries" :value="info.id">{{info.industryName}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Position</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group">
                                                <input class="form-control"  v-on:keyup.enter="findCandidates" type="text" v-model="search.params.position" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Technology</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group">
                                                <input class="form-control"  v-on:keyup.enter="findCandidates" type="text" v-model="search.params.technology" />
                                                <!-- <select class="form-control" v-model="search.params.technologyId">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in technologies" :value="info.id">{{info.technology}}</option>
                                                </select> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Skill</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group">
                                                <input class="form-control"  v-on:keyup.enter="findCandidates" type="text" v-model="search.params.skills" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Location</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group">
                                                <input class="form-control"  v-on:keyup.enter="findCandidates" type="text" v-model="search.params.location" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr mt-1">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Recruiter</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group">
                                                <select class="filter form-control" v-model="search.params.recruiterId">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in recruiterlist" :value="info.id">{{info.recruiterName}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr mt-1">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Experience</label>
                                        </div>
                                        <div class="col-md-6 col-sm-12 col-12">
                                            <div class="input-group">
                                                <select class="filter form-control" v-model="search.params.experienceFrom">
                                                    <option value="">From</option>
                                                    <option v-for="(data, index) in yearexperience" :value="data.expyear">{{data.label}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-12 col-12">
                                            <div class="input-group">
                                                <select class="filter form-control" v-model="search.params.experienceTo">
                                                    <option value="">To</option>
                                                    <option v-for="(data, index) in yearexperience" :value="data.expyear">{{data.label}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr mt-1">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Status</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group">
                                                <select class="filter form-control" v-model="search.params.status">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in statusList" :value="info.id">{{info.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="workAuthorization == true" class="col-md-2 col-sm-6 col-12 mb-10-mr mt-1">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Work Authorization</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <multiselect style="" v-model="search.params.workAuthorization" :options="workAuthorizations" track-by="id" label="workAuthorizationName" name="search.params.workAuthorization" :multiple="true" :taggable="false" :show-labels="false" placeholder="Select" :option-height="10"></multiselect>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-6 col-12 mb-10-mr mt-1">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Candidate Type</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <select v-model="search.params.candidateType" class="form-control">
                                                <option value="" selected="selected">Select</option>
                                                <option v-for="(info, index) in candidateTypeList" :value="info.id">{{info.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div> 
                                 <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>Hot Candidates</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <select v-model="search.params.priority" class="form-control" >
                                                <option value="">Select</option>
                                                <option value=true>Yes</option>
                                                <option value=false>No</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <button type="button" @click="resetCandidatesSearch" class="btn btn-primary btn-sm float-right mt-3 ml-10">Reset</button>
                                    <button type="button" @click="findCandidates" class="btn btn-primary btn-sm float-right mt-3 ml-100">Find</button>
                                </div>
                            </div>
                            <div class="row">
                            </div>
                        </form>
                        <!-- <div class="load-indicator"></div> -->
                    </div>
                </div>
                <div class="container-fluid" id="main-data">
                    <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                        <div class="row" style="margin-bottom: 15px;">
                            <div  class="col-md-6 col-sm-6 col-6">
                                <h4 v-if="!elements.showEmailTools && elements.loadingCandidate == true" class="page-title">Candidates
                                    <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{pagination.totalRecords}} - Total)</span>
                                    <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                        <div class="dropdown">
                                            <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                            <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                                <a v-for="(value, index) in searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeLimit(value)">{{value}}</a>
                                            </div>
                                        </div>
                                    </span>
                                </h4>
                            </div>
                            <div v-if="!elements.showEmailTools" class="col-md-6 d-none d-sm-none d-md-block">
                                <button type="button" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">
                                    <a @click="addCandidate" style="color: #fff;">New Candidate</a>
                                </button>
                                <button type="button" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">
                                    <router-link :to="{ name: 'progressinterviews' }" style="color: #fff;">Interviews</router-link>
                                </button>
                                <button type="button" @click="addLogActivity('Import Candidates')" class="btn btn-primary btn-sm float-right ml-10">
                                    <router-link :to="{ name: 'bulkupload' }" style="color: #fff;">Import Candidates</router-link>
                                </button>
                                <button v-if="false" type="button" @click="addLogActivity('Resume Capture')" class="btn btn-primary btn-sm float-right ml-10">
                                    <a v-b-modal.resume-form-modal-old style="color: #fff;">Resume Capture</a>
                                </button>
                                <button  type="button" @click="addLogActivity('Resume Extract')"  class="btn btn-primary btn-sm float-right ml-10">
                                    <a v-b-modal.resume-form-modal style="color: #fff;" >Resume Extract</a>
                                </button>
                                <button v-if="false" type="button" @click="addLogActivity('Bulk Upload')" class="btn btn-primary btn-sm float-right ml-10">
                                    <a v-b-modal.bulkupload-form-modal style="color: #fff;">Bulk Upload</a>
                                </button>
                                <span class="float-right" @click="exportCandidates()">
                                    <i class="value"> <img class="hover-hand" src="@/assets/icons/excel-24.png" title="Download" alt=""></i>
                                </span>
                                <span class="float-right pr-2">
                                    <a v-if="candContactAccess==1" href="javascript:" @click="emailtab" title="Email To" class="hover-hand"><i class="fa fa-envelope fa-2x px-1" style="color: rgb(55, 160, 228);border-color: #2980b9;"></i></a>
                                </span>
                                <span v-if="showShare" class="float-right pr-2 pt-1" >
                                    <i class="value" v-b-modal.share-form-modal> <img class="hover-hand" src="@/assets/icons/share-16.png" title="Share" alt="Share"></i>
                                </span>
                                <span v-if="disableshare" class="float-right pr-2 pt-1" >
                                    <i class="value" v-b-modal.share-form-modal disabled> <img class="hover-hand" style="filter: blur(1px);" src="@/assets/icons/share-16.png" :title="message" :alt="message"></i>
                                </span>
                            </div>

                        </div>

                        <div v-if="!elements.showEmailTools" class="row">
                        
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table table-borderless all-table">
                                        <thead>
                                            <tr>
                                                <th v-if="false" class="" scope="col">#</th>
                                                <th>
                                                </th>
                                                <th>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" v-model="isCheckAll" @click="checkAll" type="checkbox" id="rowchckboxall" value="option1">
                                                        <label class="form-check-label" for="rowchckboxall"></label>
                                                    </div>
                                                </th>
                                                <th class="" scope="col">Cand Code</th>
                                                <th class="" scope="col">Name</th>
                                                <th class="" scope="col">Position</th>
                                                <th class="" scope="col">Industry</th>
                                                <th class="recruiter" scope="col">Experience</th>
                                                <th v-if="coldisplay.technology" class="specialityunit" scope="col">Technology</th>
                                                <th class="specialityunit" scope="col">Skills</th>
                                                <th v-if="coldisplay.currentcity" class="currentcity" scope="col">Location</th>
                                                <!-- <th v-if="candContactAccess != 0" class="phone" scope="col">Phone</th>
                                                <th v-if="candContactAccess != 0" class="email" scope="col">Email</th> -->
                                                <th class="recruiter" scope="col">Recruiter</th>
                                                <th class="status" scope="col">Status</th>
                                                <!-- <th class="videoprofile" scope="col">Video Profile</th>
                                                <th class="lastnote" scope="col">Last Note Added</th> -->
                                                <th class="" scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="elements.loadingCandidate == true && candidateslist.length==0">
                                                <td colspan="15" align="center">No Candidates found</td>
                                            </tr>
                                            <template v-for="(candidateinfo, index) in candidateslist">
                                            <tr :style="[candidateinfo.priority === true ? {backgroundColor: 'lightblue'}: {backgroundColor: 'white'}]">

                                            <a href="javascript:" class="expand" data-targetopen="panel1">
                                                            <span @click="getCandidateNotes(candidateinfo.id)"><i :class="(currentIndex==candidateinfo.id) ? 'ti-arrow-circle-up' : 'ti-arrow-circle-down'"></i>
                                                            </span>
                                                        </a>

                                                <td v-if="false">
                                                    <a href="Javascript:void(0);" class="expand" data-targetopen="panel1"><span class="ti-arrow-circle-down"></span></a>
                                                </td>
                                                <td>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="checkbox" :id="candidateinfo.id" :value="candidateinfo.id" v-model="shareCandidate.candidateId" v-on:click="getCandidateId(candidateinfo.id,$event)">
                                                        <label class="form-check-label" :for="candidateinfo.id"></label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <a href="Javascript:void(0);" title="View Candidate Profile" @click="checkViewLimit(candidateinfo.id,candidateinfo.ttClientAccountId)">{{ candidateinfo.candidateCode }}</a>
                                                </td>
                                                <td>
                                                    <a href="Javascript:void(0);" title="View Candidate Profile" @click="checkViewLimit(candidateinfo.id,candidateinfo.ttClientAccountId)">{{getFullName(candidateinfo.firstName,candidateinfo.lastName)}}</a>
                                                </td>
                                                <td>{{ candidateinfo.position }}</td>
                                                <td>{{ candidateinfo.industry }}</td>
                                                <td class="experience w-100">
                                                    {{ candidateinfo.experience }}<span v-if="candidateinfo.experience != null"> yrs</span>
                                                    {{candidateinfo.exp_Month}} <span v-if="candidateinfo.exp_Month != null"> mos</span>
                                                </td>
                                                <td v-if="coldisplay.technology" :title="candidateinfo.technologies" v-html="gettechsubstring(candidateinfo.technologies.join(','))"></td>
                                                <td :title="candidateinfo.skills" v-html="gettechsubstring(candidateinfo.skills.join(','))"></td>
                                                <td v-if="coldisplay.currentcity" class="currentcity">{{ candidateinfo.location }}</td>
                                            <!--     <td v-if="candContactAccess!= 0" class="phone">{{ candidateinfo.mobile }}</td>
                                                <td v-if="candContactAccess!= 0" class="email">{{ candidateinfo.email }}</td> -->
                                                <td class="recruiter">{{ candidateinfo.recruiterName }}</td>
                                                <td class="status">{{ getCandidateStatus(candidateinfo.status) }}</td>
                                                <!-- <td class="videoprofile">No</td>
                                                <td class="lastnote">0</td> -->
                                                <td>
                                                    <ul class="navbar-nav">
                                                        <li class="nav-item">
                                                            <a class="nav-link dropdown-toggle p-0 font-weight-bold" href="Javascript:void(0);" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions</a>
                                                            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                                <li>
                                                                    <router-link class="dropdown-item" :to="{ name: 'viewcandidate', params: { candidateid: candidateinfo.id }}"><span class="ti-eye pr-2" @click="updateViewcount"></span> View</router-link>
                                                                    <!-- <a class="dropdown-item" href="javascript:" @click="checkViewLimit(candidateinfo.id,candidateinfo.ttClientAccountId)"><span class="ti-eye pr-2"></span> View</a> -->
                                                                </li>
                                                                <li>
                                                                    <router-link class="dropdown-item" :to="{ name: 'editcandidate', params: { candidateid: candidateinfo.id }}"><span class="ti-pencil pr-2"></span> Edit</router-link>
                                                                    <!-- <a class="dropdown-item" href="javascript:" @click="checkViewLimit(candidateinfo.id,candidateinfo.ttClientAccountId)"><span class="ti-pencil pr-2"></span> Edit</a> -->
                                                                </li>
                                                                <li>
                                                                    <a v-if="(ttClientAccountId == candidateinfo.ttClientAccountId || candidateinfo.isViewed==1)" class="dropdown-item" href="javascript:" v-b-modal.joblist-form-modal @click="loadOpenJobs();setCandidateId(candidateinfo);"><span class="ti-calendar pr-2"></span> Schedule Interview</a>
                                                                </li>
                                                                <li v-if="false">
                                                                    <a class="dropdown-item" href="javascript:" v-b-modal.reclist-form-modal @click="loadCompanyList"><span class="ti-calendar pr-2"></span> Share other Recruiter</a>
                                                                </li>
                                                                <li v-if="false">
                                                                    <a class="dropdown-item" href="javascript:" v-b-modal.notes-form-modal @click="addCallback(candidateinfo.id)"><i class="fa fa-phone pr-2" aria-hidden="true"></i> Callback</a>
                                                                </li>
                                                                <!-- <li>
                                                                    <a class="dropdown-item" href="javascript:" v-b-modal.notes-form-modal @click="addNotes(candidateinfo.id)"><i class="fa fa-sticky-note pr-2" aria-hidden="true"></i> Notes</a>
                                                                </li> -->
                                                                <li>
                                                                    <a class="dropdown-item" href="javascript:" @click="deleteCandidate(candidateinfo.id)" data-toggle="modal" data-target="#removeCandidate"><span class="ti-close pr-2"></span> Deactivate</a>
                                                                </li>
                                                                <li v-if="candidateinfo.ttClientAccountId == ttClientAccountId">
                                                                    <a v-if="candidateinfo.priority == true" class="dropdown-item" href="javascript:;" @click="setHotJobParams(candidateinfo.id, 0);" data-toggle="modal" data-target="#resetCands"><span class="ti-reload"></span> Reset Hot Candidate</a>
                                                                    <a v-if="candidateinfo.priority == false" class="dropdown-item" href="javascript:;" @click="setHotJobParams(candidateinfo.id, 1);" data-toggle="modal" data-target="#makeCands"><span class="ti-star"></span> Make Hot Candidate</a>
                                                                </li>
                                                                <li v-if="candidateinfo.ttClientAccountId == ttClientAccountId">
                                                                    <a class="dropdown-item" href="javascript:;" data-toggle="modal"  @click="AddRecruitmentNotes(candidateinfo.id)" ><span class="ti-plus"></span> Add Candidate Notes</a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </td>
                                                
                                        </tr>

                                                    <tr v-if="currentIndex == candidateinfo.id">
                                                        <td colspan="15">
                                                        <div class="table-responsive">
                                                            <table class="table table-borderless all-table">
                                                            <thead>
                                                                    <tr>
                                                                        <th class="" scope="col">Title</th>
                                                                        <th class="" scope="col">Note</th>
                                                                        <th class="" scope="col">Added On</th>    
                                                                        <th class="" scope="col">Added By</th>
                                                                        <th class="" scope="col">Action</th>
                                                                    
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                <tr v-for="info in visibleNotes" :key="info.id" :id="info.id">
                                                                <td>{{ info.title }}</td>
                                                                <td>{{ info.description }}</td>
                                                                <td>{{ info.createdUtc | formatDate }}</td>
                                                                <td>{{ info.addedByName }}</td>
                                                                
                                                                <td>  
                                                                <a v-if="info.typeId==5 && candidateCreatedId==info.addedBy" href="javascript:;" @click="deleteNote(info.id,info.referenceId)" data-toggle="modal" data-target="#removeJobNotes"><span class="ti-cut"></span></a>                                     
                                                                </td>  
                                                                
                                                                </tr>
                                                                
                                                                <td colspan="14" >
                                                                <div style="margin-left:900px">
                                                                <a v-if="candidateNotesList.length>3" href="javascript:;" style="align: 'center'," data-toggle="modal" data-target="#RecruitmentNotes" ><i ></i >Load more</a>
                                                                </div>
                                                                </td>
                                                                

                                                                    <tr  v-if="candidateNotesList.length==0">
                                                                        <td style="border:0px;" colspan="14" align="center">No Notes Found
                                                                        </td>
                                                                    </tr>
                                                            </tbody>
                                                            </table>
                                                            </div>
                                                        
                                                </td>
                                                    </tr>



                                    
                                            </template>

                                            
                                        
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <b-pagination v-if="candidateslist.length!=0" size="md" class="pull-right" :total-rows="pagination.totalRecords" v-model="pagination.currentPage" :per-page="pagination.rowsPerPage" @input="loadCandidates()"></b-pagination>
                            </div>
                        </div>
                        <div v-if="elements.showEmailTools" class="row">
                            <EmailTools v-if="elements.showEmailTools" @closeEmailTools="elements.showEmailTools=false;updateLogActivity()"></EmailTools>
                        </div>
                    </div>
                </div>
            </div>
            
            <template #overlay>
                <div>
                    <img alt="images" class="loader-img-height"  src="/images/loading.gif"> 
                </div>                   
            </template> 
        </b-overlay>
                
                <Dialog id="removeCandidate" :onYes="delete_Candidate" :returnParams="delCandidateParam" title="Deactivate Confirmation" message="Are you sure to deactivate?" />
                <b-modal id="notes-form-modal" ref="notesModal" :title="form.title" @ok="commonCallbackNotesSubmit" style="width:100%">
                    <form ref="form" @submit.stop.prevent="handleSubmit" class="col-md-12 col-sm-12 col-xs-12">
                        <div class="row">
                            <b-form-group label="Communication Mode" label-for="communication-mode" :state="elements.submitted" v-if="form.communicationType=='callback'" class="col-md-6" v-model="form.callbackMode">
                                <b-form-select id="communication-mode" v-model="form.callbackMode" required>
                                    <option value="phone">Phone</option>
                                    <option value="email">Email</option>
                                </b-form-select>
                            </b-form-group>
                            <b-form-group label="Date" label-for="date" :state="elements.submitted" v-if="form.communicationType=='callback'" class="col-md-6">
                                <b-form-input id="date" type="date" v-model="form.callbackDate" aria-describedby="invalidCallbackDate"></b-form-input>
                                <!-- <b-form-invalid-feedback class="mt-0" id="invalidCallbackDate">Callback is required</b-form-invalid-feedback> -->
                            </b-form-group>
                        </div>
                        <div class="row">
                            <b-form-group label="Start Time" label-for="start-time" :state="elements.submitted" v-if="form.communicationType=='callback'" class="col-md-6">
                                <b-form-input id="start-time" type="time" v-model="form.callbackStartTime"></b-form-input>
                            </b-form-group>
                            <b-form-group label="End Time" label-for="end-time" :state="elements.submitted" v-if="form.communicationType=='callback'" class="col-md-6">
                                <b-form-input id="end-time" type="time" v-model="form.callbackEndTime"></b-form-input>
                            </b-form-group>
                        </div>
                        <b-form-group label="Notes" label-for="notes">
                            <editor api-key="yn4wx6bfcohfz6bwlu5k4urno027lgfkcpxolz0f6k4kku01" :toolbar="elements.toolbar" :init="elements.initObj" v-model="form.callbackNotes" />
                        </b-form-group>
                    </form>
                </b-modal>
                <b-modal size="xl" id="reclist-form-modal" :no-close-on-backdrop="true" ref="jobList" title="Jobs" @show="" @hidden="cancelJobList" @ok="scheduleInterview" @cancel="" @close="">
                    <div class="card m-b-3">
                        <div class="card-body p-2">
                            <div class="basic-block">
                                <form id="main-search">
                                    <div class="row form-group ten-columns">
                                        <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <label>Company</label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <select v-model="recsearch.companyId" class="form-control" id="company" name="company">
                                                        <option v-for="(value, index) in companyList" :value="index">{{value.companyName}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-9 col-sm-6 col-12 mb-10-mr">
                                            <div class="row pull-right">
                                                <button type="button" class="btn btn-primary btn-sm filter-button float-right " @click="" style="margin-left: 10px;">Find</button>
                                                <button type="button" class="btn btn-primary btn-sm filter-button float-right " @click="" style="margin-left: 10px;">Reset</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div class="row scroll-down-list">
                                    <div class="col-sm-12">
                                        <div v-if="elements.submitted && !$v.selectedValue.required" class="errorcolor"></div>
                                        <table class="table table-borderless all-table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Job Code</th>
                                                    <th>Job Title</th>
                                                    <th>Client Name</th>
                                                    <th>Position</th>
                                                    <th>Experience</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="!jobList.length">
                                                    <td class="text-center" colspan="100%">No Jobs found</td>
                                                </tr>
                                                <tr v-for="job in jobList">
                                                    <th>
                                                        <div class="form-check form-check-inline">
                                                            <input type="radio" :id="'radiobtn'+job.id" :value="job.id" v-model="selectedValue" @click="setRecPlanId(job.recPlanId)">
                                                            <label :for="'radiobtn'+job.id"></label>
                                                        </div>
                                                    </th>
                                                    <td>{{job.jobCode}}</td>
                                                    <td>{{job.jobTitle}}</td>
                                                    <td>{{job.clientName}}</td>
                                                    <td>{{job.position}}</td>
                                                    <td>{{job.experience}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-modal>
                <b-modal size="xl" id="joblist-form-modal" :no-close-on-backdrop="true" ref="jobList" title="Jobs" @show="" @hidden="cancelJobList" @ok="scheduleInterview" @cancel="" @close="">
                    <div class="card m-b-3">
                        <div class="card-body p-2">
                            <div class="basic-block">
                                <form id="main-search">
                                    <div class="row form-group ten-columns">
                                        <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <label>Job Code</label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <div class="input-group">
                                                        <input type="text"  v-on:keyup.enter="filterJobs" v-model="params.jobCode" class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <label>Job Title</label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <div class="input-group">
                                                        <input type="text"  v-on:keyup.enter="filterJobs" v-model="params.jobTitle" class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-6 col-12 mb-10-mr">
                                            <div class="row pull-right">
                                                <button type="button" class="btn btn-primary btn-sm filter-button float-right " @click="filterJobs()" style="margin-left: 10px;">Find</button>
                                                <button type="button" class="btn btn-primary btn-sm filter-button float-right " @click="resetFilter()" style="margin-left: 10px;">Reset</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div class="row scroll-down-list">
                                    <div class="col-sm-12">
                                        <div v-if="elements.submitted && !$v.selectedValue.required" class="errorcolor">Please select a job to schedule interview</div>
                                        <label v-if="error" class="text-danger">Candidate hasn't uploaded the required documents.</label>
                                        <table class="table table-borderless all-table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Job Code</th>
                                                    <th>Job Title</th>
                                                    <th>Client Name</th>
                                                    <th>Position</th>
                                                    <th>Experience</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="!jobList.length">
                                                    <td class="text-center" colspan="100%">No Jobs found</td>
                                                </tr>
                                                <tr :style="[job.priority === true ? {backgroundColor: 'lightblue'}: {backgroundColor: 'white'}]" v-for="job in jobList">
                                                    <th>
                                                        <div class="form-check form-check-inline">
                                                            <input type="radio" :id="'radiobtn'+job.id" :value="job.id" v-model="selectedValue" @click="setRecPlanId(job.recPlanId,job.documentsRequired)">
                                                            <label :for="'radiobtn'+job.id"></label>
                                                        </div>
                                                    </th>
                                                    <td>{{job.jobCode}}</td>
                                                    <td>{{job.jobTitle}}</td>
                                                    <td>{{job.clientName}}</td>
                                                    <td>{{job.position}}</td>
                                                    <td>{{job.experience}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-modal>
                <b-modal size="small" id="resume-form-modal" :no-close-on-backdrop="true" ref="resumeModal" title="Resume" @show="" @hidden="" @ok="extractResume" @cancel="updateLogActivity" @close="updateLogActivity">
                    <div class="card m-b-3">
                        <div class="card-body p-2">
                            <div class="basic-block">
                                <div class="row scroll-down-list">
                                    <div class="col-sm-12">
                                        <ResumeAttach :isResumeCapture="isResumeCapture" :resumeinfo="resumeinfo" :multipleFile="multipleFile" ref="resumeattachcomponent"></ResumeAttach>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-modal>
                <b-modal size="small" id="resume-form-modal-old" :no-close-on-backdrop="true" ref="resumeModalOld" title="Resume" @show="" @hidden="" @ok="extractResumeOld" @cancel="updateLogActivity" @close="updateLogActivity">
                    <div class="card m-b-3">
                        <div class="card-body p-2">
                            <div class="basic-block">
                                <div class="row scroll-down-list">
                                    <div class="col-sm-12">
                                        <ResumeAttach :isResumeCapture="isResumeCapture" :resumeinfo="resumeinfo" :multipleFile="multipleFile" ref="resumeattachcomponent"></ResumeAttach>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-modal>
                <b-modal size="small" id="bulkupload-form-modal" :no-close-on-backdrop="true" ref="bulkUploadModal" title="Bulk Upload" @show="" @hidden="" @ok="" @cancel="" @close="">
                    <div class="card m-b-3">
                        <div class="card-body p-2">
                            <div class="basic-block">
                                <div class="row scroll-down-list">
                                    <div class="col-sm-12">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Title</label>
                                            </div>
                                            <div class="col-md-6 col-sm-6 col-6">
                                                <input type="text" name="title" v-model="bulkUpload.title" class="form-control ">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Resumes</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12 pt-2" v-for="(input, index) in addMore">
                                                <input multiple class="upload" ref="candidate_multiple_attach" id="candidate_multiple_fileupload" type="file" accept=".doc,.docx,.pdf,.DOCX" v-on:change="handleMultiFileUpload($event)"></input>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </b-modal>
                <b-modal id="share-form-modal" class="h-25" :no-close-on-backdrop="true" ref="resumeModal" title="Clients" @show="" @hidden="" @ok="addCandidateShare" @cancel="updateLogActivity" @close="updateLogActivity">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <!-- <div class="modal-body"> -->
                            <!--  <div class="card"> -->
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12 clientinfo">
                                        <table class="table table-borderless all-table">
                                            <thead>
                                                <tr>
                                                    <th>
                                                    </th>
                                                    <!-- <th>Client Id</th> -->
                                                    <th>Client Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="!clientlist.length">
                                                    <td class="text-center" colspan="100%">No Client found</td>
                                                </tr>
                                                <tr v-for="(client,index) in clientlist">
                                                    <td>
                                                        <div class="col-sm-12 col-12">
                                                            <!-- <input type="radio" name="jobClient" :id="'cli'+client.id" :value="client.id" v-model="shareCandidate.clientId"> -->
                                                            <input class="form-check-input" type="checkbox" v-model="shareCandidate.sharedToTtcaid" :id="client.id" :value="client.id" v-on:click="getClientId(client.id,$event)">
                                                            <label class="form-check-label" :for="client.id"></label>
                                                        </div>
                                                    </td>
                                                    <td>{{client.company_Name}}</td>
                                                    <!-- <td>{{client.account_Name}}</td> -->
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <!-- </div> -->
                            <!--  </div> -->
                        </div>
                    </div>
                </b-modal>
                <b-modal id="compareCandidate" :no-close-on-backdrop="true" hide-header-close centered size="sm" title="Confirmation" @ok="compareCandidate" ok-title="Yes" cancel-title="No">
                    <p class="my-1">Email already exists. Are you sure you want to compare?</p>
                </b-modal>
                <div>
                    <b-modal ref="my-modal" id="AddRecruitmentNotes"  hide-footer title="Notes" size="md">
                        <div  class="modal-body">
                        <textarea  style="height: 10em;" name="Notes" maxlength="5000" value="" class="fldboxbig form-control" v-model="saveCustomNotes.description"></textarea>
                            <!-- <textarea  style="resize:none" cols="60" rows="10"></textarea> -->
                        </div>
                        <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="addCustomNotes()" data-dismiss="modal">  Save </button>
                            <button type="button" class="btn btn-secondary" @click="hideModal" data-dismiss="modal">  Close </button>
                        </div>
                    </b-modal>
                </div>
                <div>
                    <b-modal ref="my-modals" id="RecruitmentNotes"  hide-footer title="Notes" size="lg">
                        <div  class="modal-body">
                            <table class="table table-borderless all-table">
                                <thead>
                                    <tr>
                                        <th class="" scope="col">Title</th>
                                        <th class="" scope="col">Note</th>
                                        <th class="" scope="col">Added On</th>
                                        <th class="" scope="col">Added By</th>
                                        <th class="" scope="col">Action</th>                                    
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="info in candidateNotesList">
                                        <td>{{ info.title }}</td>
                                        <td>{{ info.description }}</td>
                                        <td>{{ info.createdUtc | formatDate }}  {{ info.createdUtc | formatTime }}</td>
                                        <td>{{ info.addedByName }}</td>
                                        <td>                            
                                            <a v-if="info.typeId==5 && candidateCreatedId==info.addedBy" href="javascript:;" @click="deletePopupNote(info.id,info.referenceId)" data-toggle="modal"><i class="ti-cut"></i></a>                                           
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                class="btn btn-secondary"
                                @click="hideModal"
                                data-dismiss="modal">
                                Close
                            </button>
                        </div>
                    </b-modal>
                </div>
                <Dialog id="removeJobNotes" :onYes="delete_note" :returnParams="delNoteParam" title="Delete Note" message="Are you sure to delete note ?" />
                <Dialog id="makeCands" :onYes="saveHotCand" :returnParams="makCandParam" title="Make Hot Candidate Confirmation" message="Are you sure to make hot Candidate ?" />
            <Dialog id="resetCands" :onYes="saveHotCand" :returnParams="makCandParam" title="Reset Hot Candidate Confirmation" message="Are you sure to reset hot Candidate ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { mapState } from 'vuex';
//import 'tui-editor/dist/tui-editor.css';
//import 'tui-editor/dist/tui-editor-contents.css';
//import 'codemirror/lib/codemirror.css';
import Editor from '@tinymce/tinymce-vue';
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import ResumeAttach from './blocks/addcandidate/ResumeAttach';
import EmailTools from '@/components/recruiters/blocks/tools/EmailTools';
import Multiselect from 'vue-multiselect';
export default {
    components: {
        Editor,
        ResumeAttach,
        EmailTools,
        Multiselect
    },
    data() {
        return {
            message:"",
            disableshare: false,
            showOverlay:false,
            currentIndex: null,
            isExpand: false,
            candidateslist: [],
            candidates: [],
            candidateNotesList: [],
            technologies: this.$store.getters.getTechOptions,
            candidateCreatedId: this.$store.getters.currentUser.profileId,
            notesVisible: 3,
            //  recruiterlist: this.$store.getters.getRecruiters,
            industries: this.$store.getters.getIndustries,
            recruiterlist: this.$store.getters.getRecruiterList,
            statusList: this.$store.getters.getCandidatesStatusList,
            candidateTypeList: this.$store.getters.getCandidateTypeList,
            clientlist: [],
            pagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1
            },
             saveCustomNotes: {
                description: "",
                title: "Custom Note",
                addedby: this.$store.getters.currentUser.profileId,
                referenceId:0,
                typeId:5
            },
            searchlimit: this.$store.state.searchlimit,
            elements: {
                loadingCandidate:false,
                showEmailTools: false,
                showadvancedsearch: false,
                submitted: null,
                toolbar: 'undo redo formatselect| bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
                initObj: {
                    menubar: false,
                    height: 300,
                    branding: false,
                }
            },
            yearexperience: [],
            search: {
                params: {
                    name: "",
                    lastName: "",
                    searchString: "",
                    cityName: "",
                    technologyId: "",
                    technology: "",
                    skills: "",
                    recruiterId: "",
                    status: "",
                    position: "",
                    industryId: "",
                    experienceFrom: "",
                    experienceTo: "",
                    location: "",
                    priority: "",
                    workAuthorization: [],
                    candidateType: "",
                    candidateContactAccess: localStorage.getItem('candidateContactAccess'),
                    pageNo:"",
                    limit:"",
                },
                recruiters: [],
                skillset: [],
                currentstate: '',
                minexp: '',
                maxexp: '',
                region: '',
                referal: '',
                country: '',
                jobtype: '',
                industry: ''
            },
            coldisplay: {
                recruiter: true,
                title: true,
                technology: true,
                currentcity: true,
                state: true
            },
            delCandidateParam: {
                index: -1,
                id: 0
            },
             delNoteParam: {
                index: -1,
                id: 0,
                jobId: 0
            },
            form: {
                title: "",
                recruiterId: this.$store.getters.currentUser.accountId,
                userId: null,
                userType: "candidate",
                callbackDate: "",
                callbackStartTime: "",
                callbackEndTime: "",
                callbackMode: "phone",
                callbackNotes: "",
                communicationType: ""

            },
            params: {
                jobTitle: "",
                jobCode: ""
            },
            jobList: [],
            recPlanId: "",
            selectedValue: "",
            notes: "",
            candidateId: "",
            candidateAttachment: "",
            resumeinfo: {
                file: '',
                title: 'Choose Resume',
                supportedTypes: 'Supports only PDF, Doc., Docx file types',
                page: 1,
                extension: ''
            },
            parsedData: [],
            companyList: [],
            recsearch: {
                companyId: ""
            },
            isResumeCapture: true,
            multipleFile: [],
            addMore: [],
            title: "",
            shareCandidate: {
                sharedToTtcaid: [],
                candidateId: []
            },
            clientParams: {
                accountName: ''
            },
            loading:false,
            showShare: false,
            isCheckAll: false,
            workAuthorizations: [],
            candContactAccess: localStorage.getItem('candidateContactAccess'),
            bulkUpload: {
                title: '',
            },
            jobDocuments:'',
            error:false,
             makCandParam: {
                id: 0,
                value:0,
            },
            workAuthorization: Boolean,
           // ttClientAccountId: localStorage.getItem('clientAccountId'),
        }
    },
    validations: {
        form: {
            callbackDate: {
                required

            }
        },
        selectedValue: { required },
    },
    computed: {
          mapState(){

          },
          visibleNotes() {
            return this.candidateNotesList.slice(0, this.notesVisible)
        },
         ttClientAccountId() {
            if (this.$store.getters.currentUser.ttClientAccountId)
                return this.$store.getters.currentUser.ttClientAccountId;
            else return 0;
        },
    },
        
      
    watch: {
        globalSearchValue(newValue, oldValue) {
            this.search.params.name = newValue;
            this.loadCandidates();
        },
        'showOverlay': {
            handler: function(value) { 
                if(value == true){
                    $('#globalapploader').css('visibility','hidden');
                }
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {        
        this.$store.commit('refreshPagination');
        this.loadCandidates();
        this.generateExpYear();
        this.addMore.push(this.addMore.length + 1);
        this.loadClients();
        this.getWorkAuthorizations();
        this.workAuth();
        console.log(this.candContactAccess);
    },
    methods: {
        workAuth(){
            if(localStorage.getItem('workAuthorization') === "1"){
                this.workAuthorization  = true
            }else {
                this.workAuthorization  = false
            }
        },
        checkViewLimit(id,candidateTtClientAccountId){
            // this.showOverlay=true;
            //  axios.get(this.$store.getters.getAPIBasePath + '/candidateresource/viewresume/returnmessage/'+candidateTtClientAccountId  +'/' +id)
            //     .then(response => {
            //         let result = response.data;
            //         console.log(result)
            //         if (result.status == true) {
                        this.$router.push({ name: 'viewcandidate', params: { candidateid: id,candidateaccid:candidateTtClientAccountId} });
                //         this.showOverlay=false;         
                //         if(this.ttClientAccountId != candidateTtClientAccountId){
                //             this.updateViewcount(id);
                //         }
                //     }
                //     else{
                //         this.showOverlay=false;
                //         iziToast.info({
                //             title: 'Info',
                //             message: result.message,
                //             position: 'topRight'
                //         });
                //     }
                // })
                // .catch(error => {
                //     this.showOverlay=false;
                //     console.log(error);
                //     this.unAuthorized(error);
                // });    
        },
        updateViewcount(candidateId){
                axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/reduce/view/candidatecount/'+candidateId)
                .then(response => {
                    let result = response.data;
                })
                .catch(error => {
                    console.log(error);
                    this.unAuthorized(error);
                });
        },
        checkAll() {
            this.isCheckAll = !this.isCheckAll;
            this.candidates = [];

            let self = this
            if (this.isCheckAll) {
                self.showShare = true;
                $.each(this.candidateslist, function(key, value) {
                    self.shareCandidate.candidateId.push(value.id)
                })
            } else {
                self.showShare = false;
                self.shareCandidate.candidateId = [];
            }
        },
        // checkAll() {
        //     this.isCheckAll = !this.isCheckAll;
        //     this.candidates = [];

        //     let self = this
        //     if (this.isCheckAll) {
        //         axios.get(this.$store.getters.getAPIBasePath + '/candidateresource/shareresume/returnmessage')
        //         .then(response => {
        //             let result = response.data;
        //             if (result.status == true) {
        //                 self.showShare = true;
        //                 self.disableshare = false;
        //                 $.each(this.candidateslist, function(key, value) {
        //                 self.shareCandidate.candidateId.push(value.id)
        //         })
        //             }
        //             else{
        //                 self.message = result.message;
        //                 self.showShare = false;
        //                 self.disableshare = true;
                       
        //             }
        //         })
                
        //     } else {
        //         self.showShare = false;
        //         self.disableshare = false;
        //         self.shareCandidate.candidateId = [];
        //     }
        // },
        generateExpYear() {
            for (var i = 1; i <= 20; i++) {
                var x = i.toString();
                if (i == 1) {
                    this.yearexperience.push({ "expyear": "0", "label": "0" });
                }
                this.yearexperience.push({ "expyear": x, "label": x });
            }
            this.yearexperience.push({ "expyear": "21", "label": "20+" });
        },
        loadCompanyList() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/company/dropdown/list')
                .then(response => {
                    this.companyList = response.data.companyList;
                })
                .catch(error => {
                    this.unAuthorized(error);
                    let errmsg = (error.response.data.message != undefined) ? error.response.data.message : this.$config.LoadingErr;
                    iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                });
        },
        filterJobs() {
            this.loadOpenJobs();
        },
        loadOpenJobs() {
            axios.post(this.$store.getters.getAPIBasePath + '/jobresource/inboxjoblist', this.params)
                .then(response => {
                    let result = response.data;
                    if (result.status) {
                        this.jobList = result.jobs;
                        console.log(this.jobList);
                    } else {
                        let errmsg = (response.data.message != undefined) ? response.data.message : this.$config.LoadingErr;
                        iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                    }
                })
                .catch(error => {
                    this.unAuthorized(error);
                    let errmsg = (error.response.data.message != undefined) ? error.response.data.message : this.$config.LoadingErr;
                    iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                });
        },
        setRecPlanId: function(recPlanId, documentId) {
            this.recPlanId = recPlanId;
            this.jobDocuments = documentId;
        },
        setHotJobParams(candId, value) {
            this.makCandParam.id = candId;
            this.makCandParam.value= value;
        },
        saveHotCand(){
        let candData = {"id": this.makCandParam.id,"priority":this.makCandParam.value}
        axios.put(this.$store.getters.getAPIBasePath + '/candidateresource/change/priority', candData)
                .then(response => {
                  if(response.data.status == "OK"){
                      let messages = "Hot Candidate reset successfully";
                      if(this.makCandParam.value == 1){
                          messages = "Hot Candidate updated successfully"
                      }
                      console.log("messages",messages)
                      iziToast.success({
                        title: 'Success!',
                        message: messages ,
                        position: 'topRight'
                    });
                    this.loadCandidates();
                   
                  }
                  else{
                      iziToast.error({
                        title: 'Failed!',
                        message: response.data.message,
                        position: 'topRight'
                    });
                    this.loadCandidates();
                  }
                })
                .catch(error => {
                    console.log(error);
                });

    },
        resetFilter: function() {
            this.params.jobTitle = "";
            this.params.jobCode = "";
            this.loadOpenJobs();
        },
        validateCallbackData: function() {
            this.$v.form.$touch();
            if (this.$v.form.$invalid) {
                return false;
            }
            return true;
        },
        resetCandidatesSearch() {
            this.search.params = {
                name: "",
                lastName: "",
                searchString: "",
                cityName: "",
                technologyId: "",
                technology: "",
                skills: "",
                recruiterId: "",
                status: "",
                position: "",
                industryId: "",
                location: "",
                experience: "",
                experienceFrom: "",
                experienceTo: "",
                candidateType: "",
                workAuthorization: []
            };
            this.loadCandidates();
        },
        add_Notes() {
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/add/candidate/notes', this.form)
                .then(response => {
                    let result = response.data;
                    if (result.status == "ACCEPTED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });

                    }

                })
                .catch(error => {
                    console.log(error);
                    this.unAuthorized(error);
                });

        },
      async loadCandidates() {
            this.showOverlay=true;
            await this.getCandidateList();
            this.showOverlay=false;
        }, 
        getCandidateList() {
            this.showOverlay=true;
            this.search.params.pageNo = this.pagination.currentPage;
            this.search.params.limit = this.pagination.rowsPerPage;
            const specialChars = /[()-]/;
            if(!(specialChars.test(this.search.params.searchString))){
             if(this.search.params.searchString.startsWith("+1")){
            this.search.params.searchString=this.search.params.searchString.replace(/\s+/g,'');
            this.search.params.searchString=this.search.params.searchString.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1 ($2)-$3-$4")
            }
            else if(this.search.params.searchString.startsWith("+91")&&!(specialChars.test(this.search.params.searchString))){
            this.search.params.searchString=this.search.params.searchString.replace(/\s+/g,'');
            this.search.params.searchString=this.search.params.searchString.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, "$1 ($2)-$3-$4")
            }
            else 
            this.search.params.searchString=this.search.params.searchString.replace(/(\d{3})(\d{3})(\d{4})/, "($1)-$2-$3"); 
            }
           else
           this.search.params.searchString=this.search.params.searchString
           return axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/candidatesearchnew', this.search.params)
                .then(response => {
                    
                    let result = response.data;
                    console.log(result);
                    this.candidateslist = result.data;
                    this.pagination.totalRecords = result.totalRecords;
                    this.pagination.currentPage = result.currentPage;
                    this.elements.loadingCandidate = true;
                    //this.loading = false;
                    //this.showOverlay=false;
                })
                .catch(error => {
                    this.showOverlay=false;
                    this.unAuthorized(error);
                    console.log(error);
                    //this.loading = false;
                });
        },
        updateSearchRecruiters: function(value) {
            this.search.recruiters = value;
        },
        updateSearchSkillset: function(value) {
            this.search.skillset = value;
        },
        changeLimit(newlimit) {
            this.pagination.currentPage = 1;
            this.pagination.rowsPerPage = newlimit;
            this.loadCandidates();
        },
        deleteCandidate(candidateId) {
            //this.delTechSkillParam.index=arg1;
            this.delCandidateParam.id = candidateId;
            return;
            if (confirm("Do you really want to deactivate the candidate?")) {
                var params = new URLSearchParams();
                params.append('id', candidateId)
                axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/delete/candidate', params)
                    .then(response => {
                        var result = response.data;
                        if (result.status == "OK") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.loadCandidates();
                        }
                    })
                    .catch(error => {
                        console.log(error.data);
                        this.unAuthorized(error);
                    });
            }
        },
        delete_Candidate() {
            var params = new URLSearchParams();
            var candidateId = this.delCandidateParam.id;
            params.append('id', candidateId)
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/delete/candidate', params)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.loadCandidates();
                    }
                })
                .catch(error => {
                    console.log(error.data);
                    this.unAuthorized(error);
                });
        },
        scheduleInterview(bvModalEvt) {
            this.elements.submitted = true;
            this.$v.selectedValue.$touch();
            if (this.$v.selectedValue.$invalid) {
                bvModalEvt.preventDefault();
                return;
            }

            let isExists = true;
            if (this.candidateAttachment != null) {
                for (var item in this.jobDocuments) {
                    if (!this.candidateAttachment.includes(this.jobDocuments[item])) {
                        isExists = false;                        
                    }
                }
            }
           
            /* if(!isExists) {   
                this.error = true;   
                iziToast.error({ title: 'Error', message: "Candidate hasn't uploaded the required documents.", position: 'topRight'});
                 bvModalEvt.preventDefault();
                return;               
            } */

            // Candidate Interview
            let data = [];
            data.push({ "recruitmentPlanId": null, "jobId": this.selectedValue, "candidateId": this.candidateId });
            localStorage.setItem('recPlanId', this.recPlanId);
            localStorage.setItem('jobId', this.selectedValue);
            localStorage.setItem('recruitmentId', null);
            axios.post(this.$store.getters.getAPIBasePath + '/recruitmentplanresource/add/rp/candidate/shortlist', { "rpCandShortlist": data })
                .then(response => {
                    if (response.data.status == "ACCEPTED") {
                        //localStorage.setItem('recPlanId', this.recPlanId);
                        //localStorage.setItem('jobId', this.selectedValue);
                        //localStorage.setItem('recruitmentId', null);
                        this.$router.push('/candidates/interview/' + this.candidateId);
                    } else {
                        let errmsg = (response.data.message != undefined) ? response.data.message : this.$config.ProcessErr;
                        iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                    }
                })
                .catch(error => {
                    this.unAuthorized(error);
                    console.log(error);
                    let errmsg = (error.response.data.message != undefined) ? error.response.data.message : this.$config.UpdatingErr;
                    iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                });

            this.elements.submitted = false;
            //
            this.elements.submitted = false;
        },
        addNotes(candidateId) {
            this.form.title = "Add Notes";
            this.form.userId = candidateId;
            this.form.communicationType = "notes";
            // this.$refs.notesModal.show();
        },
        addCallback(candidateId) {
            this.form.title = "Callback Settings";
            this.form.userId = candidateId;
            this.form.communicationType = "callback";

            // this.$refs.notesModal.show();
        },
        commonCallbackNotesSubmit: function() {
            switch (this.form.communicationType) {
                case "callback":
                    this.add_Callback();
                    break;
                case "notes":
                    this.add_Notes();
                    break;
                default:
                    this.add_Notes();
                    break;
            }
        },
        add_Callback: function() {
            this.elements.submitted = true;
            this.handleSubmit();
            if (!this.validateCallbackData()) {
                return false;
            }
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/add/candidate/callback', this.form)
                .then(response => {
                    var result = response.data;
                    if (result.status == "ACCEPTED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.loadCandidates();
                        this.resetModal();
                    }

                })
                .catch(error => {
                    console.log(error);
                    this.unAuthorized(error);
                });
        },
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.elements.submitted = true;
            this.handleSubmit();
        },
        handleSubmit() {
            if (this.elements.submitted) {
                if (!this.checkFormValidity()) {
                    console.log("error");
                    return false;
                }
                console.log("submit form");
                this.$nextTick(() => {
                    this.$refs.notesModal.hide();
                });
            } else {
                return false;
            }
        },
        resetModal() {
            this.form.userId = null;
            this.form.communicationType = "";
            this.form.callbackNotes = "";
            this.form.callbackDate = "";
            this.form.callbackStartTime = "";
            this.form.callbackEndTime = "";
            this.elements.submitted = false;
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity();
            return valid;
        },
        findCandidates() {
            this.pagination.currentPage = 1;
            this.loadCandidates();
        },
        exportCandidates() {
            this.updateLogActivity();
            this.addLogActivity('Export Candidates');
            this.search.params.candidateContactAccess = this.candContactAccess;
            axios.post(this.$store.getters.getAPIBasePath + '/exportresource/export/candidatelist/report', this.search.params, { responseType: 'arraybuffer' })
                .then(response => {
                    let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, '');

                    let fileName = "Candidates" + "_" + currentDateWithFormat + ".xlsx";

                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;

                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);
                })
                .catch(error => {
                    console.log(error.response.data);
                    this.unAuthorized(error);
                    this.reportsearcherrors = error.response.data.errorlist;
                    iziToast.error({
                        title: 'Warning!',
                        message: error.response.data.message,
                        position: 'topRight'
                    });
                    this.reports = [];
                });

        },
        cancelJobList: function() {
            this.params.jobTitle = "";
            this.params.jobCode = "";
            this.selectedValue = "";
            this.$v.selectedValue.$reset();
            this.elements.submitted = false;

        },
        setCandidateId: function(candidateinfo) {
            this.candidateId = candidateinfo.id;
            this.candidateAttachment = candidateinfo.attachmentTypeId;          
        },
        addCandidate: function() {
            this.addLogActivity('New candidate');
            localStorage.setItem('resumeCapture', null);
            localStorage.setItem('resumeCaptureOld', null);
            localStorage.setItem('parsedResumeFileName', '');
            this.$router.push('/candidates/add');
        },
        extractResume: function() {
            localStorage.setItem('resumeCapture', null);
            localStorage.setItem('resumeCaptureOld', null);
            let formData = new FormData();
            formData.append('file', this.resumeinfo.file);
            formData.append('resume_file', this.resumeinfo.file);
            // if (this.resumeinfo.extension != null && this.resumeinfo.extension == 'pdf') {
            // this.readPDF(formData);
            // } else {
            this.readWord(formData);
            //}
        },
        extractResumeOld: function() {
            localStorage.setItem('resumeCapture', null);
            localStorage.setItem('resumeCaptureOld', null);
            let formData = new FormData();
            formData.append('file', this.resumeinfo.file);
            //formData.append('resume_file', this.resumeinfo.file);
            // if (this.resumeinfo.extension != null && this.resumeinfo.extension == 'pdf') {
            // this.readPDF(formData);
            // } else {
            this.readWordOld(formData);
            //}
        },
        readPDF: function(formData) {
            axios.post(this.$store.getters.getAPIBasePath + '/resumeparserresource/readpdf', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    localStorage.setItem('resumeCapture', "true");
                    localStorage.setItem('resume', JSON.stringify(this.resumeinfo.file));
                    this.parsedData = response.data.parsedData;
                    localStorage.setItem('parsedData', JSON.stringify(this.parsedData));
                    this.$router.push('/candidates/add');

                })
                .catch(error => {

                });
        },
        readWord: function(formData) {
            this.showOverlay=true;
            axios.post(this.$store.getters.getAPIBasePath + '/resumeparserresource/singleparser/api',
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    }).then(response => {
                    this.showOverlay=false;        
                    let emailExists = response.data.emailExists;
                    if (response.data.emailExists) {
                        localStorage.setItem('parsedResumeFileName', response.data.filePath);
                        localStorage.setItem('parseFilePath', response.data.filePath);
                        localStorage.setItem('extractCandidateId', response.data.candidateId);
                        this.$root.$emit('bv::show::modal', 'compareCandidate');
                        return;
                    } else {
                        localStorage.setItem('resumeCapture', "true");
                        localStorage.setItem('resume', JSON.stringify(this.resumeinfo.file));
                        localStorage.setItem('parsedResumeFileName', response.data.filePath);
                        this.parsedData = response.data.parserData;
                        localStorage.setItem('parseFilePath', response.data.filePath);
                        localStorage.setItem('parsedData', JSON.stringify(this.parsedData));

                        this.$router.push('/candidates/add');
                    }
                })
                .catch(error => {
                    this.showOverlay=false;
                    this.unAuthorized(error);
                    console.log(error);

                });
            // old our parser resume code    
            /* axios.post(this.$store.getters.getAPIBasePath + '/resumeparserresource/readword', formData, {
                     headers: {
                         
                         'Content-Type': 'multipart/form-data'
                     }
                 }).then(response => {                    
                     localStorage.setItem('resumeCapture', "true");
                     localStorage.setItem('resume', JSON.stringify(this.resumeinfo.file));
                     localStorage.setItem('parsedResumeFileName', this.resumeinfo.file.name);
                     this.parsedData = response.data.parsedData;
                     localStorage.setItem('parsedData', JSON.stringify(this.parsedData));
                     this.$router.push('/candidates/add');

                 })
                 .catch(error => {

                 });*/
        },
        readWordOld: function(formData) {
            // old our parser resume code    
            axios.post(this.$store.getters.getAPIBasePath + '/resumeparserresource/readword', formData, {
                    headers: {

                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    localStorage.setItem('resumeCaptureOld', "true");
                    localStorage.setItem('resume', JSON.stringify(this.resumeinfo.file));
                    localStorage.setItem('parsedResumeFileName', this.resumeinfo.file.name);
                    this.parsedData = response.data.parsedData;
                    localStorage.setItem('parsedData', JSON.stringify(this.parsedData));
                    this.$router.push('/candidates/add');

                })
                .catch(error => {

                });
        },
        uploadFileLocal: function(formData) {
            axios.post(this.$store.getters.getAPIBasePath + '/resumeparserresource/uploadlocal', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    let status = response.status;
                    console.log(response);
                })
                .catch(error => {

                });
        },
        emailtab() {
            this.updateLogActivity();
            this.elements.showEmailTools = true;
            this.addLogActivity('Email');
        },
        gettechsubstring(technology) {
            if (technology != null && technology.length > 10) {
                return technology.substring(0, 10) + "...";
            } else
                return technology;

        },
        addLogActivity: function(action) {
            this.updateLogActivity();
            this.logActivityData = {
                entityName: 'Candidate',
                actionName: action,
            };
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/add/logactivity', this.logActivityData)
                .then(response => {})
                .catch(error => {
                    console.log(error);
                });
        },
        updateLogActivity: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/update/logactivity')
                .then(response => {})
                .catch(error => {
                    console.log(error);
                });
        },
        handleMultiFileUpload(event) {

            let formData = new FormData();
            for (const row of event.target.files) {
                formData.append('file', row);
            }
            formData.append('title', this.bulkUpload.title);

            axios.post(this.$store.getters.getAPIBasePath + '/resumeparserresource/parser/api', formData)
                .then(response => {
                    let result = response.data.loadfiles;
                    console.log(response);
                    iziToast.success({
                                title: 'Success',
                                message: response.data.loadfiles,
                                position: 'topRight'
                            });
                    })
                .catch(error => {
                    console.log(error);
                });
        },
        getCandidateId: function(candId, event) {
            if (event.target.checked) {
                this.showShare = true;
                // for (let row in this.shareCandidate.candidateId) {
                //     if (candId != this.shareCandidate.candidateId[row])

                this.shareCandidate.candidateId.push(candId);
                // }
            } else {
                if (this.shareCandidate.candidateId.length == 1)
                     this.showShare = false;
            }
        },            
        // getCandidateId: function(candId, event) {
        //     if (event.target.checked) {
        //         axios.get(this.$store.getters.getAPIBasePath + '/candidateresource/shareresume/returnmessage')
        //         .then(response => {
        //             let result = response.data;
        //             if (result.status == true) {
        //                 this.showShare = true,
        //                 this.disableshare = false
        //             }
        //             else{
        //                 this.message = result.message;
        //                 this.showShare = false;
        //                 this.disableshare = true;
                       
        //             }
        //         })
        //         .catch(error => {
        //             iziToast.error({ title: 'Error Occured', message: 'Couldnt share the candidate. Please try again', position: 'topRight' });
        //             console.log(error);
        //             this.unAuthorized(error);
        //         });
                
        //     } else 
        //         if (this.shareCandidate.candidateId.length == 1){
        //             this.showShare = false;
        //             this.disableshare = false;
        //     }
        // },
        getClientId: function(clientId, event) {
            if (event.target.checked) {
                // for (let row in this.shareCandidate.sharedToTtcaid) {
                //     if (candId != this.shareCandidate.sharedToTtcaid[row]) {
                this.shareCandidate.sharedToTtcaid.push(clientId);
                //     }
                // }
            }
        },
        loadClients() {
            var countryId = localStorage.getItem('countryId');
            axios.post(this.$store.getters.getAPIBasePath + '/recruiterresource/client/list', this.clientParams)
                .then(response => {
                    let result = response.data;
                    this.clientlist = result.clientAccountList.filter(clients => clients.companyCountry == countryId && this.ttClientAccountId != clients.id); 
                })
                .catch(error => {
                    this.unAuthorized(error);
                    console.log(error);

                });
        },
        async addCandidateShare() {
            this.showOverlay=true;
            await this.getCandidateShare();
            this.showOverlay=false;
        }, 
        getCandidateShare() {

            if (this.shareCandidate.sharedToTtcaid.length == 0) {
                iziToast.info({
                    title: 'Info',
                    message: this.$config.ChooseCli,
                    position: 'topRight'
                });
                return false;
            }

            let candidateShare = [];
            for (var i = 0; i < this.shareCandidate.candidateId.length; i++) {
                // for (var j = 0; j < this.shareCandidate.sharedToTtcaid.length; j++) {
                candidateShare.push({
                    candidateId: this.shareCandidate.candidateId[i],
                    // sharedToTtcaid: this.shareCandidate.sharedToTtcaid[j],
                });
                // }
            }
            let data = {
                sharedToCompany: this.shareCandidate.sharedToTtcaid,
                candidateShare: candidateShare,
                noOfCandidates: this.shareCandidate.candidateId.length
            }
            console.log('data', data);
            return axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/add/candidate/share', data)
                .then(response => {
                    let result = response.data;
                      if (result.message == "Maximum Limit Exist") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                    if (result.sharedCand == "SHARED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.showShare = false
                    }
                    if (result.sharedCand == "NOT_SHARED") {
                        iziToast.info({
                            title: 'Info',
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                    this.shareCandidate.candidateId = [];
                    this.shareCandidate.sharedToTtcaid = [];
                })
                .catch(error => {
                    this.showOverlay=false;
                    console.log(error);
                    this.unAuthorized(error);
                });
        },
        getWorkAuthorizations: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/settingsresource/settingworkauthorization/list')
                .then(response => {
                    this.workAuthorizations = response.data.settingsWorkAuthorization;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        compareCandidate: function() {
            localStorage.setItem('emailAlreadyExists', "true");
            this.$router.push('/candidates/edit/' + localStorage.getItem('extractCandidateId'));
        },
        getCandidateNotes(id){
           if (this.currentIndex == id) {
                this.currentIndex = null;
            } else {
                this.currentIndex = id;
                this.selectedInterviewId = id;
            }

            axios.get(this.$store.getters.getAPIBasePath + '/notesresource/get/notes/' + id)
                .then(response => {
                    let result = response.data.data;
                    this.candidateNotesList = result;
                    
                    console.log(this.candidateNotesList)
                })
                .catch(error => {
                    console.log(error);
                });
        },
        AddRecruitmentNotes(id){
            this.$refs["my-modal"].show();
            this.saveCustomNotes.referenceId = id;
        },
        addCustomNotes(){        
          
            axios.post(this.$store.getters.getAPIBasePath + '/notesresource/add/notes' , this.saveCustomNotes)
                .then(response => {
                    let result = response.data.message;
                  console.log(result);
                  if(result == "Notes Data added.") {
                       iziToast.success({
                            title: 'Success',
                            message: result,
                            position: 'topRight'
                        });
                    
                        this.$refs["my-modal"].hide();
                        this.getCandidateNotes(this.saveCustomNotes.referenceId)
                        this.saveCustomNotes.description="";
                  }

                })
                .catch(error => {
                    console.log(error);
                });
        },
         hideModal() {
            this.$refs["my-modal"].hide();
        },
         deleteNote(noteId,candidateId) {
            this.delNoteParam.id = noteId;
            this.delNoteParam.candidateId = candidateId;
        },
         deletePopupNote(noteId,candidateId) {
            this.delNoteParam.id = noteId;
            this.delNoteParam.candidateId = candidateId;
        
            axios.delete(this.$store.getters.getAPIBasePath + '/notesresource/delete/notes/'+this.delNoteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getCandidateNotes(this.delNoteParam.jobId);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
         delete_note() {
        
            axios.delete(this.$store.getters.getAPIBasePath + '/notesresource/delete/notes/'+this.delNoteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getCandidateNotes(this.delNoteParam.jobId);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        
    }
}

</script>
<style scoped>
.all-table tbody td {
    background-color: transparent;

}

</style>
