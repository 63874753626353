<template>
    <div id="main" class="mt-80 d-height">
        <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.5" justify="center" class="h-100">
            <div class="container-fluid" id="quick-search">
                <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-6">
                            <h4 class="page-title">Resume Search</h4>
                            <!-- candidateData.setTtClientAccountId(commonService.getClientAccountId(request)); -->
                        </div>
                        <div class="col-md-6 col-sm-6 col-6">
                            <ul class="ul-horizontal box-tools text-right mb-0">
                                <li><a href="Javascript:void(0);" v-on:click="isResumeSearchExpand=!isResumeSearchExpand;" data-target="resume-search" class="boxhide"><span :class="isResumeSearchExpand?'ti-angle-up':'ti-angle-down'"></span></a></li>
                                <!--  <li><a href="" data-target="main-search" class="boxhide"><span class="ti-angle-down"></span></a></li> -->
                            </ul>
                        </div>
                    </div>
                    <form id="resume-search" :class="isResumeSearchExpand?'d-block':'d-none'" class="pb-4">
                        <div class="row form-group ten-columns">
                            <div class="col-md-10 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Search Keywords</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input type="text" v-on:keyup.enter="searchResumes()" name="searchResume" value="" id="searchResume" class="form-control" v-model="searchResume">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Exclude Keywords</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input type="text" v-on:keyup.enter="searchResumes()" name="excludeTerms" value="" id="excludeTerms" class="form-control" v-model="excludeTerms">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 pt-5" style="margin-top:50px;">
                                <button type="button" @click="resetSearchResume" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Reset</button>
                                <button type="button" @click="searchResumes" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Search</button>
                            </div>
                        </div>
                        <div class="row ml-1 mt-5 mb-1">
                            <div class="col-md-6 col-sm-12 col-12">
                                <h4 class="page-title pb-3">Candidates
                                <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{searchpagination.totalRecords}} - Total)</span>
                                    <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                        <div class="dropdown">
                                                <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                            <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                                <a v-for="(value, index) in searchpagination.searchlimit" class="dropdown-item" href="javascript:void(0);" @click="searchchangeLimit(value)">{{value}}</a>
                                            </div>
                                        </div>
                                    </span>
                                </h4>
                            </div>
                        </div>
                        <div class="row ml-1">
                            <div class="col-md-12">
                                <div class="table-responsive" style="min-height: 50px; padding-right:12px;">
                                    <table class="table table-borderless all-table">
                                        <thead>
                                            <tr>
                                                <th class="" scope="col">Candidate Code</th>
                                                <th class="" scope="col">Name</th>
                                                <th v-if="false" class="" scope="col">Email</th>
                                                <th class="" scope="col">Experience</th>
                                                <th class="" scope="col">Industry</th>
                                                <th class="" scope="col">Technology</th>
                                                <th class="" scope="col">Recruiter Name</th>
                                                <th class="" scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="resumeList.length==0">
                                                <td colspan="26" class="text-center">No data found</td>
                                            </tr>
                                            <tr v-for="(resumeinfo, index) in resumeList">
                                                <td class="">{{resumeinfo.candidateCode}}</td>
                                                <td class="">{{resumeinfo.candidateName}}</td>
                                                <td v-if="false" class="">{{resumeinfo.email}}</td>
                                                <td class="">{{resumeinfo.experience}} <span v-if="resumeinfo.experience">{{resumeinfo.expType}}</span></td>
                                                <td class="">{{resumeinfo.industryName}}</td>
                                                <td class="">{{resumeinfo.technology}}</td>
                                                <td class="">{{resumeinfo.recruiterName}}</td>
                                                <td class="">
                                                    <div class="row ten-columns" v-if="resumeinfo.filePath!=undefined">
                                                        <!-- <a v-if="false" href="javascript:">Download</a> -->
                                                        <span v-if="(ttClientAccountId == resumeinfo.ttClientAccountId || resumeinfo.isViewed==1)"><a :href="resumeinfo.filePath" @click="resumeDownload(resumeinfo.candidateId)"><i class="ti-import button"></i></a></span>
                                                        <span class="pl-3"><a href="javascript:(0)" data-toggle="modal" data-target="#viewResume" @click="resumeView(resumeinfo.candidateId,resumeinfo.filePath)"><i class="ti-eye button"></i></a></span>
                                                        <!-- <iframe src="https://itgigdata.s3.amazonaws.com/resume/local/candidate/profile/963/Devraj_Beniwal.docx" width='1066px' height='623px' frameborder='0'>This is an embedded </iframe>
                                                        <iframe :src="https://docs.google.com/gview?url=" + this.resumeinfo.fileurl + "&embedded=true" frameBorder="0" style="background-color: #FFFFFF" width="1000" height="800" allowfullscreen></iframe> -->
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-md-12">
                                    <b-pagination v-if="resumeList.length!=0" size="md" class='pull-right' :total-rows="searchpagination.totalRecords" v-model="searchpagination.currentPage" :per-page="searchpagination.rowsPerPage" @input="searchResumes()"></b-pagination>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="load-indicator"></div>
                </div>
            </div>
            <div class="container-fluid" id="quick-search">
                <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-6">
                            <h4 class="page-title">Search</h4>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6">
                            <ul class="ul-horizontal box-tools text-right mb-0">
                                <li><a href="Javascript:void(0);" v-on:click="isExpand=!isExpand;" data-target="main-search" class="boxhide"><span :class="isExpand?'ti-angle-up':'ti-angle-down'"></span></a></li>
                                <!--  <li><a href="" data-target="main-search" class="boxhide"><span class="ti-angle-down"></span></a></li> -->
                            </ul>
                        </div>
                    </div>
                    <form id="main-search" :class="isExpand?'d-block':'d-none'" class="pb-4">
                        <div class="row form-group ten-columns">
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Job Title</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input type="text" name="jobTitle" maxlength="50" value="" id="jobTitle" class="form-control" v-on:keyup.enter="findJobs()" v-model="search.params.jobTitle">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Job Code</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input type="text" v-on:keyup.enter="findJobs()" v-model="search.params.jobCode" name="jobId" maxlength="25" value="" id="jobId" class="smallCombo form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Client</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <!-- <select name="selectedJobType" size="1" id="selectedJobType" class="smallCombo select2"><option value="" selected="selected">All</option></select> -->
                                        <select v-model="search.params.clientId" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(data, index) in clientlist" :value="data.id">{{data.client_Name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-12 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label class="pull-left">Company Name</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <select v-model="search.params.ttClientAccountId" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(data, index) in companyClientList" :value="data.id">{{data.companyName}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Industry</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <select v-model="search.params.industryId" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(data, index) in industrylist" :value="data.id">{{data.industryName}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Status</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <select v-model="search.params.status" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(data, index) in jobStatuslist" :value="data.id">{{data.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Hot Jobs</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <select v-model="search.params.priority" class="form-control" >
                                                <option value="">Select</option>
                                                <option value=true>Yes</option>
                                                <option value=false>No</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div v-if="false" class="col-md-1 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Recruiter</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <select v-model="search.params.recId" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(data, index) in recruiterlist" :value="data.id">{{data.recruiterName}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div  class="col-md-1 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label style="visibility: hidden;">Recruiter</label>
                                    </div>
                                    <div class="col-md-12" style="display: flex;justify-content: flex-end;">
                                        <button type="button" @click="findJobs" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Find</button>
                                        <button type="button" @click="resetJobSearch" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">Reset</button>
                                    </div>
                                </div>
                            </div>        
                        </div>             
                    </form>
                    <div class="load-indicator"></div>
                </div>
            </div>
            <div class="container-fluid" id="main-data">
                <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                    <div class="row" style="margin-bottom: 15px;">
                        <div class="col-md-6 col-sm-12 col-12">
                            <h4 class="page-title">Jobs
                                <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{pagination.totalRecords}} - Total)</span>
                                <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                    <div class="dropdown">
                                        <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                        <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                            <a v-for="(value, index) in searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeLimit(value)">{{value}}</a>
                                        </div>
                                    </div>
                                </span>
                            </h4>
                        </div>
                        <div class="col-md-6 d-none d-sm-none d-md-block">
                            <button type="button" @click="addLogActivity('New Job')" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">
                                <router-link :to="{ name: 'addjob' }" style="color: #fff;">New Job</router-link>
                            </button>
                            <button type="button" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">
                                <router-link :to="{ name: 'progressinterviews' }" style="color: #fff;">Interviews</router-link>
                            </button>
                            <span class="float-right" @click="exportJobs()"><i class="value"> <img class="hover-hand" src="@/assets/icons/excel-24.png" title="Download" alt=""></i></span>
                        </div>
                        <div v-if="false" class="col-md-6 d-none d-sm-none d-md-block">
                            <ul class="ul-horizontal table-fields text-right">
                                <li>
                                    <span>Show / Hide</span>
                                </li>
                                <li>
                                    <input type="checkbox" class="field-chekbox" name="jobTitle" id="jobTitle" checked="">
                                    <label for="jobTitle" v-on:click="coldisplay.jobTitle = !coldisplay.jobTitle"><span></span>Job Title</label>
                                </li>
                                <li>
                                    <input type="checkbox" class="field-chekbox" name="jobCode" id="jobCode" checked="">
                                    <label for="jobCode" v-on:click="coldisplay.jobCode = !coldisplay.jobCode"><span></span>Job Code</label>
                                </li>
                                <li>
                                    <input type="checkbox" class="field-chekbox" name="client" id="client" checked="">
                                    <label for="client" v-on:click="coldisplay.client = !coldisplay.client"><span></span>Client</label>
                                </li>
                                <li>
                                    <input type="checkbox" class="field-chekbox" name="industry" id="industry" checked="">
                                    <label for="industry" v-on:click="coldisplay.industry = !coldisplay.industry"><span></span>Industry</label>
                                </li>
                                <li>
                                    <input type="checkbox" class="field-chekbox" name="position" id="position" checked="">
                                    <label for="position" v-on:click="coldisplay.position = !coldisplay.position"><span></span>Position</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive" style="min-height: 178px;"> -->
                <div class="card-body p-0">
                    <div class="basic-block clone-preference">
                        <div class="row pos-relative clone filled2">
                            <div class="col-md-12 col-sm-12 col-12 mb-10">
                                <div class="table-responsive">
                                <table class="table table-borderless all-table">
                                    <thead>
                                        <tr>
                                            <th v-if="false" class="" scope="col"></th> 
                                            <th></th>
                                            <th v-if="coldisplay.jobCode" class="" scope="col">Code</th>
                                            <th v-if="coldisplay.companyName" class="" scope="col">Company Name</th>
                                            <th v-if="coldisplay.jobTitle" class="" scope="col">Job Title</th>
                                            <th v-if="coldisplay.client" class="" scope="col">Client</th>
                                            <th v-if="coldisplay.industry" class="" scope="col">Industry</th>
                                            <!-- <th v-if="coldisplay.position" class="" scope="col">Position</th> -->
                                            <th class="" scope="col">Exp</th>
                                            <th class="" scope="col">Location</th>
                                            <th class="" scope="col">Vacancies</th>
                                            <th class="" scope="col">Rec. Status</th>
                                            <th class="" scope="col">Due Date</th>
                                            <th class="" scope="col">Posted Date</th>
                                            <th class="" scope="col">Status</th>
                                            <th class="" scope="col">Recruiter</th>
                                            <th class="" scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="elements.loadingJob == true && joblist.length==0">
                                            <td colspan="26" class="text-center">No data found</td>
                                        </tr>

                                        <template v-for="(jobinfo, index) in joblist">
                                        <tr :style="[jobinfo.priority === true ? {backgroundColor: 'lightblue'}: {backgroundColor: 'white'}]" >
                                                <td>

                                                    <a v-if="jobinfo.ttClientAccountId == ttClientAccountId" href="javascript:" class="expand" data-targetopen="panel1">
                                                        <span @click="getnotes(jobinfo.id)"><i :class="(currentIndex==jobinfo.id) ? 'ti-arrow-circle-up' : 'ti-arrow-circle-down'"></i>
                                                        </span>
                                                    </a>
                                                </td>
                                                
                                            <td v-if="false">
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox" id="rowchckbox1" value="option1">
                                                    <label class="form-check-label" for="rowchckbox1"></label>
                                                </div>
                                            </td>  
                                            <td v-if="coldisplay.jobCode" class="">
                                                <router-link title="View Job" :to="{ name: 'planrecruitment', params: { jobid: jobinfo.id } }">{{ jobinfo.jobCode }}</router-link>
                                            </td>
                                            <td v-if="coldisplay.companyName" class="">
                                                <router-link title="View Job" :to="{ name: 'planrecruitment', params: { jobid: jobinfo.id } }">{{ jobinfo.companyName }}</router-link>
                                            </td>
                                            <td v-if="coldisplay.jobTitle" class="">
                                                <a href="Javascript:void(0);" @click="updateLogActivity">
                                                    <router-link title="View Job" :to="{ name: 'planrecruitment', params: { jobid: jobinfo.id } }">{{jobinfo.jobTitle }}</router-link>
                                                </a>
                                            </td>
                                            <td v-if="coldisplay.client" class=""><span  v-if="jobinfo.ttClientAccountId == ttClientAccountId">{{ jobinfo.clientName }}</span></td>
                                            <td v-if="coldisplay.industry" class="">{{ jobinfo.industryName }}</td>
                                            <!--  <td v-if="coldisplay.position" class="">{{ jobinfo.position }}</td> -->
                                            <td class="">{{ jobinfo.experience }}</td>
                                            <td class="">{{ jobinfo.location }}</td>
                                            <td class="">{{ jobinfo.vacancies }}</td>
                                            <td>
                                                <ul class="navbar-nav">
                                                    <li class="nav-item">
                                                        <a class="nav-link p-0 font-weight-bold" href="Javascript:void(0);" @click="viewRecDetails(jobinfo.id)" id="recCandInfo" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <span class="p-1 mb-2 badge badge-secondary"> Hired - {{ jobinfo.hired }}</span><span title="View more details" class="dropdown-toggle pl-2"></span></a>
                                                        <ul class="dropdown-menu" aria-labelledby="recCandInfo">
                                                            <div class='table-responsive blue-bg text-white'>
                                                                <table class='table mb-0'>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Talent Identified</td>
                                                                            <td>{{recCandInfo.talentCount}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Shortlisted Candidates</td>
                                                                            <td>{{recCandInfo.candShortlistedCount}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Client Reviewed</td>
                                                                            <td>{{recCandInfo.clientReviewedCount}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Hired</td>
                                                                            <td>{{recCandInfo.hiredCount}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </td>
                                            <td class="endDate" format="MM-dd-yyyy">{{ jobinfo.dueDate | formatDate }}</td>
                                            <td class="endDate" format="MM-dd-yyyy">{{ jobinfo.createdDate | formatDate }}</td>
                                            <td class="">
                                                <span v-if="jobinfo.ttClientAccountId == ttClientAccountId" >
                                                    <ul class="navbar-nav">
                                                    <li class="nav-item">
                                                        <a class="nav-link p-0 font-weight-bold" href="Javascript:void(0);" style="display: flex;" id="recCandInfo" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <span class="p-1 mb-2 badge badge-secondary w-55-auto"> {{ jobinfo.jobStatus}}</span><span title="View more details" class="dropdown-toggle pl-2"></span></a>
                                                        <ul class="dropdown-menu" aria-labelledby="recCandInfo">
                                                            <div class='table-responsive blue-bg text-white'>
                                                                <table class='table mb-0'>
                                                                    <tbody>
                                                                        <tr v-for="(data, index) in jobStatuslist" :value="data.id">
                                                                            <td @click="updateStatus(jobinfo.id,data.id)" style="cursor: pointer;">{{data.name}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </ul>
                                                    </li>
                                                </ul>
                                                </span>
                                                <span v-else class="p-1 mb-2 badge badge-secondary w-55-auto">{{ jobinfo.jobStatus}}</span>
                                            </td>
                                            <!-- <td class="">
                                                <span v-if="jobinfo.ttClientAccountId == ttClientAccountId" >
                                                    <select v-model="status" class="form-control">
                                                    <option value="">{{ jobinfo.jobStatus}}</option>
                                                    <option v-for="(data, index) in jobStatuslist" :value="data.id">{{data.name}}</option>
                                                    </select>
                                                </span>
                                                <span v-else>{{ jobinfo.jobStatus}}</span>
                                            </td> -->
                                            <td class="">{{ jobinfo.recruiterName}}</td>
                                            <td>
                                                <ul class="navbar-nav">
                                                    <li class="nav-item">
                                                        <a class="nav-link dropdown-toggle p-0 font-weight-bold" href="Javascript:void(0);" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions</a>
                                                        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                            <li>
                                                                <router-link class="dropdown-item" :to="{ name: 'planrecruitment', params: { jobid: jobinfo.id }}"><span class="ti-eye"></span> View Job</router-link>
                                                            </li>
                                                            <li v-if="jobinfo.ttClientAccountId == ttClientAccountId">                                                   
                                                                <router-link class="dropdown-item" :to="{ name: 'editjob', params: { jobid: jobinfo.id }}"><span class="ti-pencil"></span> Edit Job</router-link>
                                                            </li>
                                                            <a class="dropdown-item" href="javascript:;" v-b-modal.AssignJobModal @click="assignJobToRecruiters(jobinfo.id,jobinfo.jobTitle)" data-toggle="modal" data-target="#asignJob"><span class="ti-calendar"></span> Assign Job</a>
                                                            <li>
                                                                <router-link class="dropdown-item" :to="{ name: 'planrecruitment', params: { jobid: jobinfo.id }}"><span class="fa fa-users"></span> Recruitment</router-link>
                                                            </li>
                                                            <li v-if="jobinfo.ttClientAccountId == ttClientAccountId">
                                                                <a class="dropdown-item" href="javascript:;" @click="deleteJob(jobinfo.id)" data-toggle="modal" data-target="#removeJob"><span class="ti-cut"></span> Deactivate Job</a>
                                                            </li>
                                                            <li v-if="jobinfo.ttClientAccountId == ttClientAccountId">
                                                                <a v-if="jobinfo.priority == true" class="dropdown-item" href="javascript:;" @click="setHotJobParams(jobinfo.id, 0);" data-toggle="modal" data-target="#resetJobs"><span class="ti-reload"></span> Reset Hot Job</a>
                                                                <a v-if="jobinfo.priority == false" class="dropdown-item" href="javascript:;" @click="setHotJobParams(jobinfo.id, 1);" data-toggle="modal" data-target="#makeJobs"><span class="ti-star"></span> Make Hot Job</a>
                                                            </li>
                                                            <li>
                                                                <!-- <a class="dropdown-item" href="javascript:;"  v-b-modal.recruiterlist-form-modal @click="assignJobToRecruiters(jobinfo.id,jobinfo.jobTitle)" data-toggle="modal" data-target="#asignJob"><span class="ti-calendar"></span> Assign Job</a> -->
                                                            </li>
                                                            <li v-if="jobinfo.ttClientAccountId == ttClientAccountId">
                                                                <a class="dropdown-item" href="javascript:;" data-toggle="modal"  @click="AddRecruitmentNotes(jobinfo.id)" ><span class="ti-plus"></span> Add Job Notes</a>
                                                            </li>
                                                            
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </td>  
                                        </tr>


                                        <tr v-if="currentIndex == jobinfo.id">
                                        <td colspan="15">
                                        <div class="table-responsive">
                                            <table class="table table-borderless all-table">
                                                        <thead>
                                                                <tr>
                                                                    <th class="" scope="col">Title</th>
                                                                    <th class="" scope="col">Note</th>
                                                                    <th class="" scope="col">Added On</th>    
                                                                    <th class="" scope="col">Added By</th>
                                                                    <th class="" scope="col">Action</th>
                                                                
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr v-for="info in visibleNotes" :key="info.id" :id="info.id">
                                                            <td>{{ info.title }}</td>
                                                            <td>{{ info.description }}</td>
                                                            <td>{{ info.createdUtc | formatDate }}</td>
                                                            <td>{{ info.addedByName }}</td>
                                                            
                                                            <td>  
                                                            <a v-if="info.typeId==5 && jobCreatedId==info.addedBy" href="javascript:;" @click="deleteNote(info.id,info.referenceId)" data-toggle="modal" data-target="#removeJobNotes"><span class="ti-cut"></span></a>                                     
                                                            </td>  
                                                            
                                                            </tr>
                                                            
                                                            <td colspan="14" >
                                                            <div style="margin-left:900px">
                                                            <a v-if="jobNotesList.length>3" href="javascript:;" style="align: 'center'," data-toggle="modal" data-target="#RecruitmentNotes" ><i ></i >Load more</a>
                                                            </div>
                                                            </td>
                                                            

                                                            <tr  v-if="jobNotesList.length==0">
                                                                    <td style="border:0px;" colspan="14" align="center">No Notes Found
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                            </table>
                                        
                                        </div>

                                            </td>      
                                        </tr>
                                        
                                        
                                    
                                        </template>
                                    
                                    </tbody>
                                </table>
                            </div>
                        </div>
                </div>
                </div>
                        <div>
                        <b-modal ref="my-modal" id="RecruitmentNotes"  hide-footer title="Notes" size="lg">
                        <div  class="modal-body">
                        <table class="table table-borderless all-table">
                                                        <thead>
                                                                <tr>
                                                                    <th class="" scope="col">Title</th>
                                                                    <th class="" scope="col">Note</th>
                                                                    <th class="" scope="col">Added On</th>
                                                                    <th class="" scope="col">Added By</th>
                                                                    <th class="" scope="col">Action</th>
                                                                
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr v-for="info in jobNotesList">
                                                            <td>{{ info.title }}</td>
                                                            <td>{{ info.description }}</td>
                                                            <td>{{ info.createdUtc | formatDate }}  {{ info.createdUtc | formatTime }}</td>
                                                            <td>{{ info.addedByName }}</td>
                                                            <td>
                                                            
                                                            <a v-if="info.typeId==5 && jobCreatedId==info.addedBy" href="javascript:;" @click="deletePopupNote(info.id,info.referenceId)" data-toggle="modal"><i class="ti-cut"></i></a>                                           
                                                            </td>  
                                                            
                                                            </tr>
                                                    
                                                                </tbody>
                                            </table>

                                            
                                        

                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                class="btn btn-secondary"
                                @click="hideModal"
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                        </div>


                    </b-modal>
                    </div>

                    <div>
                    <b-modal ref="my-modal" id="AddRecruitmentNotes"  hide-footer title="Notes" size="md">
                        <div  class="modal-body">
                        <textarea  style="height: 10em;" name="Notes" maxlength="5000" value="" class="fldboxbig form-control" v-model="saveCustomNotes.description"></textarea>
                        <!-- <textarea  style="resize:none" cols="60" rows="10"></textarea> -->
                        </div>
                        <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="addCustomNotes()" data-dismiss="modal">  Save </button>
                            <button type="button" class="btn btn-secondary" @click="hideModal" data-dismiss="modal">  Close </button>
                        </div>
                    </b-modal>
                </div>


                        <div class="col-md-12">
                            <b-pagination :style="[showOverlay === true ? {pointerEvents: 'none'}: {pointerEvents: 'auto'}]" v-if="joblist.length!=0" size="md" class='pull-right' v-model="pagination.currentPage" :total-rows="pagination.totalRecords"  :per-page="pagination.rowsPerPage" @input="loadJobs()"></b-pagination>                        
                        </div>
                    </div>
                </div>
            </div>
            <Dialog id="makeJobs" :onYes="saveHotJob" :returnParams="makJobParam" title="Make Hot Job Confirmation" message="Are you sure to make hot job ?" />
            <Dialog id="resetJobs" :onYes="saveHotJob" :returnParams="makJobParam" title="Reset Hot Job Confirmation" message="Are you sure to reset hot job ?" />
            <Dialog id="removeJob" :onYes="delete_Job" :returnParams="delJobParam" title="Deactivate Confirmation" message="Are you sure to deactivate ?" />
            <Dialog id="removeJobNotes" :onYes="delete_note" :returnParams="delNoteParam" title="Delete Note" message="Are you sure to delete note ?" />
            <!-- <Dialog id="removeNote" :onYes="delete_note" :returnParams="delNoteParam" title="Delete Note" message="Are you sure to delete ?" /> -->

            <Dialog id="hire-info" title="Recruitment Info" :showFooter="false" :message="recCandInfo.htmlMessage" />
            <!--Recruiter Dialog -->
            <!-- <b-modal id="recruiterlist-form-modal" class="h-25" :no-close-on-backdrop="true" ref="resumeModal" title="Recruiters" @show="" @hidden="" @ok="addRecruiter" @cancel="updateLogActivity" @close="updateLogActivity">
                <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 recrutierinfo">
                                <table class="table table-borderless all-table">
                                    <thead>
                                        <tr>
                                            <th>
                                            </th>
                                            <th>Recruiter Name</th>
                                            </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="!recruiterlist.length">
                                            <td class="text-center" colspan="100%">No Recruiter found</td>
                                        </tr>
                                        <tr v-for="(recruiter,index) in recruiterlist">
                                            <td>
                                                <div class="col-sm-12 col-12">
                                                    <input class="form-check-input" type="checkbox"  v-model ="assignJob.recruiterId" :id="recruiter.id" :value="recruiter.id" v-on:click="getRecruiterId(recruiter.id,$event)">

                                                <label class="form-check-label" :for="recruiter.id"></label>
                                                </div> 
                                            </td> 
                                            <td>
                                                {{recruiter.recruiterName}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Projects</td>
                                            <td>
                                            <multiselect  v-model="selected.project" name="project" :options="projectList"  label="projectName" track-by="id" select-label="" deselect-label="" class="" :max-height="600" placeholder="Select Project" :searchable="true" @select="getSprintList" ></multiselect> 
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Task Groups</td>
                                            <td>
                                            <multiselect  v-model="selected.taskGroup" name="taskGroup" :options="taskGroupList"  label="name" track-by="id" select-label="" deselect-label="" class="" :max-height="600" placeholder="Select TaskGroupName" :searchable="true" @select="getTaskGroup" ></multiselect> 
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Description
                                            </td>
                                            <div class="col-sm-12 col-12">
                                                <textarea rows="2"  class="form-control" v-model ="assignJob.description"></textarea>
                                            </div>    
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>    
                </div>
                </div>
            </b-modal> 
            -->
            <AssignjobModal :assignJob="assignJob" />
            <div class="modal" id="viewResume" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">View Resume</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="card m-b-3">
                                <div class="card-body p-2">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div v-if="docSrc !=''" class="row ten-columns">
                                                <iframe :src="docSrc" frameBorder="0" id="resumeFrame" style="background-color: #FFFFFF" width="1066px" height="500px" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-primary" data-dismiss="modal">OK</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #overlay>
                <div>
                    <img alt="images" class="loader-img-height"  src="/images/loading.gif"> 
                </div>                   
            </template> 
        </b-overlay>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import Multiselect from 'vue-multiselect';
import AssignjobModal from './blocks/AssignJob';
import { required } from "vuelidate/lib/validators";
export default {
    components: {
        Multiselect,
        AssignjobModal
    },
    computed: {
        visibleNotes() {
            return this.jobNotesList.slice(0, this.notesVisible)
        },
        recruiterId() {
            if (this.$store.getters.currentUser.profileId)
                return this.$store.getters.currentUser.profileId;
            else return 0;
        },
         ttClientAccountId() {
            if (this.$store.getters.currentUser.ttClientAccountId)
                return this.$store.getters.currentUser.ttClientAccountId;
            else return 0;
        }
    },
    watch: {
        handler: function(value) {
            this.docSrc;
        },
        'showOverlay': {
            handler: function(value) { 
                if(value == true){
                    $('#globalapploader').css('visibility','hidden');
                }
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            showOverlay:false,
            status:"",
            currentIndex: null,
            isExpand: false,
            isResumeSearchExpand: false,
            searchResume: null,
            excludeTerms: null,
            docSrc: null,
            joblist: [],
            resumeList: [],
            projectList: [],
            taskGroupList: [],
            jobNotesList: [],
            jobCreatedId: this.$store.getters.currentUser.profileId,
            notesVisible: 3,
            pagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1
            },
            saveCustomNotes: {
                description: "",
                title: "Custom Note",
                addedby: this.$store.getters.currentUser.profileId,
                referenceId:0,
                typeId:5
            },
            searchpagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1,
                searchlimit: this.$store.state.searchlimit
            },
            loginUserId: this.$store.getters.currentUser.profileId,
            technologies: this.$store.getters.getTechOptions,
            searchlimit: this.$store.state.searchlimit,
            industrylist: this.$store.getters.getIndustries,
            clientlist: this.$store.getters.getClients,
            companyClientList: this.$store.getters.getClientCompanyList,
            jobStatuslist: this.$store.getters.getJobStatus,
            recruiterlist: this.$store.getters.getRecruiterList,
            recCandInfo: {
                talentCount: 0,
                candShortlistedCount: 0,
                clientReviewedCount: 0,
                hiredCount: 0,
                talentIdentified: [],
                htmlMessage: "",
            },
            elements: {
                showadvancedsearch: false,
                loadingJob:false,
            },
            search: {
                params: {
                    jobTitle: "",
                    jobType: "",
                    technologyId: "",
                    startDate: "",
                    endDate: "",
                    recruiterId: this.recruiterId,
                    clientId: "",
                    ttClientAccountId: "",
                    industryId: "",
                    status: "",
                    recId: "",
                    priority: "",
                    pageNo: "",
                limit: ""
                },
            },
            coldisplay: {
                jobTitle: true,
                companyName: true,
                jobCode: true,
                client: true,
                industry: true,
                position: true
            },
            delJobParam: {
                index: -1,
                id: 0
            },
            delNoteParam: {
                index: -1,
                id: 0,
                jobId: 0
            },
            makJobParam: {
                id: 0,
                value:0,
            },
            assignJob: {
                jobId: "",
                recruiterId: [],
                description: "",
                jobTitle: "",
                recruiterEmail: [],
                recruiterName: []

            },
            saveTask: {
                taskGroupId: "",
                taskCode: "",
                taskType: 1,
                stageId: 1,
                priorityId: "",
                title: "",
                description: "",
                assignedTo: "",
                userType: "recruiter",
                startDate: "",
                dueDate: "",
                taskReference: "",
                taskCheckList: [],
                ttClientAccountId: "",
                jobId: "",
                jobTitle: ""
            },
            selected: {
                project: [],
                taskGroupId: null,
                stageId: null,
                mode: "",
                
            },
            candContactAccess: localStorage.getItem('candidateContactAccess'),
            //ttClientAccountId: localStorage.getItem('clientAccountId'),
        }
    },
    mounted() {
        this.$store.commit('refreshPagination');
        this.loadFilterData();
        this.loadDate();
        this.loadProjects();
    },
    // validations: {
    //     searchResume: {
    //         required
    //     }
    // },
    methods: {
        loadFilterData(){
            if(JSON.parse(localStorage.getItem('JobFilter')) == undefined || JSON.parse(localStorage.getItem('JobFilter')) == '')
            {
                this.loadJobs();
            }
            else{
                this.isExpand = true;
                this.search.params = JSON.parse(localStorage.getItem('JobFilter'));
                this.loadJobs();
            }
        },
        updateStatus(jobId,statusId){
            axios.put(this.$store.getters.getAPIBasePath + '/jobresource/update/job/status/'+jobId + '/' + statusId)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                         this.loadJobs();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        resetJobSearch() {
            localStorage.removeItem('JobFilter');
            this.search.params = {
                jobTitle: "",
                jobType: "",
                technologyId: "",
                startDate: "",
                endDate: "",
                recruiterId: this.recruiterId,
                clientId: "",
                ttClientAccountId: "",
                industryId: "",
                recId: "",
                status: "",
                priority: "",
                pageNo: "",
                limit: ""
            };
         
            this.loadJobs();
        },
        resetSearchResume() {
            this.searchResume = "";
            this.excludeTerms = "";
            this.searchResumes();
        },
        async loadJobs() {
            this.showOverlay=true;
            await this.getJobList();
            this.showOverlay=false;
        }, 
        getJobList() {
            this.showOverlay=true;
            this.search.params.pageNo = this.pagination.currentPage;
            this.search.params.limit = this.pagination.rowsPerPage;
            //return axios.post(this.$store.getters.getAPIBasePath + '/jobresource/joblist?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params)
            return axios.post(this.$store.getters.getAPIBasePath + '/jobresource/joblist', this.search.params)
            .then(response => {
                    let result = response.data;
                    this.joblist = result.data;
                    this.pagination.totalRecords = result.totalRecords;
                    this.pagination.currentPage = result.currentPage;
                    this.resumeList = [];
                    this.elements.loadingJob = true;
                })
                .catch(error => {
                    this.showOverlay=false;
                    console.log(error);
                });
        },
        searchResumes() {
           axios.post(this.$store.getters.getAPIBasePath + '/resumeresource/public/searchresume?page='+ this.searchpagination.currentPage + '&size=' + this.searchpagination.rowsPerPage+'&searchTerm=' + this.searchResume + '&excludeTerms=' + this.excludeTerms)
                .then(response => {
                    let result = response.data;
                    console.log(result)
                    this.resumeList = result.candidates;
                    console.log(this.resumeList);
                    if(result.pagination != null){
                        this.searchpagination.totalRecords = result.pagination[0].total;
                        this.searchpagination.currentPage = result.pagination[0].currentPage;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        changeLimit(newlimit) {
            this.pagination.currentPage = 1;
            this.pagination.rowsPerPage = newlimit;
            this.loadJobs();
        },
        deleteJob(jobId) {
            this.delJobParam.id = jobId;
        },
        deleteNote(noteId,jodId) {
            this.delNoteParam.id = noteId;
            this.delNoteParam.jobId = jodId;
        },
        setHotJobParams(jobId, value) {
            this.makJobParam.id = jobId;
            this.makJobParam.value= value;
        },
        saveHotJob(){
        let jobData = {"id": this.makJobParam.id,"priority":this.makJobParam.value}
        console.log("cc", this.makJobParam)
        axios.put(this.$store.getters.getAPIBasePath + '/jobresource/change/priority', jobData)
                .then(response => {
                  if(response.data.status == "OK"){
                      let messages = "Hot Job reset successfully";
                      if(this.makJobParam.value == 1){
                          messages = "Hot Job updated successfully"
                      }
                      console.log("messages",messages)
                      iziToast.success({
                        title: 'Success!',
                        message: messages ,
                        position: 'topRight'
                    });
                    this.loadJobs();
                   
                  }
                  else{
                      iziToast.error({
                        title: 'Failed!',
                        message: response.data.message,
                        position: 'topRight'
                    });
                    this.loadJobs();
                  }
                })
                .catch(error => {
                    console.log(error);
                });

    },
   
        delete_Job() {
            var params = new URLSearchParams();
            params.append('id', this.delJobParam.id)
            axios.post(this.$store.getters.getAPIBasePath + '/jobresource/delete/job', params)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.loadJobs();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
         delete_note() {
        
            axios.delete(this.$store.getters.getAPIBasePath + '/notesresource/delete/notes/'+this.delNoteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getnotes(this.delNoteParam.jobId);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        searchchangeLimit(newlimit) {
            this.searchpagination.currentPage = 1;
            this.searchpagination.rowsPerPage = newlimit;
            this.searchResumes();
        },
         deletePopupNote(noteId,jodId) {
            this.delNoteParam.id = noteId;
            this.delNoteParam.jobId = jodId;
        
            axios.delete(this.$store.getters.getAPIBasePath + '/notesresource/delete/notes/'+this.delNoteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getnotes(this.delNoteParam.jobId);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        viewRecDetails(jobId) {
            axios.post(this.$store.getters.getAPIBasePath + '/recruitmentplanresource/list/candidate/shortlist', { "jobId": jobId })
                .then(response => {
                    if (response.data.status) {
                        this.recCandInfo.talentIdentified = response.data.candShortlist;
                        this.recCandInfo.talentCount = this.recCandInfo.talentIdentified.length;
                        this.recCandInfo.candShortlistedCount = this.filterCandShortList().length;
                        this.recCandInfo.clientReviewedCount = this.filterCientReview().length;
                        this.recCandInfo.hiredCount = this.filterByStatus("7").length;
                        this.recCandInfo.htmlMessage = "<div class='table-responsive'><table class='table table-borderless'><tbody><tr><th>Talent Identified</th><td>" + this.recCandInfo.talentCount + "</td></tr><tr><th>Shortlisted Candidates</th><td>" + this.recCandInfo.candShortlistedCount + "</td></tr><tr><th>Client Reviewed</th><td>" + this.recCandInfo.clientReviewedCount + "</td></tr><tr><th>Hired</th><td>" + this.recCandInfo.hiredCount + "</td></tr></tbody></table></div>";
                    }
                }).catch(error => {
                    console.log(error);
                });
        },
        filterByStatus(statusId) {
            return this.recCandInfo.talentIdentified.filter(function(data, key) {
                if (data.status == statusId) {
                    return data;
                }
            });
        },
        filterCandShortList() {
            return this.recCandInfo.talentIdentified.filter(function(data, key) {
                if (data.status >= 3 && data.status <= 7) {
                    return data;
                }
            });
        },
        filterCientReview() {
            return this.recCandInfo.talentIdentified.filter(function(data, key) {
                if (data.status >= 4 && data.status <= 7) {
                    return data;
                }
            });
        },
        exportJobs() {
            this.addLogActivity('Export Jobs');
            this.updateLogActivity();
            this.search.params.pageNo = this.pagination.currentPage;
            this.search.params.limit = this.pagination.rowsPerPage;
            axios.post(this.$store.getters.getAPIBasePath + '/exportresource/export/jobs', this.search.params, { responseType: 'arraybuffer' })
                .then(response => {
                    let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, '');

                    let fileName = "Jobs" + "_" + currentDateWithFormat + ".xlsx";

                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;

                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);
                })
                .catch(error => {
                    console.log(error.response.data);
                    this.reportsearcherrors = error.response.data.errorlist;
                    iziToast.error({
                        title: 'Warning!',
                        message: error.response.data.message,
                        position: 'topRight'
                    });
                    this.reports = [];
                });

        },
        findJobs() {
            if(this.search.params != ''){
                localStorage.setItem('JobFilter', JSON.stringify(this.search.params));
            }
            this.pagination.currentPage = 1;
            this.loadJobs();
        },
        addLogActivity: function(action) {
            this.updateLogActivity();
            this.logActivityData = {
                entityName: 'Job',
                actionName: action,
            };
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/add/logactivity', this.logActivityData)
                .then(response => {})
                .catch(error => {
                    console.log(error);
                });
        },
        updateLogActivity: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/update/logactivity')
                .then(response => {})
                .catch(error => {
                    console.log(error);
                });
        },
        getRecruiterId: function(recruiterId, event) {
            if (event.target.checked) {
                this.assignJob.recruiterId.push(recruiterId);
            }
        },
        addRecruiter() {
            if (this.assignJob.recruiterId.length == 0) {
                iziToast.info({
                    title: 'Info',
                    message: this.$config.ChooseRec,
                    position: 'topRight'
                });
                return false;
            }
            //this.getTaskCode();
            //console.log(this.saveTask);
            let data = [];
            for (var j = 0; j < this.assignJob.recruiterId.length; j++) {
                data.push({
                    jobId: this.assignJob.jobId,
                    title: this.assignJob.jobTitle,
                    assignedTo: this.assignJob.recruiterId[j],
                    taskGroupId: this.saveTask.taskGroupId,
                    jobCode: this.saveTask.taskCode,
                    taskType: this.saveTask.taskType,
                    stageId: this.saveTask.stageId,
                    priorityId: this.saveTask.priorityId,
                    description: this.assignJob.description,
                    userType: "recruiter",
                    startDate: this.saveTask.startDate,
                    dueDate: this.saveTask.dueDate,
                    taskReference: "",
                    taskCheckList: [],
                    //recruiterId: this.assignJob.recruiterId[j],
                });
                //}
                console.log(data);

                //this.getTaskCode();
                this.saveTask.description = this.assignJob.description + "<p><a href='/job/plan-recruitment/" + this.assignJob.jobId + "'</a>" + this.assignJob.jobTitle + "</p>";
                this.saveTask.assignedTo = this.assignJob.recruiterId[j];
                this.saveTask.title = this.assignJob.jobTitle;
                this.saveTask.taskCode = "";
                this.saveTask.jobId = this.assignJob.jobId;
                this.saveTask.jobTitle = this.assignJob.jobTitle;


                axios.post(this.$store.getters.getAPIBasePath + '/taskresource/add/task', this.saveTask)
                    // axios.post(this.$store.getters.getAPIBasePath + '/taskresource/add/task', data)
                    .then(response => {
                        if (response.data.status == "ACCEPTED") {
                            iziToast.success({ title: 'Success', message: response.data.message, position: 'topRight' });
                            //this.$refs.createTaskModal.hide();
                            //this.$emit('loadTasks', this.saveTask.taskGroupId);
                            // this.refreshTaskData();
                        } else {
                            iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                        }
                    })
                    .catch(function(error) {
                        console.log(error);
                        iziToast.error({ title: 'Error', message: this.$config.SomeTryAg, position: 'topRight' });
                    });

            }
        },
        assignJobToRecruiters(jobId, jobTitle) {
            this.assignJob.jobId = jobId;
            this.assignJob.jobTitle = jobTitle;
            this.$root.$emit('bv::show::modal', 'AssignjobModal')
        },
        getTaskCode() {
            //this.loadDate();
            axios.post(this.$store.getters.getAPIBasePath + '/commonresource/autoincrementcode/task')
                .then(response => {
                    if (response.data.status) {
                        this.saveTask.taskCode = response.data.code;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadDate() {
            this.saveTask.startDate = new Date().toISOString().substr(0, 10);
            this.saveTask.dueDate = new Date().toISOString().substr(0, 10);
        },
        loadProjects() {
            axios.get(this.$store.getters.getAPIBasePath + '/projectresource/list/project')
                .then(response => {
                    if (response.data.status) {
                        this.projectList = response.data.projectList;
                    }
                })
                .catch(function(error) {
                    console.log(error);
                });
        },
        showTaskGroup() {
            if (this.selected.project.length == 0) {
                iziToast.info({ title: 'Info', message: this.$config.PlsSelProj, position: 'topRight' });
            } else {
                //this.addLogActivity('Add Task Group');
                // this.$root.$emit('bv::show::modal', 'createSprintModal')
            }
        },
        getSprintList: function(project_info) {
            if (project_info.id != null) {
                axios.get(this.$store.getters.getAPIBasePath + '/projectresource/list/sprint/' + project_info.id)
                    .then(response => {
                        if (response.data.status) {
                            this.taskGroupList = response.data.taskGroupList;
                        }
                    })
                    .catch(function(error) {
                        console.log(error);
                    });
            }
        },
        getTaskGroup: function(taskGroup_info) {
            if (taskGroup_info.id != null) {
                this.saveTask.taskGroupId = taskGroup_info.id;
            }
        },
        resumeDownload: function(candidateId) {
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/add/candidate/download', { candidateId: candidateId })
                .then(response => {
                    let result = response.data;
                })
                .catch(error => {
                    console.log(error);
                    this.unAuthorized(error);
                });
        },
        resumeView: function(candidateId, fileurl) {
            //var frameElement = document.getElementById("resumeFrame");
            //document.getElementById('resumeFrame').contentWindow.location.reload();
            this.docSrc = "https://docs.google.com/gview?url=" + fileurl + "&embedded=true";
            //frameElement.contentWindow.location.href = this.docSrc;
            console.log(this.docSrc);
        },
        getnotes(id){
           if (this.currentIndex == id) {
                this.currentIndex = null;
            } else {
                this.currentIndex = id;
                this.selectedInterviewId = id;
            }

            axios.get(this.$store.getters.getAPIBasePath + '/notesresource/get/notes/' + id)
                .then(response => {
                    let result = response.data.data;
                    this.jobNotesList = result;
                    
                    console.log(this.jobNotesList)
                })
                .catch(error => {
                    console.log(error);
                });
        },
         addCustomNotes(){
            axios.post(this.$store.getters.getAPIBasePath + '/notesresource/add/notes' , this.saveCustomNotes)
                .then(response => {
                  let result = response.data.message;
                  if(result == "Notes Data added.") {
                       iziToast.success({
                            title: 'Success',
                            message: result,
                            position: 'topRight'
                        });
                    
                        this.$refs["my-modal"].hide();
                        this.getnotes(this.saveCustomNotes.referenceId)
                        this.saveCustomNotes.description="";
                  }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        AddRecruitmentNotes(id){
            this.$refs["my-modal"].show();
            this.saveCustomNotes.referenceId = id;
        },
         hideModal() {
            this.$refs["my-modal"].hide();
        },
    }
}

</script>

<style scoped>
.all-table tbody td {
    background-color: transparent;

}
</style>
