<template>
    <div :class="{ 'viewonly' : personalinfo.viewonly == 1}">
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-header p-2">
                        <div class="row">
                            <div class="col-md-6">
                                Primary Information
                            </div>
                            <div class="col-md-6 text-right">
                                {{personalinfo.candidateCode}}
                            </div>
                        </div>
                    </div>
                    <div class="card-body p-2">
                        <div class="basic-block">
                            <div class="row ten-columns">
                                <div class="col-md-4 col-sm-6">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label class="font-weight-bold">First Name </label>
                                        </div>
                                        <div class="col-md-6">
                                            <label>{{personalinfo.firstName}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="row">
                                        <div class="col-md-6 pl-0-pr">
                                            <label class="font-weight-bold">Last Name </label>
                                        </div>
                                        <div class="col-md-6">
                                            <label>{{personalinfo.lastName}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="(ttClientAccountId == personalinfo.ttClientAccountId || personalinfo.isViewed==1)" class="col-md-4 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12 col-12 lb">
                                            <label class="font-weight-bold">Phone</label>
                                        </div>
                                        <div class="col-md-6 col-sm-12 col-12 input-group">
                                            <label>{{personalinfo.mobile}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row ten-columns">
                                <div class="col-md-4 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12 col-12 lb">
                                            <label class="font-weight-bold">Industry</label>
                                        </div>
                                        <div class="col-md-6 col-sm-12 col-12 input-group">
                                            <label>{{personalinfo.industryName}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12 col-12 lb">
                                            <label class="font-weight-bold">Position</label>
                                        </div>
                                        <div class="col-md-6 col-sm-12 col-12">
                                            <div class="input-group">
                                                <label>{{personalinfo.position}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12 col-12 lb">
                                            <label class="font-weight-bold">Years of Experience</label>
                                        </div>
                                        <div class="col-md-6 col-sm-12 col-12">
                                            <div class="input-group">
                                                <span v-if="checkEmpty(personalinfo.experience)">{{personalinfo.experience}} years </span>
                                                <span v-if="checkEmpty(personalinfo.expMonth)" class="pl-2"> {{personalinfo.expMonth}} months</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row ten-columns">
                                <div v-if="false" class="col-md-4 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12 col-12 lb">
                                            <label class="font-weight-bold">Referred By</label>
                                        </div>
                                        <div class="col-md-6 col-sm-12 col-12">
                                            <div class="input-group">
                                                <label>{{personalinfo.referredBy}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12 col-12 lb">
                                            <label class="font-weight-bold">Location</label>
                                        </div>
                                        <div class="col-md-6 col-sm-12 col-12">
                                            <div class="input-group">
                                                <label>{{personalinfo.location}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="(ttClientAccountId == personalinfo.ttClientAccountId || personalinfo.isViewed==1)" class="col-md-4 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12 col-12 lb">
                                            <label class="font-weight-bold">Candidate Source</label>
                                        </div>
                                        <div class="col-md-6 col-sm-12 col-12">
                                            <div class="input-group">
                                                <label>{{personalinfo.sourcingChannelName}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="(ttClientAccountId == personalinfo.ttClientAccountId || personalinfo.isViewed==1)" class="col-md-4 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12 col-12 lb">
                                            <label class="font-weight-bold">Source Reference Link</label>
                                        </div>
                                        <div class="col-md-6 col-sm-12 col-12">
                                            <a :href="generateLink(personalinfo.sourcingRefLink)" target="_blank">{{personalinfo.sourcingRefLink}}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="(ttClientAccountId == personalinfo.ttClientAccountId || personalinfo.isViewed==1)" class="row ten-columns">
                                <div class="col-md-4 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12 col-12 lb">
                                            <label class="font-weight-bold">LinkedIn URL</label>
                                        </div>
                                        <div class="col-md-6 col-sm-12 col-12">
                                            <a :href="generateLink(personalinfo.linkedInUrl)" target="_blank" >{{personalinfo.linkedInUrl}}</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12 col-12 lb">
                                            <label class="font-weight-bold">Willing to relocate</label>
                                        </div>
                                        <div class="col-md-6 col-sm-12 col-12">
                                            <label>{{personalinfo.willRelocate}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="workAuthorization == true" class="col-md-4 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12 col-12 lb">
                                            <label class="font-weight-bold">Work Authorization</label>
                                        </div>
                                        <div class="col-md-6 col-sm-12 col-12">
                                            <label>{{personalinfo.visaTypeText}}</label>
                                        </div>
                                    </div>
                                </div>                              
                                <div  :class="workAuthorization == true ? 'col-md-4 col-sm-6 col-12 mb-10-mr ten-columns' : 'col-md-4 col-sm-6 col-12 mb-10-mr'" class="col-md-4 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12 col-12 lb">
                                            <label class="font-weight-bold">Candidate Type</label>
                                        </div>
                                        <div class="col-md-6 col-sm-12 col-12">
                                            <label>{{personalinfo.candidateType}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12 col-12 lb">
                                            <label class="font-weight-bold">Recruiter</label>
                                        </div>
                                        <div class="col-md-6 col-sm-12 col-12">
                                            <label>{{personalinfo.recruiterName}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6 col-12 mb-10-mr">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12 col-12 lb">
                                            <label class="font-weight-bold">Created By</label>
                                        </div>
                                        <div class="col-md-6 col-sm-12 col-12">
                                            <label>{{personalinfo.createdByName}}</label>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header p-2">Educational Information</div>
                    <div class="card-body p-2">
                        <div class="basic-block">
                            <div class="row ten-columns">
                                <div class="col-sm-12">
                                    <div class="table-responsive">
                                        <table class="table table-borderless all-table">
                                            <thead>
                                                <tr>
                                                    <th class="" scope="col">Degree</th>
                                                    <th class="" scope="col">Specialization</th>
                                                    <th class="" scope="col">Year</th>
                                                    <th class="" scope="col">Name Of Institution</th>
                                                    <th class="" scope="col">City</th>
                                                    <th class="" scope="col">State</th>
                                                    <th class="" scope="col">Country</th>
                                                    <th class="" scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody class="cloned_tr">
                                                <tr v-for="(info, index) in personalinfo.educationinfo">
                                                    <td>
                                                        <label>{{personalinfo.educationinfo[index].degree}}</label>
                                                    </td>
                                                    <td>
                                                        <label>{{personalinfo.educationinfo[index].specialization}}</label>
                                                    </td>
                                                    <td>
                                                        <label>{{personalinfo.educationinfo[index].year}}</label>
                                                    </td>
                                                    <td>
                                                        <label>{{personalinfo.educationinfo[index].schoolName}}</label>
                                                    </td>
                                                    <td>
                                                        <label>{{personalinfo.educationinfo[index].city}}</label>
                                                    </td>
                                                    <td>
                                                        <label>{{personalinfo.educationinfo[index].stateName}}</label>
                                                    </td>
                                                    <td>
                                                       <!--  <label v-if="personalinfo.educationinfo[index].country != null">{{personalinfo.educationinfo[index].country.name}}</label> -->
                                                    </td>
                                                    <td>
                                                    </td>
                                                </tr>
                                                <tr :class="{ 'norecordfound' : personalinfo.educationinfo.length}">
                                                    <td colspan="8" align="center">No item found</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-header p-2">Additional Information</div>
                    <div class="card-body p-2">
                        <div class="basic-block">
                            <div class="row ten-columns">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-xs-12 required-field-block">
                                            <table class="table table-borderless all-table">
                                                <thead>
                                                    <tr>
                                                        <th class="" scope="col">Additional Field</th>
                                                        <th class="" scope="col">Value</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(doc, index) in personalinfo.candidateAdditionalFields" v-bind:key="index">
                                                        <td>{{doc.settingsNameValue.name}}</td>
                                                        <td>
                                                            <span v-if="doc.valueFormatId==dataType.xNumber">{{doc.intValue}}</span>
                                                            <span v-if="doc.valueFormatId==dataType.xText">{{doc.stringValue}}</span>
                                                            <span v-if="doc.valueFormatId==dataType.xDate">{{doc.dateValue | formatDate}}</span>
                                                            <span v-if="doc.valueFormatId==dataType.xYesNo">
                                                                <span v-if="doc.booleanValue">Yes</span>
                                                                <span v-if="!doc.booleanValue">No</span>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr v-if="personalinfo.candidateAdditionalFields.length==0">
                                                        <td colspan="2" align="center">No item found</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <div class="card-header p-2">Language(s)</div>
                    <div class="card-body p-2">
                        <div class="basic-block">
                            <div class="row ten-columns">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-xs-12 required-field-block">
                                            <span v-for="(info, index) in personalinfo.candidateLanguage" v-bind:key="index"><span v-if="index>0">, </span>{{info.languages.text}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-header p-2">Objective/Summary</div>
                    <div class="card-body p-2">
                        <div class="basic-block">
                            <div class="row ten-columns">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-xs-12 required-field-block">
                                            <div class="form-control objective">{{personalinfo.notes}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        personalinfo: Object,
        candContactAccess: [String, Number],
        ttClientAccountId: [String, Number]
    },
    mounted() {
        this.workAuth();
    },
    data() {
        return {
            dataType: this.$store.getters.getAdditionalFieldsType,
            workAuthorization: Boolean,
        }
    },
    methods: {
        workAuth(){
            if(localStorage.getItem('workAuthorization') === "1"){
                this.workAuthorization  = true
            }else {
                this.workAuthorization  = false
            }
        },
        checkEmpty(obj) {
            if (obj != null && isNaN(obj) && obj.trim().length > 0) {
                return true;
            } else if (obj != null && obj > 0) {
                return true;
            }
            return false;
        },
        generateLink(data){
            let link ='';
            if(data!= null && data.length>0){
                link = data.startsWith('https://') ? data: 'https://'+data;
            }
            return link;
        }
    }
}

</script>
