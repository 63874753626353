<template>
    <div id="main">
        <div class="container-fluid" id="quick-search">
            <div class="col-md-12 col-sm-6">
                <div class="col-sm-12 h-100 d-table">
                    <div class="d-table-cell align-middle login_div">
                        <div class="col-md-4 offset-md-4 col-sm-12">
                            <div v-if="elements.showForm" class="referral-register-block box-wrapper p-5 mb-4 bg-white rounded pos-rel mt-80">
                                <h3 class="text-color text-left">Referral Registration</h3>
                                <hr class="hrline-border">
                                <form>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="input-group required-field-block">
                                                            <input type="text" name="firstName" placeholder="First Name" maxlength="45" size="30" v-model="referralinfo.firstName" class="form-control box-none">
                                                            <span class="required-icon"></span>
                                                        </div>
                                                        <label v-if="$v.referralinfo.firstName.$error && !$v.referralinfo.firstName.required" class="text-danger">Please enter First Name</label>
                                                        <label v-if="$v.referralinfo.firstName.$error && !$v.referralinfo.firstName.minLength" class="text-danger">First Name must have at least {{$v.referralinfo.firstName.$params.minLength.min}} letters.</label>
                                                        <label v-if="$v.referralinfo.firstName.$error && !$v.referralinfo.firstName.maxLength" class="text-danger">First Name must not exceed {{$v.referralinfo.firstName.$params.maxLength.max}} letters.</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="input-group">
                                                            <input type="text" name="lastName" placeholder="Last Name" maxlength="45" size="30" v-model="referralinfo.lastName" class="form-control box-none">
                                                        </div>                                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <input type="text" class="form-control box-none" id="nickName" placeholder="Nick Name" v-model="referralinfo.nickName">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                            <PhoneNumber
                                                            class="phoneborder"
                                                            ref="phoneNumber"
                                                            :value="referralinfo.referralContact.mobileNo"
                                                            :mandatory="phoneMandatory"
                                                            @phone-input="phoneControlInput"
                                                            />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="input-group required-field-block">
                                                            <input type="email" name="primaryEmail" placeholder="Email" maxlength="80" v-model.lazy="referralinfo.email" class="Submitaddress form-control box-none" :class="{ 'is-invalid': $v.referralinfo.email.$error }" @blur="$v.referralinfo.email.$touch">
                                                            <span class="required-icon"></span>
                                                        </div>
                                                        <label v-if="$v.referralinfo.email.$error && !$v.referralinfo.email.required" class="text-danger pl-4">Please enter Email</label>
                                                        <label v-if="$v.referralinfo.email.$error && !$v.referralinfo.email.email" class="text-danger pl-4">Please enter a valid email</label>
                                                        <label v-if="$v.referralinfo.email.$error && !$v.referralinfo.email.emailexists && $v.referralinfo.email.email && $v.referralinfo.email.required" class="text-danger pl-4">Email already exists</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="input-group required-field-block">
                                                            <input type="text" class="form-control box-none" id="position" placeholder="Position" v-model="referralinfo.position">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <div class="row" style="text-align:left;">
                                                    <div class="col-md-12">
                                                        <select v-model="referralinfo.industryId" class="form-control box-none" id="industryId">
                                                            <option value="">Industry</option>
                                                            <option v-for="(data, index) in list.industrylist" :value="data.id">{{data.industryName}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">                                                
                                                <select v-if="referralinfo.referralContact.countryId=='US'" v-model="referralinfo.referralContact.stateName" class="form-control box-none" required>
                                                    <option value="">State</option>
                                                    <option v-for="(data, index) in list.stateList" :value="data.stateName">{{data.stateName}}</option>
                                                </select>
                                                <input v-if="referralinfo.referralContact.countryId!='US'" placeholder="State" v-model="referralinfo.referralContact.stateName" type="text" maxlength="25" class="form-control box-none">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <div class="row" style="text-align:left;">
                                                    <div class="col-md-12">
                                                        <select v-model="referralinfo.referralContact.countryId" class="form-control box-none" id="exampleFormControlSelect1">
                                                            <option value="">Country</option>
                                                            <option v-for="(data, index) in list.countrylist" :value="data.country_Id">{{data.name}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <vue-recaptcha ref="recaptcha" sitekey="6Lfjqf8dAAAAAHzJiArCATMNfpq-4csnpbjahfk5" secretkey="6Lfjqf8dAAAAAPx8rjDSlh-gxY_ad9Oe1ZeHJEjr" :loadRecaptchaScript="true" badge="inline" @verify="markRecaptchaAsVerified"></vue-recaptcha>
                                        <div v-if="!this.form.robot" class="errorcolor">{{form.errorMessage}}</div>
                                    </div>
                                    <div class="bg-slategray lt wrap-reset text-left mt-1" style="background-color:#fff !important;">
                                        <button type="button" @click="saveReferral" class="btn btn-primary" style="float: right;">Register</button>
                                    </div>
                                </form>
                            </div>
                            <div v-if="!elements.showForm" class="box-wrapper shadow-sm p-5 mb-4 bg-white rounded pos-rel">
                                <div class="card-body p-2">
                                    <div class="row">
                                        <div class="col-sm-12 clientinfo">
                                            <h3 class="text-color" style="text-align: center;">Welcome to Talent Turbo !</h3>
                                            <p style="text-align: center;">To activate your account, click on the activation link sent to the registered email address</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { required, email, minLength, maxLength, sameAs, helpers, requiredIf } from "vuelidate/lib/validators";
import iziToast from 'izitoast';
import VueRecaptcha from 'vue-recaptcha';
const usPhone = helpers.regex('usPhone', /^\(\d{3}\)\s\d{3}-\d{4}$/);
export default {
    components: {
        VueRecaptcha
    },
    computed: {
        referralId() {
            if (this.$route.params.referralid)
                return this.$route.params.referralid;
            else return 0;
        }
    },
    data() {
        return {
            elements: {
                showForm: true
            },
            list: {
                industrylist: [],
                countrylist: [],
                stateList: []
            },
            phoneMandatory:true,
            referralinfo: {
                registrationType: "onboarding",
                key: this.$route.params.key,
                firstName: "",
                lastName: "",
                nickName: "",
                email: "",
                position: "",
                industryId: "",
                referralContact: {
                    // countryCode:"+1",
                    // mobile: "",
                    mobileNo: "",
                    stateName: "",
                    countryId: "US",
                    skypeId: "",
                    linkedInId: ""
                },
            },
            form: {
                robot: false,
                errorMessage: ""
            }
        }
    },
    mounted() {
        this.loadIndustryList();
        this.loadCountryList();
        this.loadStateList();
    },
    validations: {
        referralinfo: {
            firstName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30)
            },           
            email: {
                required,
                email,
                emailexists(email) {
                    if (email === '') return true;
                    let ajaxdata = { email: email };
                    return axios.post(this.$store.getters.getAPIBasePath + '/referralresource/checkemailavailability', ajaxdata)
                        .then(response => {
                            if (response.data.status) {
                                return true;
                            }
                            return false;
                        })
                        .catch(error => {
                            return false;
                        });
                }
            },
            // referralContact:{
            //     mobile: {
            //         required
            //     },
            //     countryCode:{
            //     required: requiredIf(function() {
            //     return this.referralinfo.referralContact.mobile != null && this.referralinfo.referralContact.mobile.length > 0;
            //         })
            //     }
            // }

        }
    },
    methods: {
        markRecaptchaAsVerified(response) {
            if (response)
                this.form.robot = true;
        },
         phoneControlInput: function (value) {
            console.log(value);
            this.referralinfo.referralContact.mobileNo = value;
        },
        loadCountryList() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/countrylist')
                .then(response => {
                    this.list.countrylist = response.data.countrylist;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadIndustryList() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/industrylist')
                .then(response => {
                    this.list.industrylist = response.data.industries;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadStateList() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/statelist?countryId=us')
                .then(response => {
                    this.list.stateList = response.data.statelist;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        saveReferral() {
            this.$v.referralinfo.$touch();
            if (this.form.robot) {
                if (this.$v.referralinfo.$invalid) {
                    // iziToast.error({title: 'Error', message: "Some of the filed[s] does not satisfy basic requirements", position: 'topRight'});
                } else {
                    // let mobile = '';
                    // if (this.referralinfo.referralContact.mobile != '' && this.referralinfo.referralContact.mobile != null) {
                    //     mobile = this.referralinfo.referralContact.countryCode + " " + this.referralinfo.referralContact.mobile;
                    // }
                    // this.referralinfo.referralContact.mobileNo = mobile;
                    axios.post(this.$store.getters.getAPIBasePath + '/referralresource/update/public/referral/' + this.referralId, this.referralinfo)
                        .then(response => {
                            let result = response.data;
                            this.elements.showForm = false;
                            if (!response.status) {
                                iziToast.error({ title: 'Error', message: result.message, position: 'topRight' });

                            } else {
                                iziToast.success({ title: 'Success', message: this.$config.RefRegSuc, position: 'topRight' });
                            }
                        })
                        .catch(error => {
                            iziToast.error({ title: 'Error', message: this.$config.RefRegErr, position: 'topRight' });
                        });
                }
            } else {
                this.form.errorMessage = "Please check the ReCAPTCHA";
            }
        }
    }
}

</script>
