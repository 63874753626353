<template>
    <div id="main">
        <div class="container-fluid" id="quick-search">
            <div class="driver-model">
                <div class="row h-50">
                    <div class="col-sm-12 h-100 d-table">
                        <div class="d-table-cell align-middle login_div">
                            <div class="col-md-4 offset-md-4">
                                <div class="interviewer-register-block">
                                    <div v-if="showForm" class="box-wrapper p-5 mb-4 bg-white rounded pos-rel mt-80">
                                        <h3 class="text-color text-left" style="text-transfomargin-top:10px">Interviewer Registration</h3>
                                        <hr class="hrline-border">
                                        <form>
                                            <div class="row pt-3">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="input-group required-field-block">
                                                                    <input type="text" name="firstName" placeholder="First Name" maxlength="45" size="30" v-model="interviewerinfo.firstName" class="form-control box-none" :class="{ 'is-invalid': $v.interviewerinfo.firstName.$error }">
                                                                    <span class="required-icon"></span>
                                                                </div>
                                                                <label v-if="$v.interviewerinfo.firstName.$error && !$v.interviewerinfo.firstName.minLength" class="text-danger">First Name must have at least {{$v.interviewerinfo.firstName.$params.minLength.min}} letters.</label>
                                                                <label v-if="$v.interviewerinfo.firstName.$error && !$v.interviewerinfo.firstName.required" class="text-danger">Please enter First Name</label>
                                                                <label v-if="$v.interviewerinfo.firstName.$error && !$v.interviewerinfo.firstName.maxLength" class="text-danger">First Name must not exceed {{$v.interviewerinfo.firstName.$params.maxLength.max}} letters.</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="input-group">
                                                                    <input type="text" name="lastName" placeholder="Last Name" maxlength="45" size="30" v-model="interviewerinfo.lastName" class="form-control box-none">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <input type="text" class="form-control box-none" id="" placeholder="Nick Name" v-model="interviewerinfo.nickName">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div>
                                                                    <PhoneNumber class="phoneborder" ref="phoneNumber" :value="interviewerinfo.interviewersContact.mobileNo" :mandatory="phoneMandatory" @phone-input="phoneControlInput"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <div class="row" style="text-align:left;">
                                                            <div class="col-md-12">
                                                                <div class="input-group required-field-block">
                                                                    <input type="text" name="primaryEmail" placeholder="Email" maxlength="80" v-model.lazy="interviewerinfo.email" class="Submitaddress form-control box-none" :class="{ 'is-invalid': $v.interviewerinfo.email.$error }" @blur="$v.interviewerinfo.email.$touch">
                                                                    <span class="required-icon"></span>
                                                                </div>
                                                                <label v-if="$v.interviewerinfo.email.$error && !$v.interviewerinfo.email.required" class="text-danger">Please enter Email</label>
                                                                <label v-if="$v.interviewerinfo.email.$error && !$v.interviewerinfo.email.email" class="text-danger">Please enter a valid email</label>
                                                                <label v-if="$v.interviewerinfo.email.$error && !$v.interviewerinfo.email.emailexists && $v.interviewerinfo.email.email && $v.interviewerinfo.email.required" class="text-danger">Email already exists</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" v-if="false">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="input-group radio_btns">
                                                                    <p>
                                                                        <input type="radio" v-model="interviewerinfo.gender" value="M" id="male" />
                                                                        <label for="male">Male</label>
                                                                    </p>
                                                                    <p>
                                                                        <input type="radio" v-model="interviewerinfo.gender" value="F" id="female" />
                                                                        <label for="female">Female</label>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group mr-2">
                                                        <div class="row" style="text-align:left;">
                                                            <div class="col-md-12">
                                                                <datepicker v-model="interviewerinfo.dateOfBirth" placeholder="Date of Birth" input-class="form-control enablefield box-none" wrapper-class="input-group date" format="MM/dd/yyyy" :disabledDates="dobDates"></datepicker>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="false" class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <multiselect v-model="interviewerinfo.interviewersDomain" :options="domainList" name="domainName" label="domainName" track-by="domainId" :multiple="true" select-label="" deselect-label="" class="" placeholder="Select Domain"></multiselect>
                                                                </div>
                                                            </div>
                                                            <div v-if="false" class="col-md-6">
                                                                <div class="form-group">
                                                                    <select v-model="interviewerinfo.technologyId" class="form-control box-none" id="exampleFormControlSelect1">
                                                                        <option value="">Technology</option>
                                                                        <option v-for="(data, index) in technologylist" :value="data.id">{{data.technology}}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="false" class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <input type="text" class="form-control box-none" id="" placeholder="Position" v-model="interviewerinfo.position">
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div class="row" style="text-align:left;">
                                                            <div class="col-md-12">
                                                                <select v-model="interviewerinfo.industryId" class="form-control box-none" id="exampleFormControlSelect1">
                                                                    <option value="">Industry</option>
                                                                    <option v-for="(data, index) in industrylist" :value="data.id">{{data.industryName}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="false" class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <select v-if="interviewerinfo.interviewersContact.countryId=='US'" v-model="interviewerinfo.interviewersContact.stateName" class="form-control box-none" required>
                                                            <option value="">State</option>
                                                            <option v-for="(data, index) in statelist" :value="data.stateName">{{data.stateName}}</option>
                                                        </select>
                                                        <input v-if="interviewerinfo.interviewersContact.countryId!='US'" v-model="interviewerinfo.interviewersContact.stateName" type="text" maxlength="25" class="form-control box-none" placeholder="State">
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div class="row" style="text-align:left;">
                                                            <div class="col-md-12">
                                                                <select v-model="interviewerinfo.interviewersContact.countryId" class="form-control box-none" id="exampleFormControlSelect1">
                                                                    <option value="">Country</option>
                                                                    <option v-for="(data, index) in countrylist" :value="data.country_Id">{{data.name}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="false" class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <input type="text" class="form-control box-none" id="" placeholder="Skype Id" v-model="interviewerinfo.interviewersContact.skypeId">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <input type="text" class="form-control box-none" id="" placeholder="Linkedin" v-model="interviewerinfo.interviewersContact.linkedinUrl">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <vue-recaptcha ref="recaptcha" sitekey="6Lfjqf8dAAAAAHzJiArCATMNfpq-4csnpbjahfk5" secretkey="6Lfjqf8dAAAAAPx8rjDSlh-gxY_ad9Oe1ZeHJEjr" :loadRecaptchaScript="true" badge="inline" @verify="markRecaptchaAsVerified">
                                                </vue-recaptcha>
                                                <div v-if="!this.form.robot" class="errorcolor">{{form.errorMessage}}</div>
                                            </div>
                                            <div class="bg-slategray lt wrap-reset text-left mt-1" style="background-color:#fff !important;">
                                                <button type="button" @click="saveInterviewer" class="btn btn-primary" style="float: right;">Register</button>
                                            </div>
                                        </form>
                                    </div>
                                    <div v-if="!showForm" class="box-wrapper shadow-sm p-5 mb-4 bg-white rounded pos-rel">
                                        <div class="card-body p-2">
                                            <div class="row">
                                                <div class="col-sm-12 clientinfo">
                                                    <h3 class="text-color" style="text-align: center;">Welcome to Talent Turbo !</h3>
                                                    <p style="text-align: center;">To activate your account, click on the activation link sent to the registered email address</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { required, email, minLength, maxLength, sameAs, helpers } from "vuelidate/lib/validators";
import iziToast from 'izitoast';
import VueRecaptcha from 'vue-recaptcha';
import Multiselect from 'vue-multiselect';
const usPhone = helpers.regex('usPhone', /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/)

export default {
    components: {
        VueRecaptcha,
        Multiselect
    },
    computed: {
        interviewerId() {
            if (this.$route.params.interviewerid)
                return this.$route.params.interviewerid;
            else return 0;
        },
        dobDates() {
            let date = new Date();
            date.setFullYear(date.getFullYear() - 15);
            return {
                from: date
            };
        }
    },
    data() {
        return {
            showForm: true,
            TechSkills: [],
            technologylist: [],
            industrylist: [],
            countrylist: [],
            statelist: [],
            domainList: [],
            phoneMandatory:true,
            interviewerinfo: {
                registrationType: "onboarding",
                key: this.$route.params.key,
                firstName: "",
                middleName: "",
                nickName: "",
                lastName: "",
                email: "",
                phoneNo: "",
                dateOfBirth: "",
                gender: "",
                position: "",
                industryId: "",
                blockpersonaltab: 0,
                viewonly: 0,
                technology: "",
                experience: "",
                technologyId: "",
                interviewersDomain: [],
                interviewersContact: {
                    stateName: "",
                    countryId: "US",
                    skypeId: "",
                    linkedInId: "",
                    mobileNo: "",
                },
                interviewersSkill: [{
                    technologyId: ""
                }]
            },
            loginForm: {
                recaptchaVerified: false,
                pleaseTickRecaptchaMessage: ''
            },
            form: {
                robot: false,
                errorMessage: ""
            }
        }
    },
    mounted() {
        this.loadTechnologyList();
        this.loadIndustryList();
        this.loadCountryList();
        this.loadStateList();
        this.loadDomainList();
    },
    validations: {
        interviewerinfo: {
            firstName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30)
            },            
            email: {
                required,
                email,
                emailexists(email) {
                    if (email === '') return true;
                    let ajaxdata = { email: email };
                    return axios.post(this.$store.getters.getAPIBasePath + '/interviewerresource/checkemailavailability', ajaxdata)
                        .then(response => {
                            if (response.data.status) {
                                return true;
                            }
                            return false;
                        })
                        .catch(error => {
                            return false;
                        });
                }
            },
            // interviewersContact:{
            //     mobile: {
            //         required
            //     }
            // }
        }
    },
    methods: {
        onPaste (e) {
            if(e.clipboardData.getData('text').match(/[^\d ()+-]/))
                e.preventDefault();
        },
        markRecaptchaAsVerified(response) {
            if (response)
                this.form.robot = true;
        },
        validatePhone: function(evt, idx) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            console.log(charCode);
            if ((charCode > 33 && (charCode < 48 || charCode > 57)) && charCode !== 45 && charCode !== 46 && charCode !== 40 && charCode !== 41 && charCode !== 43) {
                evt.preventDefault();
            } //except numbers,open/close brackets,minus and space +        
        },
         phoneControlInput: function (value) {
            console.log(value);
            this.interviewerinfo.interviewersContact.mobileNo = value;
        },
        formatUsPhone: function(evt, idx) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                var curchr = $(idx).val().length;
                var curval = $(idx).val();
                if (curchr == 3 && curval.indexOf("(") <= -1) {
                    $(idx).val("(" + curval + ")" + " ");
                } else if (curchr == 4 && curval.indexOf("(") > -1) {
                    $(idx).val(curval + ")-");
                } else if (curchr == 5 && curval.indexOf(")") > -1) {
                    $(idx).val(curval + "-");
                } else if (curchr == 9) {
                    $(idx).val(curval + "-");
                    $(idx).attr('maxlength', '14');
                }
                return true;
            }
        },
        loadCountryList() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/countrylist')
                .then(response => {
                    this.countrylist = response.data.countrylist;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadStateList() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/statelist?countryId=us')
                .then(response => {
                    this.statelist = response.data.statelist;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadTechnologyList() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/listtechnology')
                .then(response => {
                    this.technologylist = response.data.technologylist;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadIndustryList() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/industrylist')
                .then(response => {
                    this.industrylist = response.data.industries;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadDomainList() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/domainlist')
                .then(response => {
                    this.domainList = response.data.domainList;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        updateSkills: function(Skills) {
            let skill = Object.assign({}, this.new_TechSkill);
            this.TechSkills = [];
            if (Skills.length == 0) {
                this.TechSkills.push(skill);
            } else {
                for (var i = 0; i < Skills.length; i++) {
                    let Value = Skills[i];
                    let skill = Object.assign({}, this.new_TechSkill);
                    skill.id = Value.id;
                    skill.skillType = Value.skillType;
                    skill.industryId = Value.industryId;
                    skill.technologyId = Value.technologyId;
                    skill.experience = Value.experience;
                    skill.idx = i + 1;
                    skill.technologyName = Value.technologySet.technology;
                    skill.skillSet = Value.skillSet;
                    this.TechSkills.push(skill);
                }
            }
        },
        saveInterviewer() {
            this.$v.interviewerinfo.$touch();
            if (this.form.robot) {
                if (this.$v.interviewerinfo.$invalid) {
                    return false;
                }
                else {
                    this.interviewerinfo.interviewersSkill[0].technologyId = this.interviewerinfo.technologyId;
                    //this.interviewerinfo.interviewersContact.mobileNo = mobile;
                    axios.post(this.$store.getters.getAPIBasePath + '/interviewerresource/update/public/interviewer/' + this.interviewerId, this.interviewerinfo)
                        .then(response => {
                            let result = response.data;
                            this.showForm = false;
                            // this.updateSkills(result.interviewer.interviewersSkill);
                            if (!response.status) {
                                iziToast.error({
                                    title: 'Error',
                                    message: result.message,
                                    position: 'topRight'
                                });

                            } else {
                                iziToast.success({
                                    title: 'Success',
                                    message: this.$config.IntRegSuc,
                                    position: 'topRight'
                                });
                                /* this.$router.push('/interviewers');*/
                            }
                        })
                        .catch(error => {
                            iziToast.error({
                                title: 'Error',
                                message: this.$config.IntRegErr,
                                position: 'topRight'
                            });
                        });
                }
            }
            else {
                this.form.errorMessage = "Please check the ReCAPTCHA";
            }
        }
    }
}

</script>
