<template>
	<div class="one-content active" id="candidate_shortlist">

		<div class="card">
			<div class="card-header p-2">Client Review Candidates</div>
			<div class="card-body p-2">
				<div class="basic-block">
					<div class="row">
						<div class="col-sm-12">
							<div class="table-responsive">
								<table class="table table-borderless all-table">
									<thead>
										<tr>
											<th scope="col">Name</th>
											<th scope="col">Position</th>
											<th scope="col">Exp</th>
											<!-- <th scope="col">Skills</th> -->
											<th scope="col">Place</th>
											<th v-if="candContactAccess==1" scope="col">Phone</th>
											<th v-if="candContactAccess==1" scope="col">Email</th>
											<th scope="col">Status</th>
										</tr>
									</thead>
									<tbody>
										<tr v-if="shortList.shortListCand.length==0">
											<td colspan=9 align="center">No Candidates found</td>
										</tr>
										<tr v-for="(ShortListCandidate,index) in shortList.shortListCand">
											<td><router-link title="View Candidate Profile" :to="{ name: 'viewcandidate', params: { candidateid: ShortListCandidate.candidateId }}">{{ShortListCandidate.candidateName}}</router-link></td>
											<td>{{ShortListCandidate.position}}</td>
											<td>{{ShortListCandidate.experience}}</td>
											<!-- <td>{{ShortListCandidate.technology}}</td> -->
											<td>{{ShortListCandidate.cityName}} {{ShortListCandidate.stateName}}</td>
											<td v-if="candContactAccess==1">{{ShortListCandidate.mobile}}</td>
											<td v-if="candContactAccess==1">{{ShortListCandidate.email}}</td>
											<td>
												<a class="nav-link dropdown-toggle " href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
													<span :class="ShortListCandidate.statusName" class="label text-white p-1 rounded w-50-auto slstatus w-95px">{{ShortListCandidate.statusName}}</span>
													<div class="dropdown-menu p-0" aria-labelledby="navbarDropdown">
														<ul class="m-0">
															<li v-for="(CandSLStatus, idx) in shortList.candSLStatus" v-bind:key="idx">
																<a v-on:click="$emit('changeSLcandStatus', {'rpCandSLid':ShortListCandidate.rpCandShortlistId, 'CandSLstatusId':CandSLStatus.id})" class="dropdown-item" href="javascript:">{{CandSLStatus.name}}</a>
															</li>
														</ul>
													</div>
												</a>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>
<script>
	export default {
		props: {
			shortlistedCandidates: Array,
			candContactAccess: [String, Number]
		},
		computed:{
			jobid(){
				return this.$route.params.jobid;
			}
		},
		watch: {
			shortlistedCandidates(newValue,oldValue){
				this.shortList.shortListCand = this.filterByStatus(4,6);
			}
		},
		data(){
			return {
				recPlanId: localStorage.getItem('setRecPlanId'),//this.$store.getters.getRecPlanId,
				elements:{
					showSearch: false,
					showCanList: false
				},
				shortList:{
					candSLStatus: this.$store.getters.getShortListedCandidateStatus,
					selCandidatesId:[],
					selCanIdJson:[],
					shortListCand:[]
				}
			}
		},
		mounted(){
			this.shortList.shortListCand = this.filterByStatus(4,6);
			this.shortList.candSLStatus = this.filterByDropDownStatus(4,7)
		},
		methods: {
			scheduleInterview(candId){
				console.log(candId);
				localStorage.setItem('recPlanId',this.recPlanId);
				this.$router.push('/candidates/interview/'+candId);
			},
			filterByStatus(startId,endId){
				return this.shortlistedCandidates.filter(function(data,key){
					if((data.status>=startId && data.status<=endId) && data.status != 5){
						return data;
					}
				});
			},
			filterByDropDownStatus(startId,endId){
				return this.shortList.candSLStatus.filter(function(data,key){
					if((data.id>=startId && data.id<=endId) && (data.id != 5 && data.id != 6 )){
						return data;
					}
				});
			}
		}
	}
</script>