<template>
    <div class="admin-blocks add-recruiter mt-70">
        <div class="container-fluid mtb-4 bg-white p-tb-15" id="quick-search">
            <div class="driver-model" style="padding:0px 0px 10px;">
                <div class="card-header p-2">
                    <div class="row">
                        <div class="col-md-6">
                            <a href="javascript:" @click="$router.go(-1)" title="Back" class="p-2">
                            <i class="fa fa-arrow-left"></i>
                            <span> Back</span>
                            </a> Add Recruiter
                        </div>
                        <div class="col-md-6 text-right">
                            {{recruiterInfo.recruiterCode}}
                        </div>
                    </div>
                </div>
                <div class="card-body p-2">
                    <div class="basic-block">
                        <div class="row ten-columns">
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>First Name</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group required-field-block">
                                            <input type="text" name="firstName" v-model="recruiterInfo.firstName" maxlength="45" size="30" class="form-control" />
                                            <span class="required-icon"></span>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <label v-if="$v.recruiterInfo.firstName.$error && !$v.recruiterInfo.firstName.required" class="text-danger">First Name is required</label>
                                        <label v-if="$v.recruiterInfo.firstName.$error && !$v.recruiterInfo.firstName.minLength" class="text-danger">First Name must've minimum 2 characters</label>
                                        <label v-if="$v.recruiterInfo.firstName.$error && !$v.recruiterInfo.firstName.maxLength" class="text-danger">Maximum limit is 30 characters</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Last Name</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group ">
                                            <input type="text" name="lastName" v-model="recruiterInfo.lastName" maxlength="45" size="30" class="form-control" />
                                            <span class="required-icon"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Email</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group required-field-block">
                                            <input type="email" name="email" v-model="recruiterInfo.email" maxlength="45" size="30" class="form-control"  />
                                            <span class="required-icon"></span>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <label v-if="$v.recruiterInfo.email.$error && !$v.recruiterInfo.email.required" class="text-danger">Email is required</label>
                                        <label v-if="$v.recruiterInfo.email.$error && !$v.recruiterInfo.email.email" class="text-danger">Invalid Email</label>
                                        <label v-if="emailexists" class="text-danger">Email already exists</label>
                                        <!-- <label v-if="$v.recruiterInfo.email.$error && $v.recruiterInfo.email.email && !$v.recruiterInfo.email.emailexists" class="text-danger">Email already exists</label> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Phone No.</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <PhoneNumber ref="phoneNumber" :value="recruiterInfo.mobileNo" @phone-input="phoneControlInput"/>
                                        <!-- <div class="input-group required-field-block">
                                            <select class="form-control box-none col-sm-3 pl-0 pr-0 max-w-45 border-bottom-0" v-model="recruiterInfo.countryCode">
                                                <option value="+1">+1</option>
                                                <option value="+91">+91</option>
                                            </select>
                                            <input-mask class="form-control" v-model="recruiterInfo.phone" mask="(999)-999-9999" placeholder="Phone No" :alwaysShowMask="false"  maskChar="_"></input-mask>
                                                        
                                            <input id="phoneNo" type="text" name="phoneNo" v-model="recruiterInfo.phoneNo" maxlength="10" size="30" class="form-control" @keypress="formatUsPhone($event,'#phoneNo')" @blur="$v.recruiterInfo.phoneNo.$touch" /> -->
                                        <!-- </div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Country</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <select class="form-control" name="country" v-model="recruiterInfo.countryId">
                                            <option v-for="Country in Countries" v-bind:key="Country.country_Id" :value="Country.country_Id">{{Country.name}}</option>
                                            <!-- <option v-for="State in USStates" v-bind:key="State.id" :value="State.id">{{State.stateName}}</option> -->
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Time Zone</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <Select2 v-model="recruiterInfo.timezone" :options="timeZones" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-header p-2"><a href="javascript:" @mousedown="saveRecruiter" class="btn btn-primary float-right">Invite</a></div>
            </div>
        </div>
    </div>
</template>
<script>
import { required, email, minLength, maxLength, helpers } from "vuelidate/lib/validators";
import Select2 from 'v-select2-component';
import iziToast from 'izitoast';
const usPhone = helpers.regex('usPhone', /^\(\d{3}\)\s\d{3}-\d{4}$/);

export default {
    components: {
        Select2
    },
    data() {
        return {
            elements: {
                submitted: false
            },
            emailexists: false,
            Countries: this.$store.getters.getCountries,
            USStates: this.$store.getters.getUSStates,
            timeZones: this.$store.getters.getTimeZones,
            accountType: this.$store.getters.getAccountTypeOptions,
            recruiterInfo: {
                recruiterCode: "",
                firstName: "",
                lastName: "",
                accountName: "",
                companyName: "",
                accountType: "2",
                email: "",
                mobileNo: "",
                zipcode: "",
                countryId: "US",
                timezone: "",
                countryCode:"+1",
                type:1 //Recruiter type
            }
        }
    },
    validations: {
        recruiterInfo: {
            firstName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30)
            },
            email: {
                required,
                email,
                /*       emailexists(email) {
                           return axios.post(this.$store.getters.getAPIBasePath + '/recruiterresource/checkemailavailability', { email: email })
                               .then(response => {
                                   if (response.data.status) {
                                       return true;
                                   }
                                   return false;
                               })
                               .catch(error => {
                                   return false;
                               });
                       }*/
            },
  /*           phoneNo: {
                usPhone
            } */
            /*zipcode: {
                // minLength: minLength(5),
                // maxLength: maxLength(5),
                uszip(zipcode){
                    // return /^\d{5}(-\d{4})?$/.test(zipcode);
                    return /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/.test(zipcode);
                }
            }*/
            /*country: {

            },
            timezone: {

            }*/
        }
    },
    mounted() {
        this.getRecruiterCode();
    },
    methods: {
        emailValidation(email) {
            return axios.post(this.$store.getters.getAPIBasePath + '/recruiterresource/checkemailavailability', { email: email })
                .then(response => {
                    if (response.data.status) {
                        return true;
                    }
                    return false;
                })
                .catch(error => {
                    return false;
                });
        },
        getRecruiterCode() {
            axios.post(this.$store.getters.getAPIBasePath + '/commonresource/autoincrementcode/recruiter')
                .then(response => {
                    if (response.data.status) {
                        this.recruiterInfo.recruiterCode = response.data.code;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
/*         formatUsPhone: function(evt, idx) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                var curchr = $(idx).val().length;
                var curval = $(idx).val();
                if (curchr == 3 && curval.indexOf("(") <= -1) {
                    $(idx).val("(" + curval + ")" + " ");
                } else if (curchr == 4 && curval.indexOf("(") > -1) {
                    $(idx).val(curval + ")-");
                } else if (curchr == 5 && curval.indexOf(")") > -1) {
                    $(idx).val(curval + "-");
                } else if (curchr == 9) {
                    $(idx).val(curval + "-");
                    $(idx).attr('maxlength', '14');
                }
                return true;
            }
        }, */
        phoneControlInput: function (value) {
            console.log(value);
             this.recruiterInfo.mobileNo = value;
        },
        async saveRecruiter() {
            this.elements.submitted = true;
            this.emailexists = false;
            this.$v.recruiterInfo.$touch();
            if (!this.$v.recruiterInfo.$invalid) {

                let isexists = await this.emailValidation(this.recruiterInfo.email);

                if (!isexists) {
                    this.emailexists = true;
                    iziToast.info({ message: EmailExistErr, position: 'topRight' });
                    return;
                }

                
                // if (this.recruiterInfo.phone != null && this.recruiterInfo.phone != '') {
                //     this.recruiterInfo.mobileNo = this.recruiterInfo.countryCode + " " + this.recruiterInfo.phone;                
                // }

                axios.post(this.$store.getters.getAPIBasePath + '/recruiterresource/add/recruiter', this.recruiterInfo)
                    .then(response => {
                        if (response.data.status == "ACCEPTED") {
                            iziToast.success({ title: 'Success', message: this.$config.RecAddSuc, position: 'topRight' });
                            this.$router.push('/candidates/list/recruiter');
                        } else {
                            iziToast.error({ title: 'Error', message: this.$config.Updateerr, position: 'topRight' });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        iziToast.error({ title: 'Error', message: this.$config.Updateerr, position: 'topRight' });
                    });
                this.elements.submitted = false;
            }

        },
        addLogActivity: function() {
            this.updateLogActivity();
            this.logActivityData = {
                entityName: 'Recruiter',
                actionName: 'New Recruiter',
            };
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/add/logactivity', this.logActivityData)
                .then(response => {
                    localStorage.setItem('candIdForActivity', response.data.id);
                })
                .catch(error => {

                });
        },
        updateLogActivity: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/update/logactivity')
                .then(response => {})
                .catch(error => {
                    console.log(error);
                });
        },
    }
}

</script>
