<template>
	<div class="one-content active" id="candidate_shortlist">

		<div class="card" v-if="showOfferLetter==false">
			<div class="card-header p-2">Hired Candidates <i @click="refreshTab()" title="Refresh" class="fa fa-refresh refreshIcon"></i></div>
			<div class="card-body p-2">
				<div class="basic-block">
					<div class="row">
						<div class="col-sm-12">
							<div class="table-responsive">
								<table class="table table-borderless all-table">
									<thead>
										<tr>
											<th></th>
											<th scope="col">Name</th>
											<th scope="col">Position</th>
											<th scope="col">Exp</th>
											<!-- <th scope="col">Skills</th> -->
											<th scope="col">Place</th>
											<th v-if="candContactAccess==1" scope="col">Phone</th>
											<th v-if="candContactAccess==1" scope="col">Email</th>
											<th scope="col">Hired Date</th>
											<th scope="col">Status</th>
											<th v-if="offerLetterAccess" scope="col">Offer Letter</th>
										</tr>
									</thead>
									<tbody>
										<tr v-if="shortList.shortListCand.length==0">
											<td colspan=11 align="center">No Candidates found</td>
										</tr>
										<template v-for="(ShortListCandidate,index) in shortList.shortListCand">
										<tr>
											<td>
                                                <a href="javascript:" class="expand" data-targetopen="panel1">
                                                    <span @click="getincidents(ShortListCandidate.candidateId)"><i :class="(currentIndex==ShortListCandidate.candidateId) ? 'ti-arrow-circle-up' : 'ti-arrow-circle-down'"></i>
                                                    </span>
                                                </a>
                                            </td>
											<td><router-link title="View Candidate Profile" :to="{ name: 'viewcandidate', params: { candidateid: ShortListCandidate.candidateId }}">{{ShortListCandidate.candidateName}}</router-link></td>
											<td>{{ShortListCandidate.position}}</td>
											<td>{{ShortListCandidate.experience}}</td>
											<!-- <td>{{ShortListCandidate.technology}}</td> -->
											<td>{{ShortListCandidate.cityName}} {{ShortListCandidate.stateName}}</td>
											<td v-if="candContactAccess==1">{{ShortListCandidate.mobile}}</td>
											<td v-if="candContactAccess==1">{{ShortListCandidate.email}}</td>
											<td>{{ShortListCandidate.hiredDate | formatDate}}</td>
											<td class="col-2">
												<span :class="ShortListCandidate.statusName" class="label text-white p-1 rounded w-50-auto slstatus w-100px">{{ShortListCandidate.statusName}}</span>
												<!-- <a class="nav-link dropdown-toggle " href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
													<span :class="ShortListCandidate.statusName" class="label text-white p-1 rounded w-50-auto slstatus">{{ShortListCandidate.statusName}}</span>
													<div class="dropdown-menu p-0" aria-labelledby="navbarDropdown">
														<ul class="m-0">
															<li v-for="(CandSLStatus, idx) in shortList.candSLStatus" v-bind:key="idx">
																<a v-on:click="$emit('changeSLcandStatus', {'rpCandSLid':ShortListCandidate.rpCandShortlistId, 'CandSLstatusId':CandSLStatus.id})" class="dropdown-item" href="javascript:">{{CandSLStatus.name}}</a>
															</li>
														</ul>
													</div>
												</a> -->
											</td>
											<td v-if="offerLetterAccess">
												<div class="d-flex justify-content-between">
												<a v-if="ShortListCandidate.counterOfferStatus =='Candidate_Responded'" style="color:white" @click="candReqOffer(ShortListCandidate)" href="javascript:;"><i style="color: #2980b9;cursor: pointer;font-size: 15px;" class="fa fa-envelope" title="Counter Offers"/></a>
												<button type="button" v-if="(ShortListCandidate.offerSent==null || ShortListCandidate.offerSent==0)" @click="showOfferLetter = true;candDetail(ShortListCandidate)" class="btn btn-primary btn-sm w-72px">Generate</button>
												<div v-if="ShortListCandidate.notificationEmailSent != 1 && ShortListCandidate.status == 7" class="form-check form-check-inline">
													<input class="form-check-input" type="checkbox" :id="'checkbox_' + index" v-model="ShortListCandidate.isChecked" @change="notifyCandidate(ShortListCandidate)"/>                                   
													<label class="form-check-label" :for="'checkbox_' + index"></label>
													<span style="color: #2185ca;font-size: 14px;" title="Notify the Candidate"><i class="fa fa-bell" aria-hidden="true"></i></span>
												</div>
												<button disabled type="button" v-if="ShortListCandidate.offerSent== 1 && ShortListCandidate.status== 5 && ShortListCandidate.counterOfferStatus !='Candidate_Responded' && ShortListCandidate.counterOfferStatus !='Recruiter_Responded'" class="btn btn-primary btn-sm w-72px">Generate</button>
												<button type="button"  v-if="(ShortListCandidate.status==6 || ShortListCandidate.status==17 || ShortListCandidate.status== 18) && ShortListCandidate.offerSent!==2  && ShortListCandidate.counterOfferStatus =='Recruiter_Responded'" @click="regenCandDetail(ShortListCandidate)" class="btn btn-primary btn-sm w-72px">ReGenerate</button>
												<button disabled type="button" v-if="ShortListCandidate.offerSent==2" class="btn btn-primary btn-sm w-72px">ReGenerate</button>
												<div class="withdraw-icon" v-if="ShortListCandidate.offerSent!==null && ShortListCandidate.offerSent!==0" @click="offerData(ShortListCandidate)" data-toggle="modal" data-target="#offerWithdraw" title="Withdraw" ><i class="fa fa-file-text-o file" aria-hidden="true"></i><i class="fa fa-remove remov-icon" aria-hidden="true"></i></div>
												</div>
											</td>
										</tr>
										<tr v-if="currentIndex==ShortListCandidate.candidateId">
										<td colspan="11">
                                                <div class="table-responsive recscroll">
                                                    <table class="table table-borderless all-table">
                                                        <thead>
                                                            <tr>
                                                                <th class="" scope="col">S.No.</th>
                                                                <th class="" scope="col">User Name</th>
																<th class="" scope="col">User Type</th>
                                                                <th class="" scope="col">Incident Date</th>
                                                                <th class="" scope="col">Incident Type</th>
                                                                <th class="" scope="col">Action</th>
																<th v-if="false" class="" scope="col">Notes</th>
                                                            </tr>
                                                        </thead>
														<tbody>
                                                            <tr v-for="(incidentData,index) in jobincidentList">
																<td>{{index+1}}</td>
                            									<td>{{ incidentData.userName }} </td>
																<td>{{ incidentData.userType }} </td>
                            									<td>{{ incidentData.createdAt | formatDate}}</td>
                            									<td>{{ incidentData.incidentTypeName }}</td>
                            									<td>{{ incidentData.action }}</td>
																<td v-if="false" >{{ incidentData.note }}</td>
																<!-- <td v-if="incidentData.note == 'CounterOffer'"><a style="color:white" href="javascript:;" data-toggle="modal" data-target="#listCountOffers"><i style="color: #2980b9;cursor: pointer;font-size: 15px;" class="fa fa-envelope"/></a></td>
																<td v-else></td> -->
															</tr>
                                                        </tbody>
													</table>
												</div>
											</td>
										</tr>
										</template>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="showOfferLetter">
			<OfferLetter  ref="offer" :jobInfo="jobInfo" @showOfferLetter="showOfferLetter=false" :jobCandInfo="jobCandInfo" :setdata="candData" :candCounterOffer="candCounterOffer"/>
		</div>
		<Dialog id="offerWithdraw" :onYes="withdrawOffer" title="Offer Withdrawal Confirmation" message="Are you sure to Withdraw the Offer?" />
		<div class="modal" id="listCountOffers" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Counter Offers</h5>
                        <button type="button" @click="closeModal"  class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
						<div class="d-flex" style="margin-bottom: 8px;">
							<table class="table table-borderless all-table">
								<thead>
									<tr>
										<th>Counter Offers</th>
										<th>Candidate Value</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="Offers in candCounterOffer">
										<td>
											<label >{{ Offers.settingsCounterOffer.name}}</label>
										</td>
										<td>
											<label >{{(Offers.candidateValue == null || Offers.candidateValue == "")?Offers.recruiterValue:Offers.candidateValue}}</label>
										</td>
										<td> 
											<div class="d-flex">
												<div>
													<i v-if="!acceptedItems.includes(Offers.settingsCounterOffer.id) && !rejectedItems.includes(Offers.settingsCounterOffer.id)" @click="accept(Offers.settingsCounterOffer, Offers.candidateId, Offers)" title="Accept" class="fa fa-check d-flex" style="margin-left: 15px; color: green; cursor: pointer;align-items: center;"></i>
													<div v-if="acceptedItems.includes(Offers.settingsCounterOffer.id) && !rejectedItems.includes(Offers.settingsCounterOffer.id)" disabled class="acceptd"><span>Accepted</span></div>
													</div>
												<div>
													<i v-if="!rejectedItems.includes(Offers.settingsCounterOffer.id) && !acceptedItems.includes(Offers.settingsCounterOffer.id)" @click="reject(Offers.settingsCounterOffer,Offers.candidateId, Offers)" title="Reject" class="fa fa-close d-flex" style="margin-left: 15px; color: red; cursor: pointer;align-items: center;"></i> 
													<div v-if="rejectedItems.includes(Offers.settingsCounterOffer.id) && !acceptedItems.includes(Offers.settingsCounterOffer.id)" disabled class="rejectd"><span>Rejected</span></div>
												</div>
											</div>
										</td>
									</tr>
								</tbody>
                  			</table>
						</div>
                    </div>
                    <div class="modal-footer">                                            
                        <button type="submit" @click="sendingOffers()" class="btn btn-primary">Ok</button>
                        <button type="button" @click="closeModal()" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>
<script>
import OfferLetter from '@/components/jobs/blocks/Integration/OfferLetter'; 
import iziToast from "izitoast";
	export default {
		components: {
        OfferLetter   
    },
		props: {
			shortlistedCandidates: Array,
			candContactAccess: [String, Number],
			jobInfo: {
            type: Object,
            default: () => {}
        }
		},
		computed:{
			jobid(){
				return this.$route.params.jobid;
			}
		},
		watch: {
			shortlistedCandidates(newValue,oldValue){
				//this.shortList.shortListCand = this.filterByDropDownStatus([5,6,7,9,16,17]);
				this.shortList.shortListCand = this.filterByStatus();
			}
		},
		data(){
			return {
				isChecked: false,
				recResponse: [],
				rejectedItems: [],
				acceptedItems: [],
				recruiterResponse:[],
				jobCandInfo:[],
				candCounterOffer:[],
				counterOffers:[],
				candData: "",
				showOfferLetter: false,
				currentIndex: null,
				selectedInterviewId: null,
				incidentParams:{
					incidentAgainstId: 0,
					jobId:0
            	},
				offerLetterAccess:Boolean,
				recPlanId: localStorage.getItem('setRecPlanId'),//this.$store.getters.getRecPlanId,
				elements:{
					showSearch: false,
					showCanList: false
				},
				shortList:{
					candSLStatus: this.$store.getters.getShortListedCandidateStatus,
					selCandidatesId:[],
					selCanIdJson:[],
					shortListCand:[]
				},	
				slcandidateData:[],	
				jobincidentList:{}
			}
		},
		mounted(){
			//this.shortList.shortListCand = this.filterByDropDownStatus([5,6,7,9,16,17]);	
			this.shortList.shortListCand = this.filterByStatus();		
			console.log(this.shortList.shortListCand);
			this.offerAccess();
			this.listCounterOffers();
		},
		methods: {
			removeHTMLTags(str) {
				return str.replace(/<[^>]*>/g, '');
			},
			notifyCandidate(ShortListCandidate) {
			 	var notifySalary = (this.jobInfo.currency =='₹' ? 'Rs' : this.jobInfo.currency) + ' ' + (ShortListCandidate.offerAmount? ShortListCandidate.offerAmount: (this.jobInfo.salary?this.jobInfo.salary:this.jobInfo.minSalary))+ ' ' + this.jobInfo.payFrequency
				
				if (ShortListCandidate.isChecked) {
					var params = {
						email: ShortListCandidate.email,
						candidateName: ShortListCandidate.candidateName,
						jobTitle: this.jobInfo.jobTitle,
						salary: notifySalary,
						jobDescription: this.removeHTMLTags(this.jobInfo.jobRequirement),
						rpCandShortlistId: ShortListCandidate.rpCandShortlistId
						
					}
					axios.post(this.$store.getters.getAPIBasePath + '/recruitmentplanresource/hired/notification',params)
						.then(response => {
							iziToast.success({
								title: 'Success',
								message: response.data.message,
								position: 'topRight'
							});
							this.$root.$emit('viewCanShortList')
						})
						.catch(error => {
							this.showOverlay=false;
							console.log(error);
						});
				}
			},
			sendingOffers(){
				axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/update/candidate/counteroffer',this.recruiterResponse)
                .then(response => {
                      this.closeModal();
					  if (response.data.status== "OK") {
							iziToast.success({
								title: 'Success',
								message: this.$config.counterOfferUpd,
								position: 'topRight'
							});
						}
					  this.$root.$emit('viewCanShortList');
                      console.log("recruiter Responded");
                   
                })
                .catch(error => {
                    this.showOverlay=false;
                    console.log(error);
                });

			},
			accept(Offer, candId, offerItem) {
				this.recruiterResponse.push({
					"id": offerItem.id,
					"rpCandShortlistId": this.candData.rpCandShortlistId,
					"counterOfferId": Offer.id,
					"candidateId": candId,
					"status": "Recruiter_Accepted",
					"candidateValue": offerItem.candidateValue,
            		"recruiterValue": ((offerItem.candidateValue == null || offerItem.candidateValue == "")? offerItem.recruiterValue :offerItem.candidateValue),
				});
				this.acceptedItems.push(offerItem.settingsCounterOffer.id); 
				console.log("recruiterResponse", this.recruiterResponse);
			},
			reject(Offer, candId, offerItem) {
				this.recruiterResponse.push({
					"id": offerItem.id,
					"rpCandShortlistId": this.candData.rpCandShortlistId,
					"counterOfferId": Offer.id,
					"candidateId": candId,
					"status": "Recruiter_Rejected",
					"candidateValue": offerItem.candidateValue,
            		"recruiterValue": offerItem.recruiterValue,
				});
				this.rejectedItems.push(offerItem.settingsCounterOffer.id); 
				console.log("recruiterResponse", this.recruiterResponse);
			},
			closeModal() {
				this.recruiterResponse = [];
				this.rejectedItems = [];
				this.acceptedItems = [];
				document.getElementById('listCountOffers').classList.remove('show');
				document.getElementById('listCountOffers').style.display = 'none';
				document.getElementById('listCountOffers').setAttribute('aria-hidden', 'true');
			},
			listCounterOffers() {
				axios.post(this.$store.getters.getAPIBasePath + '/settingsresource/conteroffer/list')
					.then(response => {
						if (response.data) {
							this.counterOffers = response.data.recordinfo;
						}
					})
					.catch(error => {
						console.log(error);
					});
			},
			refreshTab(){
				this.$root.$emit('viewCanShortList')
			},
			offerData(data) {
				this.slcandidateData = data;
			},
			withdrawOffer() {
				axios.post(this.$store.getters.getAPIBasePath + '/recruitmentplanresource/update/rp/candshortlist/status/' + this.slcandidateData.rpCandShortlistId, { "status": 17,"candidateId":this.slcandidateData.candidateId,"jobId": this.slcandidateData.jobId})
				.then(response => {
					iziToast.success({
						title: 'Success',
						message: this.$config.offerWdSuc,
						position: 'topRight'
					});
					this.$root.$emit('viewCanShortList')
				})
				.catch(error => {
					iziToast.error({
						title: 'Error',
						message: this.$config.offerWdErr,
						position: 'topRight'
					});
				});
			},
			candDetail(ShortListCandidate){
				this.candData = ShortListCandidate;
				this.candData.jobId = this.jobid;
			},
			regenCandDetail(ShortListCandidate){
				this.candData = ShortListCandidate;
				this.candData.jobId = this.jobid;
				axios.put(this.$store.getters.getAPIBasePath + '/jobresource/get/candidate/offerletter/' + ShortListCandidate.rpCandShortlistId)
                .then(response => {
                    this.candCounterOffer = response.data.recordinfo;
                    this.jobCandInfo = response.data.data;
					this.showOfferLetter = true;
                })
                .catch(error => {
                    console.log(error);
                });
			},
			candReqOffer(ShortListCandidate){
				this.candData = ShortListCandidate;
				this.candData.jobId = this.jobid;
				axios.put(this.$store.getters.getAPIBasePath + '/jobresource/get/candidate/offerletter/' + ShortListCandidate.rpCandShortlistId)
                .then(response => {
                    this.candCounterOffer = response.data.recordinfo;
                    this.jobCandInfo = response.data.data;
					document.getElementById('listCountOffers').classList.add('show');
					document.getElementById('listCountOffers').style.display = 'block';
					document.getElementById('listCountOffers').setAttribute('aria-hidden', 'false');
                })
                .catch(error => {
                    console.log(error);
                });
			},
			scheduleInterview(candId){
				console.log(candId);
				localStorage.setItem('recPlanId',this.recPlanId);
				this.$router.push('/candidates/interview/'+candId);
			},
			filterByStatus(){
				return this.shortlistedCandidates.filter(function(data, key) {
					const statusValues = [5, 6, 7, 9, 16, 17, 18];
					if(statusValues.includes(parseInt(data.status))) {
						return data;
					}
				});
			},
			filterByDropDownStatus(selStatus) {		
				return this.shortlistedCandidates.filter(function(data, key) {	
					if (selStatus.includes(data.status)) {					
						return data;
					}
				});
			},
			getincidents(id) {
				if (this.currentIndex == id) {
					this.currentIndex = null;
				} else {
					this.currentIndex = id;
					this.selectedInterviewId = id;
				}
				this.incidentParams.incidentAgainstId = id;
				this.incidentParams.jobId = this.jobid;
				axios.post(this.$store.getters.getAPIBasePath + '/incidentresource/incident/list/recruitment',this.incidentParams)
                .then(response => {
					console.log(response);
                    let result = response.data.data;
                     this.jobincidentList = result;
                })
                .catch(error => {
                    console.log(error);
                });
			},
			offerAccess(){
				if(localStorage.getItem('offerLetterAccess') === "false"){
					this.offerLetterAccess  = false
				}else if(localStorage.getItem('offerLetterAccess') === "true"){
					this.offerLetterAccess  = true
        	}
    },
		}
	}
</script>