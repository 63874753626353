<style type="text/css">
    .loader {
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: 9999;
        background: url('/images/loading.gif') 50% 50% no-repeat rgba(249,249,249,0.3);
        background-size: 5%;
    }
</style>
<template>
    <div id="app">
       <div id="globalapploader" class="loader"></div>
        <component :is="layout">
            <router-view/>
        </component>
    </div>
</template>

<script>
const default_layout = "default";
// const home_layout = "home"
import VueMeta from 'vue-meta';
export default {
    computed:{
        layout(){
            return (this.$route.meta.layout || default_layout )+"-layout";
        }
    },
    watch: {
        '$route.name': {
            handler: function(newVal,oldVal) { 
                if(this.$store.getters.getRouteName.includes(newVal)){
                    $('#globalapploader').css('visibility','hidden');
                }else{
                    $('#globalapploader').css('visibility','visible');
                }
            },
            deep: true,
            immediate: true
        },
    },
    mounted(){
        if(this.layout=="login-layout" || this.layout=="home-layout" || this.layout=="mobile-layout"){
            $('#globalapploader').fadeOut('slow');
        }
    },
    name: 'App'
}
</script>