<template>
    <div>
        <div class="card">
            <div class="card-header p-2"> Physical Address</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Address line 1</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group required-field-block">
                                        <input maxlength="100" name="physicalAddress" v-model="addressinfo.physicaladdress.addressLine1" class="fldboxaddress form-control"></input>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Phone Number</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <PhoneNumber ref="phoneNumber" :value="addressinfo.physicaladdress.phoneNumber"  @phone-input="phoneControlInput"  />
                                    <div v-if="false" class="input-group">
                                        <select class="form-control box-none col-sm-3 pl-0 pr-0 max-w-45 border-bottom-0" v-model="addressinfo.physicaladdress.countryCode">
                                            <option value="+1">+1</option>
                                            <option value="+91">+91</option>
                                        </select>
                                        <input-mask class="form-control box-none" v-model="addressinfo.physicaladdress.phoneNo" mask="(999)-999-9999" placeholder="Phone No" :alwaysShowMask="false" maskChar="_"></input-mask>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Address line 2</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input name="physicalAddMapURL" maxlength="100" v-model="addressinfo.physicaladdress.addressLine2" class="fldboxaddress form-control"></input>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Fax Number</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input type="text" name="faxNumber" v-model="addressinfo.physicaladdress.faxNumber" maxlength="15" value="" class="Submitaddress form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>City</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group required-field-block">
                                        <input type="text" name="physicalAddCity" v-model="addressinfo.physicaladdress.city" maxlength="25" value="" class="Submitaddress form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>State</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <select v-if="addressinfo.physicaladdress.countryId=='US'" v-model="addressinfo.physicaladdress.stateName" class="form-control">
                                            <option value="">--</option>
                                            <option v-for="(data, index) in usstates" :value="data.stateName">{{data.stateName}}</option>
                                        </select>
                                        <input v-if="addressinfo.physicaladdress.countryId!='US'" v-model="addressinfo.physicaladdress.stateName" type="text" maxlength="25" minlength="2" size="23" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Zip Code</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group required-field-block">
                                        <input type="text" name="physicalAddZip" v-model="addressinfo.physicaladdress.zipCode" id="physicalAddZip" maxlength="10" value="" class="Submitaddress form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Country</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <select v-model="addressinfo.physicaladdress.countryId" @change="addressinfo.physicaladdress.stateName=''" class="form-control">
                                            <option value="">--</option>
                                            <option v-for="(data, index) in countrieslist" :value="data.country_Id">{{data.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header p-2">
                Billing Address
                <div class="form-check form-check-inline" style="padding-left: 25px;">
                    <input type="checkbox" name="billType" v-model="addressinfo.physicaladdress.sameAsAbove" @change="copyAddress" value="on" id="billType" class="form-check-input">
                    <label class="form-check-label" for="billType">Same as Physical Address</label>
                </div>
            </div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Address line 1</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group ">
                                        <input name="billingAddress" maxlength="100" v-model="addressinfo.billingaddress.addressLine1" class="fldboxaddress form-control"></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Address line 2</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input name="billingAddMapURL" maxlength="100" v-model="addressinfo.billingaddress.addressLine2" class="fldboxaddress form-control"></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>City</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input type="text" name="billingAddCity" v-model="addressinfo.billingaddress.city" maxlength="25" value="" class="Submitaddress form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>State</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <select v-if="addressinfo.billingaddress.countryId=='US'" v-model="addressinfo.billingaddress.stateName" class="form-control">
                                            <option value="">--</option>
                                            <option v-for="(data, index) in usstates" :value="data.stateName">{{data.stateName}}</option>
                                        </select>
                                        <input v-if="addressinfo.billingaddress.countryId!='US'" v-model="addressinfo.billingaddress.stateName" type="text" maxlength="25" minlength="2" size="23" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Zip Code</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input type="text" name="billingAddZip" v-model="addressinfo.billingaddress.zipCode" maxlength="10" value="" class="Submitaddress form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Country</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <select v-model="addressinfo.billingaddress.countryId" @change="addressinfo.billingaddress.stateName=''" class="form-control">
                                            <option value="">--</option>
                                            <option v-for="(data, index) in countrieslist" :value="data.country_Id">{{data.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        addressinfo: Object,
    },
    data() {
        return {
            countrieslist: this.$store.getters.getCountries,
            usstates: this.$store.getters.getUSStates,
        }
    },
    methods: {
        copyAddress(){
            if (this.addressinfo.physicaladdress.sameAsAbove) {
                this.addressinfo.billingaddress.addressLine1 = this.addressinfo.physicaladdress.addressLine1;
                this.addressinfo.billingaddress.addressLine2 = this.addressinfo.physicaladdress.addressLine2;
                this.addressinfo.billingaddress.city = this.addressinfo.physicaladdress.city;
                this.addressinfo.billingaddress.stateName = this.addressinfo.physicaladdress.stateName;
                this.addressinfo.billingaddress.zipCode  = this.addressinfo.physicaladdress.zipCode;
                this.addressinfo.billingaddress.countryId = this.addressinfo.physicaladdress.countryId;
            } else {
                this.addressinfo.billingaddress.addressLine1 = "";
                this.addressinfo.billingaddress.addressLine2 = "";
                this.addressinfo.billingaddress.city = "";
                this.addressinfo.billingaddress.stateName = "";
                this.addressinfo.billingaddress.zipCode = "";
                this.addressinfo.billingaddress.countryId = "US";

            }
        },
        phoneControlInput:function(value){
            this.addressinfo.physicaladdress.phoneNumber = value;
        },
        validatePhoneNo:function(){
            return this.$refs.phoneNumber.validatePhoneNumber()
        }
    }
}

</script>
