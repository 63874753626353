<template>
    <div id="main" class="mt-70">
        <div class="container-fluid mtb-4 bg-white p-tb-15" id="quick-search">
            <div class="driver-model" style="padding:0px 0px 10px;">
                <div class="row no-gutters">
                    <b-tabs v-model="currentJobTab" small card class="tab-list driver-tab-list">
                        <b-tab title="Job Details">
                            <!-- <ClientInfo :jobinfo="jobinfo" ref="jobinfocomponent"></ClientInfo> -->
                            <JobInfo @industryId="handleNewValue" :newJobForm="newJobForm" ref="jobinfocomponent"></JobInfo>
                        </b-tab>
                    </b-tabs>
                    <!-- <div class="col-md-12">
                        <a href="Javascript:void(0);" @click="saveCurrentTab" class="btn btn-success btn-sm float-right" style="margin-left: 10px;color: #fff;">Save</a>
                    </div> -->
                    <div class="col-md-12 pt-2 text-right">
                        <a href="javascript:" @click="updateLogActivity"><router-link class="btn btn-secondary mr-2" :to="{ name: 'planrecruitment', params: { jobid: jobid }}"> Cancel </router-link></a>
                        <a href="javascript:" class="btn btn-primary " @click="saveJobInfo">Save</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ClientInfo from './blocks/addjob/ClientInfo';
import JobInfo from './blocks/addjob/JobInfo';
// import AddressInfo from './blocks/addclient/AddressInfo';
// import ClientContacts from './blocks/addclient/ClientContacts';
// import ContractDetails from './blocks/addclient/ContractDetails';
export default {
    computed: {
        jobid() {
            return this.$route.params.jobid;
        }
    },
    components: {
        ClientInfo,
        JobInfo
    },
    data() {
        return {
            currentJobTab: 0,
            maxtabs: 3,
            newJobForm: {
                jobTitle: "",
                jobCode: "",
                clientId: "",
                clientName: "",
                accountName: "",               
                industryId: "",
                taxTermId:"",
                position: "",
                location: "",
                eligibility: "",
                experience: "",
                salary: "",
                minSalary: "",
                maxSalary: "",
                frequencyPercentage: "",
                payFrequency: "Per Month",
                expType: "Years",
                currency: "$",
                vacancies: "",
                workType: "",
                status: "",
                accessLevel: "",
                dueDate: "",
                jobRequirement: "",               
                jobSkill: [],
                technologyList: [],
                techIndustryId:"",
                contact: {
                    contactPerson: "",
                    email: "",
                    phone1: "",
                    phone2: "",
                    website: "",
                    addressLine1: "",
                    addressLine2: "",
                    cityName: "",
                    stateName: "",
                    zipCode: "",
                    countryId: ""
                },
                vendorDetails: [],               
                blockjobtab: 0
            },
            clientinfo: {
                dunsNumber: "",
                clientName: "",
                clientGroup: "",
                accountManager: "",
                url: "",
                backupaccountManager: "",
                jobUrl: "",
                accountExecutive: "",
                status: "",
                description: ""
            }
        }
    },
    mounted() {
        this.loadJobById(this.jobid);

    },
    methods: {
        handleNewValue(newValue) {
            this.newJobForm.industryId = newValue;
        },
        loadJobById(jobId) {

            axios.get(this.$store.getters.getAPIBasePath + '/jobresource/view/job/' + jobId)
                .then(response => {
                    let result = response.data;
                    if (!result.status) {
                        iziToast.error({
                            title: 'Error Occured',
                            message: result.message, 
                            position: 'topRight'
                        });
                    }
                    let self = this;

                    let saved_skills = [];
                    // $.each(result.jobs.jobSkill,function(key,value){
                    //   saved_skills.push(value.technologySkillSet);
                    // });


                    for (var i = 0; i < result.jobs.technologyList.length; i++) {
                        saved_skills.push({
                            technology: {
                                technology: result.jobs.technologyList[i].technologyName,
                                id: 0
                            },
                            skills: []
                        });

                        for (var j = 0; j < result.jobs.technologyList[i].technologySkillData.length; j++) {
                            saved_skills[i].technology.id = result.jobs.technologyList[i].technologySkillData[j].technologyId;
                            saved_skills[i].skills.push({
                                skillId: result.jobs.technologyList[i].technologySkillData[j].id,
                                skillName: result.jobs.technologyList[i].technologySkillData[j].skillName
                            });
                        }

                    }
                    
                    let locationData = [];                    
                    if(result.jobs.locationData != null && result.jobs.locationData != "") {
                        for(var i = 0; i < result.jobs.locationData.length; i++) {
                           locationData.push({stateName:result.jobs.locationData[i].dataName , id: result.jobs.locationData[i].id});
                        }
                    }                     
                    let eligibilityData = [];
                    if(result.jobs.eligibilityData != null && result.jobs.eligibilityData != "") {
                        for(var j = 0; j < result.jobs.eligibilityData.length; j++) {
                           eligibilityData.push({qualificationName:result.jobs.eligibilityData[j].dataName , id: result.jobs.eligibilityData[j].id});
                        }
                    }
                    // if(result.jobs.location != null && result.jobs.location != "") {
                    //     if (result.jobs.location.indexOf(',') > -1) {
                    //     var str_array = result.jobs.location.split(',');
                    //         for(var i = 0; i < str_array.length; i++) {
                    //            // Trim the excess whitespace.
                    //            str_array[i] = str_array[i].replace(/^\s*/, "").replace(/\s*$/, "");
                    //            locationData.push({stateName:str_array[i] , id: i});
                    //         }
                    //     }
                    //     else {
                    //         locationData.push({stateName:result.jobs.location , id: 1});
                    //     }
                    // } 
                    let workAuthData = [];
                    if(result.jobs.workAuthData != null && result.jobs.workAuthData != "") {
                        for(var j = 0; j < result.jobs.workAuthData.length; j++) {                           
                           workAuthData.push({workAuthorizationName:result.jobs.workAuthData[j].dataName, id: result.jobs.workAuthData[j].id});
                        }
                    }
                   
                    let documentData = [];
                    if(result.jobs.jobDocumentData != null && result.jobs.jobDocumentData != "") {
                        for(var j = 0; j < result.jobs.jobDocumentData.length; j++) {
                           documentData.push({documentName:result.jobs.jobDocumentData[j].dataName, id: result.jobs.jobDocumentData[j].id});
                        }
                    }    
                    


                    if (result.jobs.contact != null) {
                        this.newJobForm = {
                            jobTitle: result.jobs.jobTitle,
                            jobCode: result.jobs.jobCode,
                            clientId: result.jobs.clientId,
                            industryId: (result.jobs.industryId == null || result.jobs.industryId == "" ) ? "" : result.jobs.industryId,
                            position: result.jobs.position,
                            location: locationData, //result.jobs.location,
                            eligibility: eligibilityData,//result.jobs.eligibility,
                            workAuthorization: workAuthData,
                            jobDocuments: documentData,
                            experience: result.jobs.experience,
                            salary: result.jobs.salary,
                            minSalary: (result.jobs.minSalary == 0.0)?"":result.jobs.minSalary,
                            maxSalary: (result.jobs.maxSalary == 0.0)?"":result.jobs.maxSalary,
                            frequencyPercentage:(result.jobs.frequencyPercentage == 0.0)?"":result.jobs.frequencyPercentage,
                            vacancies: result.jobs.vacancies,
                            workType: result.jobs.workType,
                            status: result.jobs.status,
                            payFrequency: (result.jobs.payFrequency == null) ? "Per Month" : result.jobs.payFrequency,
                            expType: (result.jobs.expType == null) ? "Per Month" : result.jobs.expType,
                            currency: (result.jobs.currency == null) ? "$" : result.jobs.currency,
                            dueDate: (result.jobs.dueDate == null || result.jobs.dueDate == "" || result.jobs.dueDate == "null") ? "" : new Date(result.jobs.dueDate),
                            jobRequirement: result.jobs.jobRequirement,
                            jobSkill: [],
                            technologyList: result.jobs.technologyList,
                            techIndustryId: (result.jobs.jobSkill.length!=0?result.jobs.jobSkill[0].industryId:null),
                            contact: result.jobs.contact,
                            endClientContact: result.jobs.endClientContact,
                            endclientName: result.jobs.endclientName,
                            endAccountName: result.jobs.endAccountName,
                            clientName: result.jobs.clientName,
                            accountName: result.jobs.accountName,
                            accessLevel: result.jobs.accessLevel,
                            vendorDetails : (result.jobs.jobVendorInfo == null) ? [] : result.jobs.jobVendorInfo,
                            taxTermId: result.jobs.taxTermId,
                            countryId: (result.jobs.countryId == null || result.jobs.countryId == "" ) ? "" : result.jobs.countryId,
                        };     
                        this.$refs.jobinfocomponent.setSkillDetails(saved_skills);
                    } else {                                             
                        this.newJobForm = {
                            jobTitle: result.jobs.jobTitle,
                            jobCode: result.jobs.jobCode,
                            clientId: result.jobs.clientId,
                            industryId: (result.jobs.industryId == null || result.jobs.industryId == "" ) ? "" : result.jobs.industryId,
                            position: result.jobs.position,
                            location: locationData, //result.jobs.location,
                            eligibility: eligibilityData, //result.jobs.eligibility,
                            workAuthorization: workAuthData,
                            jobDocuments: documentData,
                            experience: result.jobs.experience,
                            salary: result.jobs.salary,
                            minSalary: (result.jobs.minSalary == 0.0)?"":result.jobs.minSalary,
                            maxSalary: (result.jobs.maxSalary == 0.0)?"":result.jobs.maxSalary,
                            frequencyPercentage:(result.jobs.frequencyPercentage == 0.0)?"":result.jobs.frequencyPercentage,
                            vacancies: result.jobs.vacancies,
                            workType: result.jobs.workType,
                            status: result.jobs.status,
                            payFrequency: (result.jobs.payFrequency == null) ? "Per Month" : result.jobs.payFrequency,
                            expType: (result.jobs.expType == null) ? "Per Month" : result.jobs.expType,
                            currency: (result.jobs.currency == null) ? "$" : result.jobs.currency,
                            //dueDate: new Date(result.jobs.dueDate), //this.formatDateString(result.jobs.dueDate),
                            dueDate: (result.jobs.dueDate == null || result.jobs.dueDate == "" || result.jobs.dueDate == "null") ? "" : new Date(result.jobs.dueDate),
                            jobRequirement: result.jobs.jobRequirement,
                            jobSkill: [],
                            technologyList: result.jobs.technologyList,
                            techIndustryId: (result.jobs.jobSkill.length!=0?result.jobs.jobSkill[0].industryId:null),
                            clientName: result.jobs.clientName,
                            accountName: result.jobs.accountName,  
                            countryId: (result.jobs.countryId == null || result.jobs.countryId == "" ) ? "" : result.jobs.countryId,                          
                            contact: {
                                contactPerson: "",
                                email: "",
                                phone1: "",
                                phone2: "",
                                website: "",
                                addressLine1: "",
                                addressLine2: "",
                                cityName: "",
                                stateName: "",
                                zipCode: "",
                                countryId: ""
                            },                            
                            accessLevel: result.jobs.accessLevel,
                            vendorDetails : (result.jobs.jobVendorInfo == null) ? [] : result.jobs.jobVendorInfo,
                            taxTermId: result.jobs.taxTermId                            
                        }
                    
                        this.$refs.jobinfocomponent.setSkillDetails(saved_skills);
                    }

                    if(result.jobs.contact != null){
                        let phoneNo = result.jobs.contact.phone2;
                        if (phoneNo != null && phoneNo.startsWith("+")) {
                            let phoneData = phoneNo.split(" ");
                            this.newJobForm.contact.countryCode = phoneData[0];
                            this.newJobForm.contact.mobile = phoneData[1];           
                        }
                    }
                     this.newJobForm.vendorDetails.forEach(function(data) {
                        // let phoneNo = data.phone;
                           let phoneNo = data.phone1;
                        if (phoneNo != null && phoneNo.startsWith("+")) {
                            let phoneData = phoneNo.split(" ");
                            data.countryCode = phoneData[0];
                            data.mobile = phoneData[1];
                        } else{
                            data.mobile = phoneNo;
                        }                             
                    }); 
                    console.log("vendorinfo",result.jobs.jobVendorInfo )
                   

                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.JobLoadErr,
                        position: 'topRight'
                    });
                });
        },
        saveCurrentTab() {
            switch (this.currentJobTab) {
                case 0:
                    this.$refs.jobinfocomponent.validateJobInfo();
                    this.$refs.jobinfocomponent.addMoreSkillDetails();
                    this.$refs.jobinfocomponent.addSkillInfo();
                    //this.saveJobInfo();
                    break;
                default:
                    break;
            }
        },
        saveJobInfo() {            
            if (this.$refs.jobinfocomponent.validateJobInfo()) {
                this.$refs.jobinfocomponent.addMoreSkillDetails();
                this.$refs.jobinfocomponent.addSkillInfo();
                this.$refs.jobinfocomponent.addNewVendorInfo();
                let locationValues = "" ;

                for (var i = 0; i < this.newJobForm.location.length; i++) {
                    if(locationValues == "") 
                        locationValues = this.newJobForm.location[i].id 
                    else 
                        locationValues = locationValues+ "," +this.newJobForm.location[i].id ;
                }
                // for (var i = 0; i < this.newJobForm.location.length; i++) {
                //     if(locationValues == "") 
                //         locationValues = this.newJobForm.location[i].stateName 
                //     else 
                //         locationValues = locationValues+ ", " +this.newJobForm.location[i].stateName ;
                // }                
                let qualificationData = "" ;
                for (var i = 0; i < this.newJobForm.eligibility.length; i++) {                    
                    if(qualificationData == "") 
                        qualificationData = this.newJobForm.eligibility[i].id 
                    else 
                        qualificationData = qualificationData+ "," +this.newJobForm.eligibility[i].id ;
                } 

                let workAuthData = "" ;

                for (var j = 0; j < this.newJobForm.workAuthorization.length; j++) {
                    if(workAuthData == "") 
                        workAuthData = this.newJobForm.workAuthorization[j].id 
                    else 
                        workAuthData = workAuthData+ "," +this.newJobForm.workAuthorization[j].id ;
                }
                
               // for documents required
                let documentData = "" ;
                for (var j = 0; j < this.newJobForm.jobDocuments.length; j++) {
                    if(documentData == "") 
                        documentData = this.newJobForm.jobDocuments[j].id 
                    else 
                        documentData = documentData+ "," +this.newJobForm.jobDocuments[j].id ;
                }
                 this.newJobForm.vendorDetails.forEach(function(data) {
                 if (data.mobile != null && data.mobile != ''&&  data.countryCode != '' && data.mobile != undefined && data.countryCode != undefined && data.countryCode != null)
                    data.phone1 = data.countryCode + " " + data.mobile;                
                else
                     data.phone1 = data.mobile;
                }); 
                // this.newJobForm.vendorDetails.forEach(function(data) {
                //  if (data.mobile != null && data.mobile != '') {
                //     data.phone1 = data.countryCode + " " + data.mobile;                
                // }                  
                // }); 
                //       this.newJobForm.vendorDetails.forEach(function(data) {
                //  if (data.mobile != null && data.mobile != '') {
                //     data.phone1 = data.countryCode + " " + data.mobile;                
                // }                  
                // }); 
                // this.newJobForm.experience = $.trim(this.newJobForm.experience).includes("years")? $.trim(this.newJobForm.experience) : $.trim(this.newJobForm.experience)+" years";
                let jobInfoData = {
                    recruiterId: this.$store.getters.currentUser.profileId,
                    jobTitle: this.newJobForm.jobTitle,
                    jobCode: this.newJobForm.jobCode,
                    clientId: this.newJobForm.clientId,
                    clientName: this.newJobForm.clientName,
                    accountName: this.newJobForm.accountName,
                    industryId: this.newJobForm.industryId,
                    position: this.newJobForm.position,
                    location: locationValues, //this.newJobForm.location,
                    eligibility: qualificationData, //this.newJobForm.eligibility,
                    workAuthorization: workAuthData, 
                    jobDocuments: documentData, 
                    experience: this.newJobForm.experience,
                    salary: this.newJobForm.salary,
                    minSalary:this.newJobForm.minSalary,
                    maxSalary:this.newJobForm.maxSalary,
                    frequencyPercentage:this.newJobForm.frequencyPercentage,
                    payFrequency: this.newJobForm.payFrequency,
                    expType: this.newJobForm.expType,
                    currency: this.newJobForm.currency,
                    vacancies: this.newJobForm.vacancies,
                    workType: this.newJobForm.workType,
                    status: this.newJobForm.status,
                    dueDate: this.formatDateString(this.newJobForm.dueDate),
                    jobRequirement: this.newJobForm.jobRequirement,
                    jobSkill: this.newJobForm.jobSkill,
                    contact: this.newJobForm.contact,
                    accessLevel: this.newJobForm.accessLevel,
                    jobVendorInfo : this.newJobForm.vendorDetails,
                    taxTermId: this.newJobForm.taxTermId,
                    countryId: this.newJobForm.countryId
                };
                
                // if (jobInfoData.contact.mobile != null && jobInfoData.contact.mobile != '') {
                //     jobInfoData.contact.phone2 = jobInfoData.contact.countryCode + " " + jobInfoData.contact.mobile;                
                // }
              
                let self = this;
                axios.post(this.$store.getters.getAPIBasePath + '/jobresource/update/job/' + this.jobid, jobInfoData)
                    .then(response => {
                        this.$router.push('/job/plan-recruitment/' + self.jobid);
                        // self.loadJobById(self.jobid);
                        iziToast.success({title: 'Success', message: this.$config.JobInfoUpSuc, position: 'topRight'});
                    })
                    .catch(error => {
                        this.newJobForm.jobSkill = [];
                        let errorMessage;
                        if (error.response.data.validationErrorList)
                            errorMessage = error.response.data.validationErrorList[0].message;
                        else
                            errorMessage = error.response.data.message;
                        iziToast.error({
                            title: 'Error',
                            message: errorMessage,
                            position: 'topRight'
                        });
                    });

            }
        },
        updateLogActivity: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/update/logactivity')
                .then(response => {})
                .catch(error => {
                    console.log(error);
                });
        }
    }
}

</script>
