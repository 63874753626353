<template>						
	<fieldset :disabled="!salaryNegotiationAccess">
		<div class="card-body p-2">
                <div class="basic-block m-t-15">
                    <div class="row">
                        <div class="col-md-4 col-sm-12">
                            <div class="row">
                                <div class="col-md-4">
                                   <label class="font-weight-bold">Job Code :</label> 
                                </div>
                                <div class="col-md-6 p-0">
                                    <label>{{Job.jobCode}}</label>
                                </div>
                            </div>
                        </div>
						<div class="col-md-4 col-sm-12">
                            <div class="row">
                                <div class="col-md-4">
                                   <label class="font-weight-bold">Job Title :</label> 
                                </div>
                                <div class="col-md-6 p-0">
                                    <label>{{Job.jobTitle}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <div class="col-md-5">
                                   <label class="font-weight-bold">Employer Pay :</label> 
                                </div>
                                <div v-if="Job.salary !=''" class="col-md-6 p-0">
                                    <label>{{Job.currency}} {{Job.salary}} {{Job.payFrequency}}</label>
                                </div>
                            </div>
                        </div>
					</div>
					<div v-if="showSalary" class="row mt-20">
						<div class="col-md-9 col-sm-12">
                            <div class="row">
                                <div class="col-md-3">
                                   <label class="font-weight-bold">Salary Expectation :</label> 
                                </div>
                                <div class="col-md-4 ">
                                        <div class="input-group extended">
                                            <select v-model="salaryNegotiation.currency" class="form-control p-0" disabled>
                                               <option v-for="(info, index) in currencies" :value="info.symbol">{{info.symbol}}</option> 
                                            </select>
                                            <input type="number" name="expSalary" v-model="salaryNegotiation.candExpectedSalary" class="form-control" maxlength="25"  @keypress="onlyNumber">
                                            <div class="input-group-append">
                                                <select  v-model="salaryNegotiation.payFrequency" class="form-control p-0">
                                                    <option v-for="(data, index) in payFrequencyList" :value="data.name">{{data.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="">
                                            <label v-if="!$v.salaryNegotiation.candExpectedSalary" class="text-danger">* Please enter the Salary expectation </label>
                                            <label v-if="!$v.currency" class="text-danger">* Salary should be in {{Job.currency}}</label>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>

					<div class="row mt-20">
                        <div class="col-md-12 col-sm-12 col-12">
							<div class="p-tb-15 b-1 br_5px">
								<div class="col-md-4 col-sm-12 col-12">
								    <input type="radio" id="minmax"  value="One" v-model="picked" @click="minMax()" name="MinMax" checked="">
								    <label class="font-weight-bold ml-8" for="minmax">Minimum/Maximum salary</label>
								</div>
                                <div v-if="picked=='two'" class="col-md-8 col-sm-12 col-12">
                                </div>
                                <div v-if="picked=='One'" class="col-md-8 col-sm-12 col-12 mt-10 pl-36">
                                    <div class="row d-flex">
                                        <div class="col-md-6 col-sm-12">
                                            <div class="row">
                                                <div class="col-md-7">
                                                    <label class="font-weight-bold">Minimum Salary :</label> 
                                                </div>
                                                <div class="col-md-5 p-0">
                                                    <div class="input-group extended">
                                                        <input type="number" name="minsalary" maxlength="25" value="" class="fldboxbig form-control"  v-model="salaryNegotiation.candMinSalary" @keypress="onlyNumber">
                                                    </div>                             		
                                                </div>
                                                <div class="plr-15-10">
                                                    <label v-if="!$v.salaryNegotiation.minsalary" class="text-danger">Minimum Salary must be less than Maximum Salary</label>
                                                </div>								
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-12">
                                            <div class="row">
                                                <div class="col-md-7">
                                                    <label class="font-weight-bold">Maximum Salary :</label> 
                                                </div>
                                                <div class="col-md-5 p-0">
                                                    <div class="input-group extended">
                                                        <input type="number" name="maxsalary" maxlength="25" value="" class="fldboxbig form-control" v-model="salaryNegotiation.candMaxSalary" @keypress="onlyNumber">
                                                    </div>                             		
                                                </div>
                                                <div class="plr-15-10">
                                                    <label v-if="!$v.salaryNegotiation.maxsalary" class="text-danger">Maximum Salary must be greater than Minimum Salary</label>
                                                </div>								
                                            </div>
                                        </div>
                                    </div>   
								</div>			           
								<div class="col-md-4 col-sm-12 col-12 m-t-15">			            
								    <input type="radio" id="flex"  value="two" @click="flex()" v-model="picked" name="Flex">
								    <label class="font-weight-bold ml-8" for="flex">Flexibility Percentage</label>
								</div>
                                <div v-if="picked=='One'" class="col-md-8 col-sm-12 col-12">
                                </div>
                                <div v-if="picked=='two'" class="col-md-8 col-sm-12 col-12 mt-10 pl-36">			                  
                                    <div class="row">
                                        <div class="col-md-2">
                                            <label class="fW_bold">Flex <span class="fW_bold">%</span> :</label>  
                                        </div>
                                        <div class="col-md-3 p-0">
                                            <div class="input-group extended">
                                                <input type="number" name="frepercentage" maxlength="25" value="" class="fldboxbig form-control" v-model="salaryNegotiation.flexibilityPercentage" @keypress="onlyNumber">
                                            </div>
                                            <label class="whole_no">*Whole numbers only</label>
                                        </div>
                                    </div>                                                             
								</div>			            
							</div>
						</div>
							
                    </div>
                </div>
            </div>
            <div v-if="($v.salaryNegotiation.candMaxSalary.$error && !$v.salaryNegotiation.candMaxSalary.required) && ($v.salaryNegotiation.candMinSalary.$error && !$v.salaryNegotiation.candMinSalary.required) && ($v.salaryNegotiation.flexibilityPercentage.$error && !$v.salaryNegotiation.flexibilityPercentage.required) " class="col-md-12 jc-space">
                <span class="text-danger">* Candidate's minimum and maximum salary or flexibility percentage required </span>
            </div>

            <div v-if="!$v.jobSalary" class="col-md-12 jc-space">
                <span class="text-danger">* Please provide the Salary info to initiate the Salary Negotiation</span>
            </div>
             <div v-if="directAccept()" style="padding: 15px 0;text-align: center;flex-direction: column;width: 40%;margin: 10px auto;" class="b-1 br_5px d-flex jc-c">
                <span> Recruiter Accepted</span>
                <div>Offer Amount : {{ salaryNegotiation.currency }}  {{(this.salaryNegotiation.candMinSalary ? this.salaryNegotiation.candMinSalary : this.salaryNegotiation.candExpectedSalary) }} {{ salaryNegotiation.payFrequency }} </div>
            </div>
            <div v-if="sendingMinSalary() || offerMinCheck() && !directAccept()" style="padding: 15px 0;text-align: center;flex-direction: column;width: 88%;margin: 10px auto;" class="b-1 br_5px d-flex jc-c">
                    <!-- <div class="fW_bold">Minimum Salary: {{ salaryNegotiation.currency }}  {{ salaryNegotiation.candMinSalary }} {{ salaryNegotiation.payFrequency }}</div> -->
                    <div class="fW_bold" style='color: #37a0e4;padding-bottom: 7px;'>Since the Candidate is requesting less than the job offer so we're sending the candidate salary info for approval</div>
                    <div><button type="button" @click="sendingCandMinSalary('direct')" class="btn btn-primary ml-10"><span class="pr-10 c-white">Send</span></button></div>
                    <!-- <button type="button" @click="requestid(id)" class="btn btn-success ml-10"><a title="Accept" href="javascript:void(0);" data-toggle="modal" data-target="#AcceptRequest"><i class="ti-check c-white"></i> <span class="pr-10 c-white">Accept</span> </a></button> -->
			</div>
            <div v-if="!sendingMinSalary() && !directAccept()  && !offerMinCheck()">
                <div class="col-sm-12 jc-space p-8">
                    <button v-if="(negotiate() || negotiateDirect()) &&  !minAccept()" type="button" @click="SalaryNegotiation('setld')" class="btn salaryNeg">
                        <span class="fs-12">Initiate salary negotiation</span>
                    </button>
                    <button v-if="negotiateDisabled()" type="button" disabled  class="btn salaryNeg">
                        <span class="fs-12">Initiate salary negotiation</span>
                    </button>
                    <button v-if="(reNegotiation() || recRejected() || setldLess() || reNegotiationD())"  type="button" @click="SalaryNegotiation('setld')" class="btn salaryNeg">
                        <span class="fs-12" >re-negotiation</span>
                    </button>
                    <button v-if="reNegotiationDisabled() && !reNegotiationD() && !setldLess() && !setldLessAcc() && !recRejected()" type="button" disabled class="btn salaryNeg">
                        <span class="fs-12" >re-negotiation</span>
                    </button>
                </div>
                <div v-if="manualNegotiation() || manualNegotiationDir()">
                    <span class="d-flex text-danger jc-c">Negotiation will be done manually further</span>
                </div>
                <div class="m-b-10" v-if="!setldLessAcc()">
                    <ul class="steps">
                        <li v-if="!negotiationStatus.initiated" class="step">
                            <div class="step-content">
                                <span class="step-circle">1</span>
                                <span v-if="historyData != null &&  historyData.length >= 1 &&( negType == 'setld' || negType1=='setld')" class="step-text w-96 text-center">Re-Negotiation</span>
                                <!-- <span v-if="historyData != null &&  historyData.length >= 1 && negType1=='setld'" class="step-text w-96 text-center">Re-Negotiation</span> -->
                                <span v-else-if="historyData != null &&  historyData.length == 2 && negType == 'direct' && negType1=='direct'" class="step-text w-96 text-center">Re-Negotiation</span>
                                <span v-else class="step-text w-70 text-center">Negotiation</span>
                                <span class="step-text w-64 text-center">Initiated</span>
                            </div>
                        </li>
                        <li v-if="negotiationStatus.initiated" class="step step-success">
                            <div class="step-content">
                                <span class="step-circle stepSuccess"><i class="fa fa-check"></i></span>
                                <span v-if="(negotiationProcessStatus == 'closed' || negotiationProcessStatus == 'open') && historyData.length > 1 && !showNeg()" class="step-text w-96 text-center">Re-Negotiation</span>
                                <span v-else-if="showNeg()" class="step-text w-96 text-center">Negotiation</span>
                                <span v-else class="step-text w-70 text-center">Negotiation</span>
                                <span class="step-text w-64 text-center">Initiated</span>
                            </div>
                        </li>
                        <li v-if="!negotiationStatus.processing" class="step">
                            <div class="step-content">
                                <span class="step-circle">2</span>
                                <span class="step-text">SETLD</span>
                                <span class="step-text w-88 text-center">Processing</span>
                            </div>
                        </li>
                        <li  v-if="negotiationStatus.processing" class="step step-success">
                            <div class="step-content">
                                <span class="step-circle stepSuccess"><i class="fa fa-check"></i></span>
                                <span class="step-text">SETLD</span>
                                <span class="step-text w-88 text-center">Processing</span>
                            </div>
                        </li>
                        <li v-if="!negotiationStatus.responded" class="step">
                            <div class="step-content">
                                <span class="step-circle">3</span>
                                <span class="step-text">SETLD</span>
                                <span class="step-text w-88 text-center">Responded</span>
                            </div>
                        </li>
                        <li v-if="negotiationStatus.responded" class="step step-success">
                            <div class="step-content">
                                <span class="step-circle stepSuccess"><i class="fa fa-check"></i></span>
                                <span class="step-text">SETLD</span>
                                <span class="step-text w-88 text-center">Responded</span>
                            </div>
                        </li>
                        <li v-if="!negotiationStatus.accepted && !negotiationStatus.rejected" class="step">
                            <div class="step-content">
                                <span class="step-circle">4</span>
                                <span class="step-text">Offer</span>
                                <span class="step-text">Accepted</span>
                            </div>
                        </li>
                        <li v-if="negotiationStatus.accepted" class="step step-success">
                            <div class="step-content">
                                <span class="step-circle stepSuccess"><i class="fa fa-check"></i></span>
                                <span class="step-text">Offer</span>
                                <span class="step-text">Accepted</span>
                            </div>
                        </li>
                        <li  v-if="negotiationStatus.rejected" class="step step-error">
                            <div class="step-content">
                                <span class="step-circle"><i class="fa fa-close"></i></span>
                                <span class="step-text">Offer</span>
                                <span class="step-text">Rejected</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div v-if="status == 'Received' && bestOfferFound " class="d-flex pb_20 jc-c">
                        <div class="fW_bold">Negotiation Amount: {{ salaryNegotiation.currency }}  {{ bestOffer }} {{ salaryNegotiation.payFrequency }}</div>
                        <button type="button" @click="requestid(id)" class="btn btn-success ml-10"><a title="Accept" href="javascript:void(0);" data-toggle="modal" data-target="#AcceptRequest"><i class="ti-check c-white"></i> <span class="pr-10 c-white">Accept</span> </a></button>
                        <button type="button" @click="requestid(id)" class="btn btn-danger ml-10 "> <a title="Reject" href="javascript:void(0);" data-toggle="modal" data-target="#RejectRequest"><i class="ti-close c-white"></i> <span class="c-white"> Reject</span> </a></button>
                </div>
            </div>
			<div class="d-flex pb_2" v-if="status == 'Candidate_Accepted' ">
				<div class="col-sm-5 fW_bold" >Accepted Offer:<span class="pl-2 fW_bold text-success">{{ salaryNegotiation.currency }}  {{ bestOffer }} {{ salaryNegotiation.payFrequency }}</span>
                </div>
				
			</div>
            <div class="d-flex pb_2" v-if="status == 'Candidate_Rejected'">
				<div class="col-sm-5 fW_bold">Rejected Offer:<span class="pl-2 fW_bold text-danger">{{ salaryNegotiation.currency }}  {{ bestOffer }} {{ salaryNegotiation.payFrequency }}</span>
                </div>				
			</div>
            <div class="d-flex pb_2" style="padding-top: 10px;" v-if="status == 'Candidate_Accepted' || status == 'Recruiter_Accepted' || manualNegotiation() || manualNegotiationDir()">
				<div>
                    <label class="col-sm-12 fW_bold">Offer Amount {{ salaryNegotiation.currency }}: </label>  
                </div>
                <div >
                    <div class="input-group extended">
                        <input :disabled="Offer" type="number" style="width: 80px;" maxlength="25" value="" class="fldboxbig form-control" v-model="bestOffer" @keypress="onlyNumber">
                        <button v-if=" manualNegotiation() || manualNegotiationDir()" type="button" @click="acceptOffer()" class="btn btn-success"><a href="javascript:void(0);" data-toggle="modal"><i class="ti-check c-white"></i> <span class="pr-10 c-white"></span> </a></button>
                        <button v-if=" manualNegotiation()  || manualNegotiationDir()" type="button" @click="clearOffer()" class="btn btn-danger"><a href="javascript:void(0);" data-toggle="modal"><i class="ti-close c-white"></i> <span class="pr-10 c-white"></span> </a></button>
                    </div>
                    <div v-if="!$v.bestOffer.$error && !$v.bestOffer.required">
                        <span class="text-danger">*Offer Amount is required</span>
                    </div>
                 </div>				
			</div>
			<div class="d-flex pb_20" v-if="">
				<div class="col-sm-12 fW_bold tab-bg p-15">
					<a href="Javascript:void(0);" class="c-black" data-targetopen="panel1">
						<span class="fW_bold hover-hand" v-on:click="isShow = !isShow" :class="isShow?'fa fa-caret-down':'fa fa-caret-right'"> &nbsp;</span>
						<span>Transaction History</span>
					</a>
					<div v-if="isShow" class="t-history">
						<table class="table">
							<thead>
								<tr >
									<th>Negotiation</th>
                                    <th>Employee Ask</th>
                                    <th>Employee Min Salary</th>
                                    <th>Employee Max Salary</th>
									<th>SST Amount</th> 
									<th>Status</th>
								</tr>
							</thead>
							<tbody>
								 <tr v-for="history in historyData"  :key="history.id">
									<td>{{ history.negotiationOrder }}</td>
                                    <td v-if="history.candExpectedSalary!='' && history.candExpectedSalary!=null">{{ history.currency }} {{ history.candExpectedSalary }} {{ history.payFrequency }}</td>
                                    <td v-else class="text-center">-</td>
                                    <td v-if="history.candMinSalary!='' && history.candMinSalary!=null">{{ history.currency }} {{ history.candMinSalary }} {{ history.payFrequency }}</td>
                                    <td v-else class="text-center">-</td>
                                    <td v-if="history.candMaxSalary!='' && history.candMaxSalary!=null">{{ history.currency }} {{ history.candMaxSalary }} {{ history.payFrequency }}</td>		
                                    <td v-else class="text-center">-</td>
                                    <td>{{ history.currency }}  {{ history.bestOffer }} {{ history.payFrequency }}</td>
									<td>{{ history.status.replace(new RegExp("_", 'g'), " ") }}</td>									
								</tr>
								 <tr v-if="historyData!= null && !historyData.length">
									<td class="text-center" colspan="100%">No History found</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				
			</div>
        <div class="modal " id="RejectRequest" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Confirmation</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Are you sure to reject this Offer?
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="requestConfirmation('reject')" class="btn btn-primary" data-dismiss="modal">Yes</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal " id="AcceptRequest" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Confirmation</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Are you sure to accept this Offer?
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="requestConfirmation('accept')" class="btn btn-primary" data-dismiss="modal">Yes</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                    </div>
                </div>
            </div>
        </div
	</div>	
	</fieldset>				

	
</template>
<script>
	import iziToast from 'izitoast';
    import { required, email, minLength, maxLength, helpers } from "vuelidate/lib/validators";
    import 'bootstrap/dist/css/bootstrap.min.css';
    import 'bootstrap-steps/dist/bootstrap-steps.min.css';
	export default {
		props: {
			jobDetails: Object,
			candidateInfo:Object,
			transactionHistory:Array		
		},
		watch: {
			'transactionHistory': {
				handler: function(value) {
					this.historyData = value;
					this.bindSalaryNegotiationData(this.historyData);
				},
				deep: true,
				immediate: true
			},
            'picked': {
				handler: function(value) {
					if(value =='One'){
                        this.showSalary = false
                        this.salaryNegotiation.candExpectedSalary=""
                        this.salaryNegotiation.currency=this.jobDetails.currency
                    }else if(value =='two'){
                        this.showSalary = true
                        this.salaryNegotiation.candExpectedSalary=this.candidateInfo.expectedSalary
                    }else{
                    this.showSalary = false
                    }
				},
				deep: true,
				immediate: true
			},
            'salaryNegotiation': {
				handler: function(value) {
                    this.salaryNegotiation.candExpectedSalary = value.candExpectedSalary
					if(value.flexibilityPercentage > 100){
                        this.salaryNegotiation.flexibilityPercentage="",
                        iziToast.error({
                            title: 'Error',
                            message: this.$config.flexError,
                            position:'topRight'
                        });
                    }
				},
				deep: true,
				immediate: true
			}
		},
		data(){
			return {
                negType:'',
                negType1:'',
                Offer:true,
                salaryNegotiationAccess:Boolean,
                picked:"",
				negotiationStatus:{
					initiated:false,
					processing:false,
					responded:false,
					accepted:false,
					rejected:false,
					received:false
				},                
                payFrequencyList: this.$store.getters.getPayFrequencyList,
                currencies: this.$store.getters.getCurrencies,
				historyData:[],
				isShow : false,
                Job:{
                    jobCode:this.jobDetails.jobCode,
                    jobTitle:this.jobDetails.jobTitle,
                    currency: this.jobDetails.currency,
                    salary:(this.jobDetails.salary?this.jobDetails.salary:this.jobDetails.minSalary),
                    payFrequency:this.jobDetails.payFrequency,
                    minSalary:this.jobDetails.minSalary,
                    maxSalary:this.jobDetails.maxSalary,
                    flexibilityPercentage:this.jobDetails.frequencyPercentage,
				},
                salaryNegotiation:{
                    currency:this.jobDetails.currency,
                    payFrequency:this.jobDetails.payFrequency,
					candExpectedSalary:this.candidateInfo.expectedSalary,
                    candMinSalary:(this.candidateInfo.minSalary == 0 ? '': this.candidateInfo.minSalary),
					candMaxSalary:(this.candidateInfo.maxSalary == 0 ? '': this.candidateInfo.maxSalary),  
					flexibilityPercentage:(this.candidateInfo.flexibilityPercentage == 0 ? '': this.candidateInfo.flexibilityPercentage),
                    rpCandShortlistId:this.candidateInfo.rpCandShortlistId,
                    jobId:this.jobDetails.id,
                    candidateId: this.candidateInfo.candidateId,
                    dealAmount:'',
                    flexAmount:'',
                    offers:[],
                    negotiationType: 'setld',
				},	
                sendingcandSalary:{
                    jobId:this.jobDetails.id,
                    candidateId: this.candidateInfo.candidateId,
                    candMinSalary:(this.candidateInfo.minSalary == 0 ? '': this.candidateInfo.minSalary),
                },
                negotiationProcessStatus:""	,
                id:"",
                salaryNegId:"",
                bestOffer:"",
                bestOfferFound:true,
                rpCandShortlistId:"",
                status:"",
                negotiationOrder:"",
                candidateTotalAmount:""	
			}
		},
		validations: {
        
            salaryNegotiation: {
                minsalary:function(candMinSalary,candMaxSalary) {
                    if (candMinSalary.candMinSalary!="" && candMinSalary.candMinSalary!=undefined && candMinSalary.candMinSalary!=null && candMinSalary.candMaxSalary != "" && candMinSalary.candMaxSalary != undefined && candMinSalary.candMaxSalary != null){
                        if(Number(candMinSalary.candMinSalary) > Number(candMinSalary.candMaxSalary)){
                            return false;
                            }
                        }
                        return true;
                },
           
                maxsalary:function(candMinSalary,candMaxSalary) {
                    if (candMinSalary.candMinSalary != "" && candMinSalary.candMinSalary != undefined && candMinSalary.candMinSalary != null && candMinSalary.candMaxSalary!="" && candMinSalary.candMaxSalary!=undefined && candMinSalary.candMaxSalary!=null){
                        if(Number(candMinSalary.candMinSalary) > Number(candMinSalary.candMaxSalary)){
                            return false;
                            }
                    } 
                    return true;
                },
                candExpectedSalary:function() {
                    if (this.picked=='two' &&  (this.salaryNegotiation.candExpectedSalary=="" || this.salaryNegotiation.candExpectedSalary==undefined || this.salaryNegotiation.candExpectedSalary==null)){
                            return false;          
                    } 
                    return true;
                },
                candMinSalary: {
                    required
                },
                candMaxSalary: {
                    required
                },
                flexibilityPercentage: {
                    required
                },                       
            }, 
            currency:function() {
                if (this.Job.currency == this.salaryNegotiation.currency){
                    return true;          
                } 
                return false;
            },
            jobSalary:function() {
               if ((this.Job.salary != null && this.Job.salary.length > 0) &&  this.Job.flexibilityPercentage !=undefined){
                    return true;        
                } 
                else if (this.Job.minSalary !=undefined && this.Job.maxSalary !=undefined){
                    return true;
                }
                return false;
            },
            bestOffer: {
                    required
            },  
        },
		mounted(){
			this.minMaxFlex();
            this.salaryAccess();
            this.Offers();
            this.offer();
		},
		methods: {
            Offers(){
                if (this.negotiationOrder=='2'  && (this.status == 'Candidate_Rejected' || this.status == 'Recruiter_Rejected')){                 
                   this.Offer=false;
                }else if (!this.bestOfferFound)  {                 
                     this.Offer=false;
                }
            },
            offer(){
                if (this.negotiationOrder=='0' && this.historyData.length == 3  && (this.status == 'Candidate_Rejected' || this.status == 'Recruiter_Rejected')){                 
                   this.Offer=false;
                }else if (!this.bestOfferFound)  {                 
                     this.Offer=false;
                }
            },
            acceptOffer(){
                const params ={ 'candidateId': this.salaryNegotiation.candidateId,'offerAmount': this.bestOffer }
               if(!this.$v.bestOffer.$invalid){
               axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/update/offeramount', params)
                .then(response => {  
                    let result = response.data                
                    if(result.status="OK"){
                        iziToast.success({
                            title: 'success',
                            message: result.message,
                            position:'topRight'
                        });                   
                    }else{
                        iziToast.error({
                            title: 'Error',
                            message: result.message,
                            position:'topRight'
                        });             
                    }
                   
                })
                .catch(error => {
                    console.log(error);
                });
               }
            },
            clearOffer(){
                this.bestOffer=''
            },
            // showOffer(){
            //     if (this.bestOffer > this.salaryNegotiation.candMinSalary && this.bestOffer < this.Job.maxSalary) {
            //         return true;
            //     } else {
            //         return false;
            //     }
            // },
            salaryAccess(){
                if(localStorage.getItem('salaryNegotiationAccess') === "false"){
                        this.salaryNegotiationAccess  = false
                    }else if(localStorage.getItem('salaryNegotiationAccess') === "true"){
                        this.salaryNegotiationAccess  = true
                    }
            },
            payFreqCalc(){
                if(this.salaryNegotiation.payFrequency!=this.Job.payFrequency){
                        if(this.Job.payFrequency=='Per Month' && this.salaryNegotiation.payFrequency=='Per Year'){
                           this.salaryNegotiation.payFrequency='Per Month'
                           this.salaryNegotiation.candExpectedSalary=(this.salaryNegotiation.candExpectedSalary/12)
                        }else if(this.Job.payFrequency=='Per Year' && this.salaryNegotiation.payFrequency=='Per Month'){
                           this.salaryNegotiation.payFrequency='Per Year'
                           this.salaryNegotiation.candExpectedSalary=(this.salaryNegotiation.candExpectedSalary*12)
                        }
                    }
            },
            showNeg(){
                if (this.status == 'Received' && this.historyData.length == 2 && this.negotiationProcessStatus == 'open' && this.negotiationOrder == 0){                 
                    return true;
                }
                return false;
            },
            sendingMinSalary(){
                if (((this.salaryNegotiation.candMinSalary ? this.salaryNegotiation.candMinSalary : this.salaryNegotiation.candExpectedSalary) <=  (this.Job.minSalary ? this.Job.minSalary : this.Job.salary)) && this.status== '' && (this.salaryNegotiation.candMinSalary ? this.salaryNegotiation.candMinSalary : this.salaryNegotiation.candExpectedSalary) != ''){                 
                    return true;
                }
                return false;
            },
            offerMinCheck(){
                if (this.bestOffer >= (this.salaryNegotiation.candMinSalary ? this.salaryNegotiation.candMinSalary : this.salaryNegotiation.candExpectedSalary) && this.historyData.length ==1 && this.status != 'Candidate_Rejected' && this.status != 'Candidate_Accepted'){                 
                    return true;
                }
                return false;
            },
            negotiate(){
                if ((this.negotiationProcessStatus=='' || this.negotiationProcessStatus==undefined) && this.$v.jobSalary){                 
                    return true;
                }
                return false;
            },
            negotiateDisabled(){
                if (this.negotiationProcessStatus == 'open' && this.historyData.length == 1){                 
                    return true;
                }else if (!this.$v.jobSalary)  {                 
                    return true;
                }
                return false;
            },
            negotiateDirect(){
                if ( this.historyData.length == 1 && this.negotiationOrder == 0 && (this.status == 'Candidate_Accepted' || this.status == 'Candidate_Rejected'  )){                 
                    return true;
                }
                return false;
            },
            directAccept(){
                if (this.negotiationOrder==0 && this.status == 'Recruiter_Accepted'){                 
                    return true;
                }
                return false;
            },
            reNegotiationDisabled(){
                if ((this.status == 'Candidate_Accepted' ) || ((this.status == 'Candidate_Rejected' || this.status == 'Processing' || this.status == 'Recruiter_Accepted' || this.status == 'Recruiter_Rejected' ) && this.historyData.length > 1 )){                 
                    return true;
                }
                return false;
            },
            setldLess(){
                if (this.status == 'Candidate_Rejected' && this.historyData.length == 2 && this.negotiationOrder == 0 && this.negotiationProcessStatus == 'closed'){                 
                    return true;
                }
                return false;
            },
            setldLessAcc(){
                if (this.status == 'Candidate_Accepted' && this.historyData.length == 2 && this.negotiationOrder == 0 && this.negotiationProcessStatus == 'closed'){                 
                    return true;
                }
                return false;
            },
            reNegotiation(){
                if (this.negotiationProcessStatus == 'closed' && (this.status == 'Candidate_Rejected' || this.status == 'Recruiter_Rejected') &&  this.historyData.length == 1  && this.negotiationOrder!=0){                 
                    return true;
                }
                return false;
            },
            minAccept(){
                if (this.negotiationProcessStatus == 'closed' && this.status == 'Candidate_Accepted' &&  this.historyData.length == 1  && this.negotiationOrder == 0){                 
                    return true;
                }
                return false;
            },
            recRejected(){
                if (this.negotiationProcessStatus == 'closed' && this.status == 'Recruiter_Rejected' && this.historyData.length == 2  && this.negotiationOrder == 0){                 
                    return true;
                }
                return false;
            },
            reNegotiationD(){
                if (this.negotiationProcessStatus == 'closed' && (this.status == 'Candidate_Rejected' || this.status == 'Recruiter_Rejected') &&  this.historyData.length == 2  && this.negotiationOrder!=0 && this.negType == 'direct'){                 
                    return true;
                }
                return false;
            },
            manualNegotiation(){

                console.log('negotiationOrder ='+this.negotiationOrder+' status = '+this.status+' bestOfferFound = '+this.bestOfferFound)
                if (this.negotiationOrder=='2'  && (this.status == 'Candidate_Rejected' || this.status == 'Recruiter_Rejected')){                 
                    return true;
                }else if (!this.bestOfferFound)  {                 
                    return true;
                }
                return false;
            },
            manualNegotiationDir(){
                if (this.negotiationOrder=='0'  &&  (this.status == 'Recruiter_Rejected' || this.status == 'Candidate_Rejected') && this.historyData.length == 3 && this.negotiationProcessStatus == 'closed'){                 
                    return true;
                }else if (!this.bestOfferFound)  {                 
                    return true;
                }
                return false;
            },
            candidateFlexCalc(){
                if(this.salaryNegotiation.flexibilityPercentage !=undefined ){
                    let calculatedTotal = this.salaryNegotiation.candExpectedSalary * (this.salaryNegotiation.flexibilityPercentage / 100);
                    let totalAmount =  Number(this.salaryNegotiation.candExpectedSalary ) - Number(calculatedTotal) 
                    this.candidateTotalAmount = totalAmount
                }
            },
            jobFlexCalc(){
                if(this.Job.flexibilityPercentage !=undefined){
                    let calculatedTotal = this.Job.salary * (this.Job.flexibilityPercentage.replace(new RegExp("%", ''), " ") / 100);
                    let totalAmount =  Number(this.Job.salary ) + Number(calculatedTotal) 
                    this.salaryNegotiation.dealAmount = this.Job.salary;
                    this.salaryNegotiation.flexAmount = totalAmount;
                }else{
                    this.salaryNegotiation.dealAmount = this.Job.minSalary;
                    this.salaryNegotiation.flexAmount = this.Job.maxSalary;
                }
            },
            minMaxFlex(){
                console.log('this.candidateInfo.minSalary= '+this.candidateInfo.minSalary +' this.candidateInfo.maxSalary = '+this.candidateInfo.maxSalary+' this.salaryNegotiation.candMinSalary='+this.salaryNegotiation.candMinSalary+' this.salaryNegotiation.candMaxSalary='+this.salaryNegotiation.candMaxSalary);
                console.log('this.candidateInfo.flexibilityPercentage = '+this.candidateInfo.flexibilityPercentage+ ' this.salaryNegotiation.flexibilityPercentage='+this.salaryNegotiation.flexibilityPercentage);
  
                if((this.salaryNegotiation.candMinSalary !=null && this.salaryNegotiation.candMinSalary !='') || (this.salaryNegotiation.candMaxSalary !=null && this.salaryNegotiation.candMaxSalary !='') ){
                    this.picked = 'One'
                }else if(this.salaryNegotiation.flexibilityPercentage !=null && this.salaryNegotiation.flexibilityPercentage !=''){
                    this.picked = 'two'
                }else{
                    this.picked = ''
                }
            },
            minMax(){
               this.salaryNegotiation.flexibilityPercentage = "";
               this.salaryNegotiation.candMinSalary = (this.candidateInfo.minSalary == 0 ? '': this.candidateInfo.minSalary);
			   this.salaryNegotiation.candMaxSalary = (this.candidateInfo.maxSalary == 0 ? '': this.candidateInfo.maxSalary); 					

            },
            flex(){
               this.salaryNegotiation.candMinSalary = "";
               this.salaryNegotiation.candMaxSalary = "";
               this.salaryNegotiation.flexibilityPercentage = (this.candidateInfo.flexibilityPercentage == 0 ? '': this.candidateInfo.flexibilityPercentage);
            },
			onlyNumber($event) {
				let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
				if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
					$event.preventDefault();
            	}
        	},
            requestid(id) {
                this.salaryNegId = id;
            },
            requestConfirmation(type){
            axios.post(this.$store.getters.getAPIBasePath + '/jobresource/candidate/salary/negotiation/request/'+this.salaryNegId, {type:type, userType:this.$store.getters.currentUser.userType})
                .then(response => {  
                    let result = response.data                
                    if(result.status="ACCEPTED"){
                        this.showOverlay=false;
                        iziToast.success({
                            title: 'Confirmation Request',
                            message: result.message,
                            position:'topRight'
                        });
                        this.getHistoryData();                    
                    }else{
                        this.showOverlay=false;
                        iziToast.error({
                            title: 'Error',
                            message: result.message,
                            position:'topRight'
                        });             
                    }
                   
                })
                .catch(error => {
                    this.showOverlay=false;
                    console.log(error);
                });
            },
            getHistoryData(){
				axios.get(this.$store.getters.getAPIBasePath+'/setldresource/get/'+this.rpCandShortlistId)
                .then(response => {
					console.log(response);
					this.historyData = response.data;
                    this.bindSalaryNegotiationData(this.historyData);
                })
                .catch(error => {
                    console.log(error);
                });
			},
            clearNegotiationStatus(){
                this.negotiationStatus.initiated = false;
                this.negotiationStatus.processing = false;
                this.negotiationStatus.responded = false;
                this.negotiationStatus.rejected = false;
                this.negotiationStatus.accepted = false;
            },
            bindSetldData: function(){
                if(this.salaryNegotiation.flexibilityPercentage !=null && this.salaryNegotiation.flexibilityPercentage !=''){
                    this.salaryNegotiation.offers.push({
                        userReferenceId:this.salaryNegotiation.candidateId,
                        offerAmount:this.salaryNegotiation.candExpectedSalary,
                        flexAmount:this.candidateTotalAmount
                    });
                }else{
                    this.salaryNegotiation.offers.push({
                        userReferenceId:this.salaryNegotiation.candidateId,
                        offerAmount:this.salaryNegotiation.candMinSalary,
                        flexAmount:this.salaryNegotiation.candMaxSalary
                    });
                }

               
            },
            sendingCandMinSalary(type){
                this.salaryNegotiation.negotiationType = type;
                this.SalaryNegotiation(type);
                
            },
			SalaryNegotiation(type){
                this.$v.salaryNegotiation.$touch();
                if(!(this.$v.salaryNegotiation.candMinSalary.$invalid && this.$v.salaryNegotiation.candMaxSalary.$invalid && this.$v.salaryNegotiation.flexibilityPercentage.$invalid) && this.$v.salaryNegotiation.candExpectedSalary && !this.$v.jobSalary.$invalid && this.$v.currency){
                this.clearNegotiationStatus();
                this.jobFlexCalc();
                this.candidateFlexCalc();
                this.bindSetldData();           
                this.negotiationStatus.initiated = true;
                var updatedSalaryNegotiation = {
                ...this.salaryNegotiation,
                currency: this.salaryNegotiation.currency == '₹' ? 'Rs' : this.salaryNegotiation.currency,
                negotiationType: type
                };
                console.log("updatedSalaryNegotiation",updatedSalaryNegotiation);

				axios.post(this.$store.getters.getAPIBasePath+'/setldresource/add', updatedSalaryNegotiation)
                .then(response => {
                console.log(response);
                if (type == 'direct') {
                    this.clearNegotiationStatus();
                    this.salaryNegId = response.data.id;
                    this.rpCandShortlistId = response.data.recordinfo[0].rpCandShortlistId;
                    return this.requestConfirmation('accept');
                } else {
                    return Promise.resolve(response);
                }
                })
                .then(response => {
                    this.historyData = response.data.recordinfo;
                    console.log(this.historyData);
                    this.bindSalaryNegotiationData(this.historyData);
                    if (type == 'setld') {
                        this.getStatus(response.data.processingStatus);
                    }
                    this.Offers();
                    this.offers = [];
                    this.getHistoryData();
                    iziToast.success({
                        title: 'Success',
                        message: "",
                        position: 'topRight'
                    });
                })
                .catch(error => {
                    console.log(error);
                });
                    }
			},
			getStatus:function(status){
				console.log("status",status);
				 switch (status) {
                    case "Initiated":
                       this.negotiationStatus.initiated = true;
                        break;
					case "Processing":
						this.negotiationStatus.initiated = true;
                        this.negotiationStatus.processing = true;
                        break;
					case "Responded":
						this.negotiationStatus.initiated = true;
                        this.negotiationStatus.processing = true;
                        this.negotiationStatus.responded = true;
                        break;
					case "Recruiter_Accepted":
						this.negotiationStatus.initiated = true;
                        this.negotiationStatus.processing = true;
                        this.negotiationStatus.responded = true;   
                        break;
					case "Recruiter_Rejected":
                        if(negotiationOrder == 0){
						this.negotiationStatus.initiated = false;
                        this.negotiationStatus.processing = false;
                        this.negotiationStatus.responded = false;
                        this.negotiationStatus.rejected = false;
                        }else{
						this.negotiationStatus.initiated = true;
                        this.negotiationStatus.processing = true;
                        this.negotiationStatus.responded = true;
                        this.negotiationStatus.rejected = true;
                        }
                        break;
                    case "Candidate_Accepted":
						this.negotiationStatus.initiated = true;
                        this.negotiationStatus.processing = true;
                        this.negotiationStatus.responded = true;
                        this.negotiationStatus.accepted = true;
                        break;
					case "Candidate_Rejected":
                        if(negotiationOrder == 0){
						this.negotiationStatus.initiated = false;
                        this.negotiationStatus.processing = false;
                        this.negotiationStatus.responded = false;
                        this.negotiationStatus.rejected = false;
                        }else{
                        this.negotiationStatus.initiated = true;
                        this.negotiationStatus.processing = true;
                        this.negotiationStatus.responded = true;
                        this.negotiationStatus.rejected = true;   
                        }
                        break;    
					case "Received":
						this.negotiationStatus.initiated = true;
                        this.negotiationStatus.processing = true;
                        this.negotiationStatus.responded = true;
                        this.negotiationStatus.received = true;
                        break;
					default:
				}
				console.log(this.negotiationStatus);
			},
			bindSalaryNegotiationData(data){
				if(data != null && data.length > 0){
					this.salaryNegotiation.referenceId = data[0].referenceId
                    this.negType = data[0].negotiationType;
					if(data.length == 1){console.log(data[0].negotiationProcessStatus)
						this.salaryNegotiation.candExpectedSalary = data[0].candExpectedSalary
						this.salaryNegotiation.candMinSalary = data[0].candMinSalary
						this.salaryNegotiation.candMaxSalary = data[0].candMaxSalary
						this.salaryNegotiation.flexibilityPercentage = data[0].flexibilityPercentage
						this.salaryNegotiation.currency = (data[0].currency == 'Rs'? '₹' : data[0].currency)
						this.salaryNegotiation.payFrequency = data[0].payFrequency
                        this.salaryNegotiation.negotiationType = data[0].negotiationType
                        this.negotiationProcessStatus = data[0].negotiationProcessStatus
                        this.negotiationOrder = data[0].negotiationOrder
                        this.id = data[0].id
                        this.rpCandShortlistId = data[0].rpCandShortlistId
                        this.bestOffer = data[0].bestOffer
                        this.bestOfferFound = data[0].bestOfferFound
                        this.status = data[0].status
                        this.negType = data[0].negotiationType;
						this.getStatus(data[0].status);
					}else if(data.length == 2){
                        this.salaryNegotiation.referenceId = data[1].referenceId
						this.salaryNegotiation.candExpectedSalary = data[1].candExpectedSalary
						this.salaryNegotiation.candMinSalary = data[1].candMinSalary
						this.salaryNegotiation.candMaxSalary = data[1].candMaxSalary
						this.salaryNegotiation.flexibilityPercentage = data[1].flexibilityPercentage
						this.salaryNegotiation.currency = (data[1].currency == 'Rs'? '₹' : data[1].currency)
						this.salaryNegotiation.payFrequency = data[1].payFrequency
                        this.salaryNegotiation.negotiationType = data[1].negotiationType
                        this.negotiationProcessStatus = data[1].negotiationProcessStatus
                        this.negotiationOrder = data[1].negotiationOrder
                        this.id = data[1].id
                        this.rpCandShortlistId = data[1].rpCandShortlistId
                        this.bestOffer = data[1].bestOffer
                        this.bestOfferFound = data[1].bestOfferFound
                        this.status = data[1].status
                        this.negType1 = data[1].negotiationType;
						this.getStatus(data[1].status);
					}else if(data.length == 3){
						this.salaryNegotiation.candExpectedSalary = data[2].candExpectedSalary
						this.salaryNegotiation.candMinSalary = data[2].candMinSalary
						this.salaryNegotiation.candMaxSalary = data[2].candMaxSalary
						this.salaryNegotiation.flexibilityPercentage = data[2].flexibilityPercentage
						this.salaryNegotiation.currency = (data[2].currency == 'Rs'? '₹' : data[2].currency)
						this.salaryNegotiation.payFrequency = data[2].payFrequency
                        this.salaryNegotiation.negotiationType = data[2].negotiationType
                        this.negotiationProcessStatus = data[2].negotiationProcessStatus
                        this.negotiationOrder = data[2].negotiationOrder
                        this.id = data[2].id
                        this.rpCandShortlistId = data[2].rpCandShortlistId
                        this.bestOffer = data[2].bestOffer
                        this.bestOfferFound = data[2].bestOfferFound
                        this.status = data[2].status
						this.getStatus(data[2].status);
					}
				}
			}
			
		}
	}
</script>