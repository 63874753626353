<template>
    <div>
        <div class="card">
            <div class="card-header p-2">
                <div class="row">
                    <div class="col-md-6">
                        Client Details
                    </div>
                    <div class="col-md-6 text-right">
                        {{clientinfo.clientCode}}
                    </div>
                </div>
            </div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Client Name</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group required-field-block">
                                        <input type="text" v-model="clientinfo.clientName" maxlength="50" class="form-control text-required">
                                        <span class="required-icon">
                                        </span>
                                    </div>
                                    <label v-if="$v.clientinfo.clientName.$error && !$v.clientinfo.clientName.required" class="text-danger">Please enter client name</label>
                                    <label v-if="$v.clientinfo.clientName.$error && !$v.clientinfo.clientName.minLength" class="text-danger">Client name must have at least {{$v.clientinfo.clientName.$params.minLength.min}} letters.</label>
                                    <label v-if="$v.clientinfo.clientName.$error && !$v.clientinfo.clientName.maxLength" class="text-danger">Client name must not exceed {{$v.clientinfo.clientName.$params.maxLength.max}} letters.</label>
                                </div>
                            </div>
                        </div>
                        <div v-if="false" class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Client Code</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input type="text" disabled="disabled" name="clientCode" v-model="clientinfo.clientCode" maxlength="10" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>DUNS Number</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input type="text" v-model="clientinfo.dunsNumber" minlength="9" maxlength="9" class="form-control" @keypress="onlyNumber" @blur="$v.clientinfo.dunsNumber.$touch">
                                    </div>
                                    <label v-if="$v.clientinfo.dunsNumber.$error && !$v.clientinfo.dunsNumber.dunsNumberExists" class="text-danger">DUNS number already exists</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-8 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Account Name(Email Id)</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group required-field-block">
                                        <input type="email" class="form-control" v-model="clientinfo.accountName" maxlength="50">
                                        <span class="required-icon">
                                        </span>
                                    </div>
                                    <label v-if="$v.clientinfo.accountName.$error && !$v.clientinfo.accountName.required" class="text-danger">Please enter email id</label>
                                    <label v-if="$v.clientinfo.accountName.$error && !$v.clientinfo.accountName.email" class="text-danger">Email Id not valid</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Website</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input type="url" v-model="clientinfo.url" maxlength="100" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Status</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <select v-model="clientinfo.status" class="form-control">
                                        <option value="" selected="selected">Select</option>
                                        <option value="true">Active</option>
                                        <option value="false">InActive</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header p-2">Description</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-xs-12">
                                    <textarea rows="3" v-model="clientinfo.description" class="form-control"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    props: {
        clientinfo: Object,
    },
    data() {
        return {}
    },
    validations: {
        clientinfo: {
            clientName: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(200)
            },
            accountName: {
                required,
                email
            },
            dunsNumber: {
                dunsNumberExists(dunsNumber) {
                    console.log(this.clientinfo);
                    if (dunsNumber === '' || dunsNumber === null) return true;
                    let ajaxdata = { dunsNumber: dunsNumber };
                    if (this.clientinfo.id != undefined) {
                        ajaxdata.id = this.clientinfo.id;
                    }
                    return axios.post(this.$store.getters.getAPIBasePath + '/clientresource/check/dunsnumber/availability', ajaxdata)
                        .then(response => {
                            if (response.data.status) {
                                return true;
                            }
                            return false;
                        })
                        .catch(error => {
                            return false;
                        });
                }
            }
        }
    },
    mounted() {
    },
    methods: {
        onlyNumber($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }
        },
        validateClientInfo() {
            this.$v.clientinfo.$touch();
            if (this.$v.clientinfo.$invalid) {
                return false;
            }
            return true;
        }
    }
}

</script>
